"use client";

import { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Input, List, Image, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PublicService from "../../../../service/PublicService";
import { useTranslation } from "react-i18next";

const StockImagePicker = ({ visible, onClose, addImageToCampaignImages }) => {
  const [query, setQuery] = useState("marketing"); // Default search keyword
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef(null);
  const { t } = useTranslation();

  // Fetch images from Unsplash API
  const fetchImages = useCallback(
    async (reset = false) => {
      if (!query) return;

      setLoading(true);
      try {
        const response = await PublicService.queryStockImages({
          query,
          per_page: 25,
          page: reset ? 1 : page,
        });

        const newImages = response.data.results;
        if (reset) {
          setImages(newImages);
          setPage(2);
        } else {
          setImages((prev) => [...prev, ...newImages]);
          setPage((prev) => prev + 1);
        }
        setHasMore(newImages.length > 0);
      } catch (error) {
        console.error("Error fetching stock images:", error);
      } finally {
        setLoading(false);
      }
    },
    [query, page]
  );

  // Handle user input search
  const handleSearch = () => {
    setImages([]); // Clear previous results
    setPage(1);
    fetchImages(true);
  };

  // Infinite Scroll - Load more when reaching bottom
  const handleScroll = () => {
    if (!hasMore || loading) return;

    const container = containerRef.current;
    if (
      container &&
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50
    ) {
      fetchImages();
    }
  };

  // Handle Image Selection
  const handleImageSelect = async (url) => {
    await addImageToCampaignImages(url);
    onClose(); // Close modal after selection
  };

  useEffect(() => {
    if (visible) {
      fetchImages(true);
    }
  }, [visible, fetchImages]);

  return (
    <Modal
      title={t("Choose Stock Image")}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Input
        placeholder={t("Search for images...")}
        prefix={<SearchOutlined />}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onPressEnter={handleSearch}
        style={{ marginBottom: 16 }}
      />

      <div
        ref={containerRef}
        onScroll={handleScroll}
        style={{ height: 400, overflowY: "auto", padding: "5px" }}
      >
        <List
          grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
          dataSource={images}
          renderItem={(item) => (
            <List.Item>
              <div className="flex flex-col">
                <Image
                  src={item.thumbnail || "/placeholder.svg"}
                  preview={false}
                  style={{
                    cursor: "pointer",
                    borderRadius: 8,
                    objectFit: "cover",
                    height: 120,
                  }}
                  onClick={() => handleImageSelect(item.url)}
                />
                <p className="text-[12px] mt-1">
                  Photo by{" "}
                  <a
                    className="underline"
                    href={item.profile_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.photographer}
                  </a>{" "}
                  on{" "}
                  <a
                    className="underline"
                    href="https://unsplash.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Unsplash
                  </a>
                </p>
              </div>
            </List.Item>
          )}
        />
        {loading && (
          <div style={{ textAlign: "center", padding: 10 }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default StockImagePicker;
