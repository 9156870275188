"use client";

import { useState, useEffect } from "react";
import {
  Select,
  Button,
  Form,
  message,
  Card,
  List,
  Popconfirm,
  Tabs,
  Tag,
} from "antd";
import {
  SearchOutlined,
  PhoneOutlined,
  DeleteOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import AgentConfiguration from "./AgentConfiguration";
import AgentAssignment from "./AgentAssignment";
import CallManagementService from "../../../../service/CallManagementService";

const { Option } = Select;
const { TabPane } = Tabs;

export default function PhoneNumbers() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [userNumbers, setUserNumbers] = useState([]);
  const [userAgents, setUserAgents] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchUserNumbers();
    fetchUserAgents();
  }, []);

  const fetchUserNumbers = async () => {
    try {
      const res = await CallManagementService.getUserNumbers();
      setUserNumbers(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserAgents = async () => {
    try {
      const res = await CallManagementService.getUserAgents();
      setUserAgents(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const searchNumbers = async (values) => {
    setSearching(true);
    try {
      const res = await CallManagementService.searchNumber();
      setAvailableNumbers(res.data.numbers);
    } catch (error) {
      console.error(error);
    } finally {
      setSearching(false);
    }
  };

  const purchaseNumber = async () => {
    if (!selectedNumber) {
      message.error(t("Please select a number to purchase"));
      return;
    }

    setLoading(true);
    try {
      const res = await CallManagementService.purchaseNumber({
        phoneNumber: selectedNumber,
      });

      message.success(t("Successfully purchased number: {{phoneNumber}}", { phoneNumber: res.data.phoneNumber }));
      form.resetFields();
      setAvailableNumbers([]);
      setSelectedNumber("");
      fetchUserNumbers(); // Refresh the list of user numbers
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const removeNumber = async (phoneNumber) => {
    try {
      await CallManagementService.deleteNumber(phoneNumber);
      fetchUserNumbers(); // Refresh the list of user numbers
    } catch (error) {
      console.error(error);
    }
  };

  const handleAgentCreated = () => {
    fetchUserAgents();
  };

  const renderNumbersList = () => (
    <Card title={t("Your Phone Numbers")} className="mb-6">
      <List
        dataSource={userNumbers}
        renderItem={(number) => (
          <List.Item
            actions={[
              <Popconfirm
                key={number.phoneNumber}
                title={t("Are you sure you want to remove this number?")}
                onConfirm={() => removeNumber(number.phoneNumber)}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <Button icon={<DeleteOutlined />} danger>
                  {t("Remove")}
                </Button>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              avatar={<PhoneOutlined />}
              title={number.friendlyName}
              description={
                <>
                  <div>{`${number.phoneNumber}`}</div>
                  {number.agentId && (
                    <Tag color="green">
                      <LinkOutlined /> {number.agentName || t("Agent assigned")}
                    </Tag>
                  )}
                </>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );

  const renderPurchaseNumber = () => (
    <Card title={t("Purchase Phone Number")} className="mb-6">
      <Form form={form} onFinish={searchNumbers} layout="vertical">
        <Button
          type="primary"
          htmlType="submit"
          icon={<SearchOutlined />}
          loading={searching}
          className="w-full bg-orange-500 hover:bg-orange-600"
        >
          {t("Search Available Numbers")}
        </Button>
      </Form>

      {availableNumbers.length > 0 && (
        <div className="mt-4">
          <Form.Item label={t("Select a Number")}>
            <Select
              value={selectedNumber}
              onChange={setSelectedNumber}
              placeholder={t("Choose a phone number")}
              className="w-full"
            >
              {availableNumbers.map((number) => (
                <Option key={number.phoneNumber} value={number.phoneNumber}>
                  {number.friendlyName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Button
            type={!selectedNumber ? undefined : "primary"}
            onClick={purchaseNumber}
            loading={loading}
            disabled={!selectedNumber}
            className="w-full bg-orange-500 hover:bg-orange-600"
          >
            {t("Purchase Number")}
          </Button>
        </div>
      )}
    </Card>
  );

  return (
    <div className="max-w-4xl mx-auto p-6">
      <Tabs defaultActiveKey="numbers">
        <TabPane tab={t("Phone Numbers")} key="numbers">
          {renderNumbersList()}
          {renderPurchaseNumber()}
        </TabPane>
        <TabPane tab={t("AI Agents")} key="agents">
          <AgentConfiguration
            agents={userAgents}
            onAgentCreated={handleAgentCreated}
            onAgentUpdated={fetchUserAgents}
            onAgentDeleted={fetchUserAgents}
          />
        </TabPane>
        <TabPane tab={t("Agent Assignment")} key="assignment">
          <AgentAssignment
            phoneNumbers={userNumbers}
            agents={userAgents}
            onAssignmentUpdated={fetchUserNumbers}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
