import { SendOutlined } from "@ant-design/icons";
import { Button, Card, Input, List, Result, Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PublicService from "../../../service/PublicService";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const { TextArea } = Input;

const getOrCreateUUID = (webinarId) => {
  const storageKey = `webinar_uuid_${webinarId}`;
  let uuid = localStorage.getItem(storageKey);
  if (!uuid) {
    uuid = uuidv4();
    localStorage.setItem(storageKey, uuid);
  }
  return uuid;
};

const getOrCreateViewerCount = () => {
  let count = localStorage.getItem("webinar_viewer_count");
  if (!count) {
    count = (Math.floor(Math.random() * (1000 - 200 + 1)) + 200).toString();
    localStorage.setItem("webinar_viewer_count", count);
  }
  return Number.parseInt(count);
};

export default function PublicWebinar() {
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const [webinar, setWebinar] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [uuid] = useState(() => getOrCreateUUID(id));
  const [viewerCount, setViewerCount] = useState(getOrCreateViewerCount());
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(null);
  const [isWebinarLive, setIsWebinarLive] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [hasWatched, setHasWatched] = useState(false);

  const getNextWebinarTime = useCallback((timeSlots) => {
    if (!timeSlots || timeSlots.length === 0) return null;

    const now = dayjs();
    const currentWeek = now.startOf("week");
    let nextSlot = null;
    let minDiff = Number.POSITIVE_INFINITY;

    timeSlots.forEach((slot) => {
      const dayIndex = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ].indexOf(slot.day);

      if (dayIndex === -1) return;

      // Calculate the next occurrence of this weekday
      let slotDate = currentWeek.add(dayIndex, "day");

      // Set the time for this slot
      slotDate = slotDate
        .hour(Number.parseInt(slot.startHour))
        .minute(Number.parseInt(slot.startMinute))
        .second(0);

      // If this time has passed this week, check next week
      if (slotDate.isBefore(now)) {
        slotDate = slotDate.add(1, "week");
      }

      const diff = slotDate.diff(now);
      if (diff < minDiff) {
        minDiff = diff;
        nextSlot = {
          startTime: slotDate,
          endTime: slotDate
            .hour(Number.parseInt(slot.startHour + 2))
            .minute(Number.parseInt(slot.startMinute)),
          duration: 2 * 60,
        };
      }
    });

    return nextSlot;
  }, []);

  const updateWebinarStatus = useCallback((webinarData) => {
    if (!webinarData?.timeSlots) return;

    const now = dayjs();
    let isLive = false;
    let nextSlotStart = null;

    // Check if current time falls within any time slot
    webinarData.timeSlots.forEach((slot) => {
      const dayIndex = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ].indexOf(slot.day);

      if (dayIndex === -1) return;

      const slotDate = now.day(dayIndex);
      const startTime = slotDate
        .hour(Number.parseInt(slot.startHour))
        .minute(Number.parseInt(slot.startMinute));
      const endTime = slotDate
        .hour(Number.parseInt(slot.startHour + 2))
        .minute(Number.parseInt(slot.startMinute));

      // If slot is in the past, check next week
      if (endTime.isBefore(now)) {
        startTime.add(1, "week");
        endTime.add(1, "week");
      }

      if (
        now.isAfter(startTime.subtract(1, "minute")) &&
        now.isBefore(endTime)
      ) {
        isLive = true;
      }

      // Find the next slot start time
      if (
        startTime.isAfter(now) &&
        (!nextSlotStart || startTime.isBefore(nextSlotStart))
      ) {
        nextSlotStart = startTime;
      }
    });

    setIsWebinarLive(isLive);

    if (!isLive && nextSlotStart) {
      setCountdown(nextSlotStart.diff(now));
    } else {
      setCountdown(null);
    }

    setInitialLoading(false);
  }, []);

  const fetchWebinar = useCallback(async () => {
    try {
      const response = await PublicService.getWebinar(id);
      setWebinar(response.data);
      updateWebinarStatus(response.data);
    } catch (error) {
      console.error("Failed to fetch webinar:", error);
    }
  }, [id, updateWebinarStatus]);

  const fetchComments = useCallback(async () => {
    try {
      const response = await PublicService.getWebinarComments(id, uuid);
      setComments(response.data);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  }, [id, uuid]);

  const handleCommentSubmit = async () => {
    try {
      await PublicService.addWebinarComment(id, newComment, uuid);
      setNewComment("");
      fetchComments();
    } catch (error) {
      console.error("Failed to add comment:", error);
    }
  };

  const generateAIComment = useCallback(async () => {
    try {
      await PublicService.webinarAIComment(id);
      fetchComments();
    } catch (error) {
      console.error("Failed to generate AI comment:", error);
    }
  }, [id, fetchComments]);

  useEffect(() => {
    if (id) {
      fetchWebinar();
      fetchComments();
    }
  }, [id, fetchWebinar, fetchComments]);

  useEffect(() => {
    const aiInterval = setInterval(generateAIComment, 20000);
    return () => clearInterval(aiInterval);
  }, [generateAIComment]);

  useEffect(() => {
    const viewerInterval = setInterval(() => {
      setViewerCount((prevCount) => {
        const change = Math.floor(Math.random() * 11) - 5; // Random number between -5 and 5
        const newCount = Math.max(200, Math.min(1000, prevCount + change));
        localStorage.setItem("webinar_viewer_count", newCount.toString());
        return newCount;
      });
    }, 15000);
    return () => clearInterval(viewerInterval);
  }, []);

  useEffect(() => {
    let timer;
    if (countdown !== null) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1000) {
            clearInterval(timer);
            setIsWebinarLive(true);
            fetchWebinar();
            return null;
          }
          return prev - 1000;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown, fetchWebinar]);

  useEffect(() => {
    if (isWebinarLive && !hasWatched) {
      const token = searchParams.get("token");

      const markAsWatched = async () => {
        try {
          await PublicService.markWebinarAsWatched(id, token);
          setHasWatched(true);
        } catch (error) {
          console.error("Failed to mark webinar as watched:", error);
        }
      };
      markAsWatched();
    }
  }, [isWebinarLive, hasWatched, id, uuid, searchParams]);

  const renderCountdown = () => {
    if (countdown === null) return null;
    const duration = dayjs.duration(countdown);
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const nextSlot = getNextWebinarTime(webinar?.timeSlots);
    const nextDay = nextSlot?.startTime.format("dddd");
    const nextTime = nextSlot?.startTime.format("HH:mm");

    return (
      <div className="text-center my-4">
        <h2 className="text-xl font-bold mb-2">
          {t("Next webinar starts on {{nextDay}} at {{nextTime}}", {
            nextDay,
            nextTime,
          })}
        </h2>
        <div className="text-3xl font-bold">
          {days > 0 && `${days}d `}
          {hours > 0 && `${hours}h `}
          {minutes > 0 && `${minutes}m `}
          {seconds}s
        </div>
      </div>
    );
  };

  if (initialLoading) return <Skeleton active />;

  if (!isWebinarLive && countdown === null && !initialLoading) {
    return (
      <Result
        status="info"
        title={t("Webinar has ended")}
        subTitle={t(
          "Thank you for your interest. The webinar is no longer available."
        )}
      />
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      {isWebinarLive && (
        <h1 className="text-2xl font-bold mb-4">{webinar?.title}</h1>
      )}
      {renderCountdown()}
      {isWebinarLive && (
        <Card>
          <video
            className="w-full aspect-video mb-4"
            autoPlay
            src={webinar?.videoUrl}
          >
            {t("Your browser does not support the video tag.")}
          </video>
          <p className="mb-4">{webinar?.description}</p>
          <p className="text-sm text-gray-500">
            {viewerCount} {t("viewers")}
          </p>
        </Card>
      )}

      {isWebinarLive && webinar?.chatFeature === "Use" && (
        <Card className="mt-4">
          <h2 className="text-xl font-semibold mb-4">{t("Comments")}</h2>

          <div className="mt-4">
            <TextArea
              rows={4}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment..."
            />
            <Button
              type="primary"
              icon={<SendOutlined />}
              onClick={handleCommentSubmit}
              className="mt-2"
            >
              {t("Post Comment")}
            </Button>
          </div>
          <List
            itemLayout="horizontal"
            dataSource={comments}
            renderItem={(comment) => (
              <List.Item>
                <List.Item.Meta
                  title={comment.fullname || t("You")}
                  description={comment.content}
                />
              </List.Item>
            )}
          />
        </Card>
      )}
    </div>
  );
}
