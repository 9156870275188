import React, { useCallback, useEffect, useRef, useState } from "react";
import Board from "../../../components/Board";

import {
  Alert,
  Button,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { GiConfirmed } from "react-icons/gi";
import { ImStatsDots } from "react-icons/im";
import { IoMdRefresh } from "react-icons/io";
import { IoLibrarySharp } from "react-icons/io5";
import { LuWorkflow } from "react-icons/lu";
import { MdCleaningServices, MdDelete, MdPreview } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  handleXLSXTOJSON,
  when,
} from "../../../components/Board/services/utils";
import { setPhoneCandidate } from "../../../redux/auth/actions";
import {
  getPhoneCandidate,
  selectDarkMode,
  selectLoading,
  selectUser,
} from "../../../redux/auth/selectors";
import { store } from "../../../redux/store";
import ATSService from "../../../service/ATSService";
import CrudService from "../../../service/CrudService";
import ForwardResume from "../ForwardResume";
import CandidateMeetingBox from "../Message/CandidateMeetingBox";
import CandidateRejectBox from "../Message/CandidateRejectBox";
import MeetingConfirmationBox from "../Message/MeetingConfirmationBox";
import VariableMessageBox from "../Message/VariableMessageBox";
import VariableSMSBox from "../Message/VariableSMSBox";
import CandidateNote from "./CandidateNote";
import DelayedAutomations from "./DelayedAutomations";
import DetailsModal from "./DetailsModal";
import FunnelTemplateLibrary from "./FunnelTemplateLibrary";
import WorkflowConfigurator from "./WorkflowConfigurator";

export const mappedVacancySubmission = [
  { value: "fullname", label: "Fullname" },
  { value: "firstname", label: "Firstname" },
  { value: "lastname", label: "Lastname" },
  { value: "email", label: "Email" },
  { value: "phone", label: "Phone" },
  { value: "linkedInUrl", label: "LinkedIn URL" },
];
function isBoolean(value) {
  return value === "true" || value === "false";
}

function not(fn) {
  return function (value) {
    return !fn(value);
  };
}

function getUrlParams() {
  const url = new URL(window.location.href);
  var params = {};

  for (const p of url.searchParams.entries()) {
    const value = p[1];

    when(
      value,
      isBoolean
    )((value) => {
      params[p[0]] = Boolean(value);
    });
    when(
      value,
      not(isBoolean)
    )((value) => {
      params[p[0]] = value;
    });
  }

  return params;
}

const LOAD_PER_PAGE = 25;

const LTS = ({ VacancyId }) => {
  const { t } = useTranslation();
  const [boardColumns, setBoardColumns] = useState([]);
  const [messageCandidate, setMessageCandidate] = useState(null);
  const [smsCandidate, setSMSCandidate] = useState(null);
  const [rejectCandidate, setRejectCandidate] = useState(null);
  const [detailsModal, setDetailsModal] = useState(null);
  const [scheduleCandidate, setScheduleCandidate] = useState(null);
  const [meetingConfirmBox, setMeetingConfirmBox] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  const [addModal, setAddModal] = useState(null);
  const [accountConfig, setAccountConfig] = useState(null);
  const [bulkUploadProcess, setBulkUploadProcess] = useState({});
  const [loading, setLoading] = useState(false);
  const [reloadingStages, setReloadingStages] = useState(true);
  const [templateLibrary, setTemplateLibrary] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const [noteModal, setNoteModal] = useState(null);
  const [forwardResume, setForwardResume] = useState(null);
  const [existingEmails, setExistingEmails] = useState([]);
  const [delayedAutomationModal, setDelayedAutomationModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const fileInputRef = useRef(null);
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const phoneCandidate = useSelector(getPhoneCandidate);
  const darkMode = useSelector(selectDarkMode);
  const backendLoading = useSelector(selectLoading);

  const [pages, setPages] = useState({});

  const loadMore = useCallback(
    async (id) => {
      if (!pages?.[id]) return;

      const mainFilter = {
        stageId: id === "UNCATEGORIZED" ? { $exists: false } : id,
      };
      if (VacancyId) mainFilter.VacancyId = VacancyId;
      const filters = { ...mainFilter };
      if (accountConfig?.hideRejected)
        filters.$and = [
          { ...mainFilter },
          { $or: [{ rejected: false }, { rejected: { $exists: false } }] },
        ];

      CrudService.search("VacancySubmission", LOAD_PER_PAGE, pages[id] + 1, {
        filters,
        sort: { sort: 1, createdAt: -1 },
      }).then(({ data }) => {
        if (data.items.length > 0)
          setBoardColumns((c) => {
            const current = [...c];
            const idx = current.findIndex((e) => e.id === id);

            if (idx === -1) return current;

            return [
              ...current.slice(0, idx),
              {
                id: id,
                title: current[idx].title,
                candidateCount: data.total,
                cards: [
                  ...current[idx].cards,
                  ...data.items.map((d) => ({
                    id: d._id,
                    fullname: `${d.formData?.firstname ?? ""} ${
                      d.formData?.lastname ?? ""
                    }`,
                    linkedInUrl: d.formData?.linkedInUrl,
                    email: d.formData?.email ?? "",
                    phone: d.formData?.phone ?? "",
                    rejected: d.rejected,
                    stars: d.stars,
                    createdAt: d.createdAt,
                    scheduledAt: d?.interviewMeetingTimestamp,
                  })),
                ],
                canLoadMore: data.total > data.page * data.limit,
              },
              ...current.slice(idx + 1),
            ];
          });
      });

      setPages((pages) => {
        const current = { ...pages };
        current[id] = pages[id] + 1;

        return current;
      });
    },
    [pages, accountConfig, VacancyId]
  );

  const user = useSelector(selectUser);
  useEffect(() => {
    if (!accountConfig?._id) return;
    if (!user) return;
    if (user?.accessLevel === "read") return;

    CrudService.update("AccountConfiguration", accountConfig._id, {
      hideRejected: accountConfig.hideRejected,
    }).then(() => {
      reloadStages({ noLoadingDisplay: true });
    });
  }, [accountConfig, user]);

  useEffect(() => {
    CrudService.search("AccountConfiguration", 1, 1, {}).then(({ data }) => {
      const config = data.items?.[0];
      if (!config) return;

      setAccountConfig({ ...config });
    });
  }, []);

  const reloadStages = useCallback(
    async ({ noLoadingDisplay, text = "" } = { noLoadingDisplay: false }) => {
      if (!noLoadingDisplay) setReloadingStages(true);

      await ATSService.reloadStages({
        limit: LOAD_PER_PAGE,
        page: 1,
        sort: { sort: 1, createdAt: -1 },
        VacancyId,
        text,
      })
        .then(({ data }) => {
          setBoardColumns(data.boardColumns.filter((a) => !!a));
          setPages((pages) => {
            const current = { ...pages };
            current["UNCATEGORIZED"] = 1;
            for (const column of data.boardColumns.filter((a) => !!a)) {
              current[column.id] = 1;
            }

            return current;
          });
        })
        .finally(() => {
          if (!noLoadingDisplay) setReloadingStages(false);
        });
    },
    [VacancyId]
  );
  useEffect(() => {
    reloadStages();
  }, [reloadStages]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      handleXLSXTOJSON({ sheet: file }, async (json) => {
        json.shift();

        let mappings = {};
        try {
          mappings = JSON.parse(
            localStorage[
              `importfile_mapping_${Object.keys(json?.[0]).join("_")}`
            ]
          );
        } catch (e) {}

        setBulkUploadProcess((current) => ({ ...current, json, mappings }));
      });
    }
  };

  const performSearch = useCallback((text) => {
    reloadStages({
      text: text ? text : undefined,
      noLoadingDisplay: true,
    });
  }, []);

  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      performSearch(newValue);
    }, 1000);
  };

  const HideIcon = accountConfig?.hideRejected ? BiSolidHide : BiSolidShow;

  if (reloadingStages) return <Skeleton active />;
  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between gap-3">
        <input
          type="text"
          placeholder={t("Search Leads")}
          className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
          value={searchTerm}
          onChange={handleInputChange}
        />

        <Space className="flex justify-center items-center mdx:justify-end">
          {!(user?.accessPhoneCallOutbound && !user?.accessAny) && (
            <MdPreview
              size={22}
              className="cursor-pointer"
              title={t("Preview Landing Page")}
              onClick={async () => {
                window.open(`/page/${VacancyId}`);
              }}
            />
          )}

          <HideIcon
            size={25}
            title={t("Hide Rejected")}
            className="cursor-pointer"
            onClick={() => {
              setAccountConfig((c) => ({
                ...c,
                hideRejected: !c.hideRejected,
              }));
            }}
          />

          <Popconfirm
            title={t(
              "This will unrecoverably delete all leads that are marked as unqualified in this funnel"
            )}
            onConfirm={async () => {
              await ATSService.cleanupRejected(VacancyId);
              await reloadStages({ noLoadingDisplay: true });
            }}
          >
            <MdCleaningServices
              size={25}
              title={t("Cleanup Rejected")}
              className="cursor-pointer"
            />
          </Popconfirm>

          <IoMdRefresh
            size={25}
            title={t("Refresh")}
            className="cursor-pointer"
            onClick={() => reloadStages()}
          />
        </Space>
      </div>

      {contextHolder}

      <Board
        onCardOptionClick={(id, option, data) => {
          if (option === "email") {
            setLastScroll(window.scrollY);
            setMessageCandidate(id);
          }
          if (option === "sms") {
            setLastScroll(window.scrollY);
            setSMSCandidate(id);
          }
          if (option === "phone") {
            if (phoneCandidate)
              return message.info(t("Please close the active tab"));
            setLastScroll(window.scrollY);
            store.dispatch(setPhoneCandidate(id));
          }
          if (option === "reject") {
            setLastScroll(window.scrollY);
            setRejectCandidate(id);
          }
          if (option === "schedule") {
            setLastScroll(window.scrollY);
            setScheduleCandidate(id);
          }
          if (option === "delete-column") {
            if (user?.accessLevel === "read")
              return message.error(t("Your access is read-only"));
            if (id === "UNCATEGORIZED")
              return message.info(
                t(
                  "This column cannot be removed. You need to categorize or delete all of the leads."
                )
              );
            modal.confirm({
              title: t("Confirm Deletion"),
              content: t(
                "You are about to delete a stage (column). All of the corresponding applicants will be safely moved to the uncategorized column."
              ),
              okText: t("DELETE"),
              cancelText: t("Cancel"),
              closable: true,
              onOk: async () => {
                await ATSService.deleteStage(id);
                setBoardColumns((cur) => {
                  const current = [...cur].filter((a) => a.id !== id);
                  return current;
                });
                await reloadStages({ noLoadingDisplay: true });
              },
            });
          }
          if (option === "undo-reject") {
            ATSService.undoRejectCandidate({ candidateId: id });
            setBoardColumns((c) => {
              const current = [...c];

              const column = current.find((c) =>
                c.cards.some((card) => card.id === id)
              );
              const card = column.cards.find((card) => card.id === id);
              card.rejected = false;

              return current;
            });
          }
          if (option === "stars-pick") {
            if (user?.accessLevel === "read")
              return message.error("Your access is read-only");

            setBoardColumns((c) => {
              const current = [...c];

              const column = current.find((c) =>
                c.cards.some((card) => card.id === id)
              );
              const card = column.cards.find((card) => card.id === id);
              card.stars = data.e;

              return current;
            });
            CrudService.update("VacancySubmission", id, {
              stars: data.e,
            });
          }
          if (option === "open-note") {
            setLastScroll(window.scrollY);
            setNoteModal(id);
          }
          if (option === "forward-resume") {
            setLastScroll(window.scrollY);
            setForwardResume(id);
          }
          if (option === "details-modal") {
            setLastScroll(window.scrollY);
            setDetailsModal(id);
          }

          if (option === "import") {
            if (user?.accessLevel === "read")
              return message.error("Your access is read-only");

            console.log(VacancyId, id);
            setLastScroll(window.scrollY);

            fileInputRef.current.value = "";
            setBulkUploadProcess({
              stageId: id,
            });
            fileInputRef.current.click();
          }

          if (option === "export") {
            const card = boardColumns.find((e) => e.id === id);
            const candidates = boardColumns.find((e) => e.id === id)?.cards;
            if (!candidates) return;

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(
              candidates.map((e) => {
                delete e?.id;
                delete e?.rejected;

                return e;
              })
            );

            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
            XLSX.writeFile(
              workbook,
              `Candidates ${card.title} ${moment().format(
                "DD-MM-YYYY HH:mm"
              )}.xlsx`
            );
          }

          if (option === "delayed-automation") {
            setDelayedAutomationModal(id);
          }

          if (option === "workflow") {
            setLastScroll(window.scrollY);
            setWorkflow({
              stageId: id,
            });
          }
          if (option === "add-candidate") {
            setLastScroll(window.scrollY);
            setAddModal({
              stageId: id,
            });
          }
        }}
        {...getUrlParams()}
        handleCardMove={async (source, destination, subject) => {
          if (user?.accessLevel === "read")
            return message.error(t("Your access is read-only"));

          if (!!subject?.cards) {
            if (subject.id === "UNCATEGORIZED")
              return message.info(t("This column cannot be moved"));
            const currentColumns = [...boardColumns];

            const sortedColumns = [
              ...currentColumns
                .filter((c) => c.id !== subject.id)
                .map((c, i) => ({ ...c, sort: i + 1 })),
              { ...subject, sort: destination.toPosition },
            ].sort((a, b) => a.sort - b.sort);

            setBoardColumns(sortedColumns);
            await CrudService.update("VacancyStage", null, {
              bulkItems: sortedColumns
                .filter((c) => c.id !== "UNCATEGORIZED")
                .map((col, i) => ({
                  _id: col.id,
                  sort: i,
                })),
            });
            await reloadStages({ noLoadingDisplay: true });
          } else {
            console.log(source, destination, subject);
            if (!subject) return;
            if (destination.toColumnId === "UNCATEGORIZED")
              return message.info(t("Cannot move lead into uncategorized"));
            if (destination.toColumnId === "new")
              return message.error(
                t("Not so quick! Try again in a few seconds.")
              );

            setBoardColumns((c) => {
              const current = [...c];

              const sourceCol = current.find(
                (c) => c.id === source.fromColumnId
              );
              if (sourceCol?.cards)
                sourceCol.cards = sourceCol.cards.filter(
                  (c) => c.id !== subject.id
                );

              const destinationCol = current.find(
                (c) => c.id === destination.toColumnId
              );
              destinationCol.cards = [
                ...destinationCol.cards.map((c, i) => ({ ...c, sort: i + 1 })),
                { ...subject, sort: destination.toPosition },
              ].sort((a, b) => a.sort - b.sort);

              ATSService.moveCandidate({
                targetStage: destination.toColumnId,
                candidateId: subject.id,
                destinationCol: destinationCol.cards.map((c) => c.id),
              }).then(() => {
                reloadStages({ noLoadingDisplay: true });
              });

              return current;
            });
          }
        }}
        loadMore={loadMore}
        VacancyId={VacancyId}
        allowAddColumn
        onNewColumnConfirm={async (e) => {
          if (user?.accessLevel === "read")
            return message.error(t("Your access is read-only"));

          const response = await CrudService.create("VacancyStage", {
            name: e.title,
            sort: 100,
            vacancyId: VacancyId,
          });

          setBoardColumns((cur) => {
            const current = [...cur];
            current.push({
              id: response.data.result._id,
              title: e.title,
              cards: [],
              canLoadMore: false,
              sort: 100,
            });
            return current;
          });

          await reloadStages({ noLoadingDisplay: true });
        }}
        onColumnRename={async (id, e) => {
          if (user?.accessLevel === "read")
            return message.error(t("Your access is read-only"));

          setBoardColumns((cur) => {
            const current = [...cur];
            const changed = current.find((c) => c.id === id);
            if (changed) changed.title = e;
            return current;
          });
          await CrudService.update("VacancyStage", id, { name: e });
          await reloadStages({ noLoadingDisplay: true });
        }}
      >
        {{
          columns: boardColumns,
        }}
      </Board>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!messageCandidate}
        onCancel={() => setMessageCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <VariableMessageBox
          candidateId={messageCandidate}
          onSend={() => {
            setMessageCandidate(null);
            reloadStages({ noLoadingDisplay: true });
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!smsCandidate}
        onCancel={() => setSMSCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <VariableSMSBox
          candidateId={smsCandidate}
          onSend={() => {
            setSMSCandidate(null);
            reloadStages({ noLoadingDisplay: true });
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!rejectCandidate}
        onCancel={() => setRejectCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <CandidateRejectBox
          candidateId={rejectCandidate}
          onSend={() => {
            setRejectCandidate(null);
            reloadStages({ noLoadingDisplay: true });
            setBoardColumns((c) => {
              const current = [...c];

              const column = current.find((c) =>
                c.cards.some((card) => card.id === rejectCandidate)
              );
              const card = column.cards.find(
                (card) => card.id === rejectCandidate
              );
              card.rejected = true;

              if (accountConfig?.hideRejected)
                column.cards = column.cards.filter((c) => c.rejected === false);

              return current;
            });
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!scheduleCandidate}
        onCancel={() => setScheduleCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <CandidateMeetingBox
          candidateId={scheduleCandidate}
          onSend={() => {
            setScheduleCandidate(null);
            reloadStages({ noLoadingDisplay: true });
          }}
          close={() => setScheduleCandidate(null)}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!meetingConfirmBox}
        onCancel={() => setMeetingConfirmBox(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <MeetingConfirmationBox
          VacancyId={VacancyId}
          onSend={() => {
            setMeetingConfirmBox(null);
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!bulkUploadProcess?.json?.[0]}
        onCancel={() => setBulkUploadProcess({})}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <Alert
          type="info"
          message={t("Please check and verify the data")}
          className="mt-5"
        />
        {bulkUploadProcess?.mappedItems ? (
          <div className="overflow-auto">
            <table className="min-w-full divide-y divide-gray-200 mt-5 mb-3">
              <thead>
                <tr className="font-bold">
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {t("Delete")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {t("Firstname")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {t("Lastname")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {t("Email")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {t("Phone")}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {t("LinkedIn URL")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200">
                {bulkUploadProcess?.mappedItems?.map((line, i) => (
                  <tr key={i}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <MdDelete
                        onClick={() => {
                          setBulkUploadProcess((cur) => {
                            const current = { ...cur };
                            current.mappedItems.splice(i, 1);
                            return current;
                          });
                        }}
                        className="cursor-pointer text-red-500 relative top-0.5 start-1"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };
                              current.mappedItems[i].firstname = e;
                              return current;
                            });
                          },
                        }}
                      >
                        {line?.firstname ?? ""}
                      </Typography.Paragraph>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };
                              current.mappedItems[i].lastname = e;
                              return current;
                            });
                          },
                        }}
                      >
                        {line?.lastname ?? ""}
                      </Typography.Paragraph>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap flex">
                      {existingEmails?.includes?.(line?.email) &&
                        (user?.isAdmin ||
                          user?.leadModuleAccess ||
                          process.env.NODE_ENV !== "production") &&
                        "*"}
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };
                              current.mappedItems[i].email = e;
                              return current;
                            });
                          },
                        }}
                      >
                        {line?.email ?? ""}
                      </Typography.Paragraph>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };
                              current.mappedItems[i].phone = e;
                              return current;
                            });
                          },
                        }}
                      >
                        {line?.phone ?? ""}
                      </Typography.Paragraph>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };
                              current.mappedItems[i].linkedInUrl = e;
                              return current;
                            });
                          },
                        }}
                      >
                        {line?.linkedInUrl ?? ""}
                      </Typography.Paragraph>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-end">
              <button
                onClick={async () => {
                  setLoading(true);
                  try {
                    const chunk = (arr, size) =>
                      Array.from(
                        { length: Math.ceil(arr.length / size) },
                        (v, i) => arr.slice(i * size, i * size + size)
                      );

                    const chunks = chunk(
                      bulkUploadProcess.mappedItems.map((formData) => ({
                        VacancyId,
                        stageId: bulkUploadProcess.stageId,
                        formData,
                        searchIndex: JSON.stringify(formData),
                      })),
                      25
                    );

                    for (const bulkItems of chunks) {
                      const result = await CrudService.create(
                        "VacancySubmission",
                        {
                          bulkItems,
                        }
                      );

                      if (result.data?.result)
                        await ATSService.importCandidates({
                          candidateIds: result.data.result.map((a) => a._id),
                        });
                    }

                    await reloadStages({ noLoadingDisplay: true });

                    setBulkUploadProcess({});
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setLoading(false);
                  }
                }}
                className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                disabled={loading}
              >
                {!loading ? t("Import") : <Spin>{t("Import")}</Spin>}
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="font-bold flex items-center justify-between mt-5 mb-3">
              <div>{t("Column of Imported File")}</div>
              <div>{t("Target Column")}</div>
            </div>
            {bulkUploadProcess?.json?.[0] &&
              Object.keys(bulkUploadProcess?.json?.[0]).map((key, i) => (
                <div key={i} className="flex items-center justify-between mb-1">
                  <div>{key}</div>
                  <div>
                    <Select
                      className="min-w-[120px]"
                      value={bulkUploadProcess?.mappings?.[key]}
                      allowClear
                      onChange={(e) =>
                        setBulkUploadProcess((cur) => {
                          const current = { ...cur };
                          if (!current?.mappings) current.mappings = {};
                          current.mappings[key] = e;
                          localStorage[
                            `importfile_mapping_${Object.keys(
                              bulkUploadProcess.json[0]
                            ).join("_")}`
                          ] = JSON.stringify(current.mappings);
                          return current;
                        })
                      }
                    >
                      {mappedVacancySubmission
                        .filter((item) => {
                          return (
                            !bulkUploadProcess?.mappings ||
                            bulkUploadProcess?.mappings[key] === item.value ||
                            !Object.values(bulkUploadProcess.mappings).includes(
                              item.value
                            )
                          );
                        })
                        .map((item) => (
                          <Select.Option
                            key={item.value}
                            value={item.value}
                            label={t(item.label)}
                          >
                            {t(item.label)}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                </div>
              ))}

            <div className="flex justify-end mt-2">
              <button
                onClick={async () => {
                  const keys = Object.keys(bulkUploadProcess.mappings);
                  const values = Object.values(bulkUploadProcess.mappings);

                  if (!values.includes("email"))
                    return message.info(
                      t("Please select a mapping column for email")
                    );
                  if (
                    !values.includes("firstname") &&
                    !values.includes("fullname")
                  )
                    return message.info(
                      t(
                        "Please select a mapping column for firstname or fullname"
                      )
                    );
                  if (
                    !values.includes("lastname") &&
                    !values.includes("fullname")
                  )
                    return message.info(
                      t(
                        "Please select a mapping column for lastname or fullname"
                      )
                    );

                  const emailKey = Object.keys(bulkUploadProcess.mappings).find(
                    (key) => bulkUploadProcess.mappings[key] === "email"
                  );
                  if (emailKey) {
                    const emails = bulkUploadProcess.json.map(
                      (item) => item[emailKey]
                    );

                    const existing = await ATSService.getExisting(emails);

                    const existingEmails = existing.data.items.map(
                      (d) => d?.email
                    );
                    setExistingEmails(Array.from(new Set(existingEmails)));
                  }

                  const mappedItems = bulkUploadProcess.json
                    .map((item) => {
                      const mappedItem = {};
                      for (const key of keys)
                        mappedItem[bulkUploadProcess.mappings[key]] = item[key];

                      if (mappedItem.fullname) {
                        mappedItem.firstname = mappedItem.fullname
                          .split(" ")
                          ?.slice(0, -1)
                          ?.join(" ");
                        mappedItem.lastname = mappedItem.fullname
                          .split(" ")
                          ?.slice(-1)
                          ?.join(" ");
                        if (!mappedItem.firstname && mappedItem.lastname) {
                          mappedItem.firstname = `${mappedItem.lastname}`;
                          mappedItem.lastname = "";
                        }

                        delete mappedItem.fullname;
                      }
                      return mappedItem;
                    })
                    .filter((a) => {
                      if (Object.keys(a).every((key) => !a[key])) return false;

                      return true;
                    });
                  setBulkUploadProcess((current) => ({
                    ...current,
                    mappedItems,
                  }));
                }}
                className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                disabled={backendLoading}
              >
                {!backendLoading ? t("Import") : <Spin>{t("Import")}</Spin>}
              </button>
            </div>
          </>
        )}
      </Modal>

      {/* For bulk upload */}
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".xlsx,.csv"
      />

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!workflow}
        onCancel={() => setWorkflow(null)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <WorkflowConfigurator workflow={workflow} VacancyId={VacancyId} />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!addModal}
        onCancel={() => setAddModal(null)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
        title={t("Add Lead")}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!addModal?.stageId) return setAddModal(null);
            const firstname = e.target[0].value;
            const lastname = e.target[1].value;
            const email = e.target[2].value;
            const phone = e.target[3].value;
            const linkedInUrl = e.target[4].value;

            const formData = {
              firstname,
              lastname,
              email,
              phone,
              linkedInUrl,
            };
            const result = await CrudService.create("VacancySubmission", {
              bulkItems: [
                {
                  VacancyId,
                  stageId: addModal?.stageId,
                  searchIndex: JSON.stringify(formData),
                  formData,
                },
              ],
            });

            if (result.data?.result)
              await ATSService.importCandidates({
                candidateIds: result.data.result.map((a) => a._id),
              });

            await reloadStages({ noLoadingDisplay: true });
            setAddModal(null);
            setPhoneValue("");
          }}
        >
          <div className="mb-2 mt-5">
            <input
              type="text"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder={t("Firstname")}
            />
            <input
              type="text"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder={t("Lastname")}
            />
            <input
              type="email"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder={t("Email")}
            />
            <PhoneInput
              placeholder={t("Phone")}
              country="jp"
              className="w-full mt-2"
              inputClass="dark:!bg-gray-900"
              dropdownClass="dark:!text-black"
              buttonClass="dark:!bg-gray-900"
              value={phoneValue}
              onChange={(e) =>
                setPhoneValue(
                  (e?.target?.value ?? e).startsWith("0")
                    ? `+81${(e?.target?.value ?? e).slice(1)}`
                    : e?.target?.value ?? e
                )
              }
            />
            <input
              type="linkedInUrl"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder={t("LinkedIn URL (optional)")}
            />
          </div>
          <div className="w-full justify-end flex mt-2">
            <Button
              className="text-sm bg-indigo-500 text-white rounded"
              loading={backendLoading}
              htmlType="submit"
            >
              {t("Add Lead")}
            </Button>
          </div>
        </form>
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!noteModal}
        onCancel={() => setTimeout(() => setNoteModal(null), 750)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <CandidateNote candidateId={noteModal} />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!forwardResume}
        onCancel={() => setTimeout(() => setForwardResume(null), 750)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <ForwardResume
          candidateId={forwardResume}
          onSend={() => setForwardResume(null)}
        />
      </Modal>

      <Modal
        wrapClassName={darkMode ? "dark" : ""}
        width={"90vh"}
        onCancel={() => {
          window.scrollTo(0, lastScroll);
          setTimeout(() => setDetailsModal(null), 750);
        }}
        open={!!detailsModal}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <DetailsModal candidateId={detailsModal} />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!templateLibrary}
        onCancel={() => setTemplateLibrary(false)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <FunnelTemplateLibrary
          candidateId={noteModal}
          vacancyId={VacancyId}
          onFinish={async () => {
            await reloadStages({ noLoadingDisplay: true });
            setTemplateLibrary(false);
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!delayedAutomationModal}
        onCancel={() => setDelayedAutomationModal(false)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <DelayedAutomations stageId={delayedAutomationModal} />
      </Modal>
    </>
  );
};

export default LTS;
