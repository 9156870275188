"use client";

import { useCallback, useEffect, useState } from "react";
import { Button, Card, message, Upload, Modal, Spin, Popconfirm } from "antd";
import {
  UploadOutlined,
  PictureOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import PublicService from "../../../../service/PublicService";
import UploadService from "../../../../service/UploadService";
import StockImagePicker from "./StockImagePicker";
import CreativeTemplates from "./CreativeTemplates";
import CreativeEditor from "./CreativeEditor";
import { useNavigate, useSearchParams } from "react-router-dom";
import CrudService from "../../../../service/CrudService";
import { store } from "../../../../redux/store";
import { login } from "../../../../redux/auth/actions";
import { useSelector } from "react-redux";
import { selectLoading, selectUser } from "../../../../redux/auth/selectors";
import AuthService from "../../../../service/AuthService";
import UserService from "../../../../service/UserService";

// Improved ad templates with better visual design
const adTemplates = [
  {
    id: 1,
    name: "Modern Overlay",
    defaultShapes: [
      {
        type: "rect",
        left: 0,
        top: 0,
        width: 600,
        height: 400,
        fill: "linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 100%)",
        opacity: 1,
      },
      {
        type: "rect",
        left: 40,
        top: 220,
        width: 520,
        height: 4,
        fill: "#ffffff",
        opacity: 0.9,
      },
    ],
    textPlacement: {
      headline: {
        left: 40,
        top: 230,
        width: 520,
        fontSize: 38,
        fontFamily: "Arial",
        fontWeight: "bold",
        textAlign: "left",
      },
      description: {
        left: 40,
        top: 300,
        width: 520,
        fontSize: 16,
        fontFamily: "Arial",
        textAlign: "left",
      },
    },
  },
  {
    id: 2,
    name: "Centered Impact",
    defaultShapes: [
      {
        type: "rect",
        left: 0,
        top: 0,
        width: 600,
        height: 400,
        fill: "linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.4) 100%)",
        opacity: 1,
      },
      {
        type: "rect",
        left: 150,
        top: 190,
        width: 400,
        height: 3,
        fill: "#ffffff",
        opacity: 0.8,
      },
    ],
    textPlacement: {
      headline: {
        left: 100,
        top: 80,
        width: 400,
        fontSize: 36,
        fontFamily: "Arial",
        fontWeight: "bold",
        textAlign: "center",
      },
      description: {
        left: 75,
        top: 210,
        width: 450,
        fontSize: 18,
        fontFamily: "Arial",
        textAlign: "center",
      },
    },
  },
  {
    id: 3,
    name: "Dynamic Corner",
    defaultShapes: [
      {
        type: "rect",
        left: 0,
        top: 0,
        width: 600,
        height: 400,
        fill: "linear-gradient(135deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 50%)",
        opacity: 1,
      },
      {
        type: "rect",
        left: 20,
        top: 90,
        width: 60,
        height: 3,
        fill: "#ffffff",
        opacity: 0.9,
      },
    ],
    textPlacement: {
      headline: {
        left: 20,
        top: 20,
        width: 350,
        fontSize: 28,
        fontFamily: "Arial",
        fontWeight: "bold",
        textAlign: "left",
      },
      description: {
        left: 20,
        top: 120,
        width: 350,
        fontSize: 14,
        fontFamily: "Arial",
        textAlign: "left",
      },
    },
  },
];

// Helper function to sanitize keys for adImageData
const sanitizeKey = (key) => key.replace(/\//g, "__").replace(/\./g, "_dot_");

const AdCreativeGenerator = () => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [generatedCreatives, setGeneratedCreatives] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isStockPickerVisible, setIsStockPickerVisible] = useState(false);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [funnel, setFunnel] = useState(null);
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generatedImageData, setGeneratedImageData] = useState({});

  useEffect(() => {
    const getData = () => {
      const id = searchParams.get("id");
      if (id) {
        setFormData(null);

        CrudService.getSingle("AdCampaign", id).then(async (res) => {
          if (res.data) {
            setFormData(res.data);
            try {
              const funnel = await CrudService.getSingle(
                "Vacancy",
                res.data.vacancy
              );
              setFunnel(funnel);
            } catch (error) {
              console.error("Error fetching vacancy data:", error);
            }
          }

          // Load saved images and image data from the database
          setGeneratedImages(res.data?.adImages ?? []);
          setGeneratedImageData(res.data?.adImageData ?? {});
        });
      }
    };

    getData();
  }, [searchParams]);

  // Add image to campaign images in the database
  const addImageToCampaignImages = async (imageUrl) => {
    const id = searchParams.get("id");
    if (!id) return;

    try {
      // Update local state
      setGeneratedImages((prev) => {
        if (prev.includes(imageUrl)) return prev;
        return [...prev, imageUrl];
      });

      // Update database
      const res = await CrudService.getSingle("AdCampaign", id);
      const currentImages = res.data?.adImages || [];

      if (!currentImages.includes(imageUrl)) {
        await CrudService.update("AdCampaign", id, {
          adImages: [...currentImages, imageUrl],
        });
      }
    } catch (error) {
      console.error("Error adding image to campaign:", error);
      message.error(t("Failed to save image to campaign"));
    }
  };

  // Update the saveCreativeData function to handle the rendered image URL
  const saveCreativeData = async (
    originalImageUrl,
    creativeData,
    renderedImageUrl
  ) => {
    const id = searchParams.get("id");
    if (!id) return;

    try {
      const sanitizedKey = sanitizeKey(originalImageUrl);

      // First, remove the original image from the array
      const res = await CrudService.getSingle("AdCampaign", id);
      const currentImages = res.data?.adImages || [];

      // Filter out the original image and any previous rendered versions
      const filteredImages = currentImages.filter(
        (img) =>
          img !== originalImageUrl &&
          // Also filter out any previously rendered versions that might be in the array
          !(
            generatedImageData[sanitizedKey] &&
            JSON.parse(generatedImageData[sanitizedKey])?.renderedImageUrl ===
              img
          )
      );

      // Add the new rendered image
      const updatedImages = [...filteredImages, renderedImageUrl];

      // Update local state for image data
      setGeneratedImageData((prev) => ({
        ...prev,
        [sanitizedKey]: JSON.stringify({
          ...creativeData,
          renderedImageUrl,
          originalImageUrl,
          lastModified: new Date().toISOString(),
        }),
      }));

      // Update database
      await CrudService.update("AdCampaign", id, {
        [`adImageData.${sanitizedKey}`]: JSON.stringify({
          ...creativeData,
          renderedImageUrl,
          originalImageUrl,
          lastModified: new Date().toISOString(),
        }),
        adImages: updatedImages,
      });

      // Update local state for images
      setGeneratedImages(updatedImages);

      message.success(t("Creative saved successfully"));
    } catch (error) {
      console.error("Error saving creative data:", error);
      message.error(t("Failed to save creative data"));
    }
  };

  // Delete image from campaign
  const deleteImage = async (imageUrl) => {
    const id = searchParams.get("id");
    if (!id) return;

    try {
      // Update local state
      setGeneratedImages((prev) => prev.filter((img) => img !== imageUrl));
      setGeneratedImageData((prev) => {
        const newData = { ...prev };
        delete newData[sanitizeKey(imageUrl)];
        return newData;
      });

      // Update database
      const res = await CrudService.getSingle("AdCampaign", id);
      const updatedImages = (res.data?.adImages || []).filter(
        (img) => img !== imageUrl
      );
      const updatedImageData = { ...(res.data?.adImageData || {}) };
      delete updatedImageData[sanitizeKey(imageUrl)];

      await CrudService.update("AdCampaign", id, {
        adImages: updatedImages,
        adImageData: updatedImageData,
      });

      message.success(t("Image deleted successfully"));
    } catch (error) {
      console.error("Error deleting image:", error);
      message.error(t("Failed to delete image"));
    }
  };

  const handleImageSelect = async (imageUrl) => {
    setSelectedImage(imageUrl);
    setGeneratedCreatives([]);
  };

  const handleFileUpload = async ({ file }) => {
    try {
      const result = await UploadService.upload(file);
      const imageUrl = result.data.secure_url;
      handleImageSelect(imageUrl);

      // Save the uploaded image to the campaign
      await addImageToCampaignImages(imageUrl);
    } catch (error) {
      message.error(t("Failed to upload image"));
      console.error("Upload error:", error);
    }
  };

  const generateCreatives = async () => {
    if (!selectedImage) {
      message.error(t("Please select an image first"));
      return;
    }

    setIsGenerating(true);
    try {
      const templates = adTemplates;
      const creatives = await Promise.all(
        templates.map(async (template) => {
          try {
            const res = await PublicService.aiPrompt({
              content: `context: ${JSON.stringify(
                funnel?.data?.pageContent || {}
              )?.slice?.(0, 4000)}
              
              Please generate a short ad banner headline and description for my context. The language should be same as the language used in the context. The headline should not be more than 2-3 words. The headline should make sense and be good marketing content. Your response to me should not have anything else than the generated headline and description.

              Your answer must be parseable with javascript JSON.parse method and must be in the exact following JSON format. In your answer do not include anything other than the required JSON.
              {
                "headline": String,
                "description": String
              }
              `,
            });

            // Clean the response to ensure it's valid JSON
            const cleanedOutput = res.data?.output
              ?.replace?.(/```json/g, "")
              ?.replace?.(/```/g, "")
              ?.trim();

            const result = JSON.parse(cleanedOutput);

            return {
              template,
              image: selectedImage,
              text: {
                headline: result?.headline || "Powerful Headline",
                description:
                  result?.description ||
                  "Compelling description that drives action",
                color: "#ffffff",
              },
            };
          } catch (error) {
            console.error("Error generating creative:", error);
            // Provide fallback content if AI generation fails
            return {
              template,
              image: selectedImage,
              text: {
                headline: "Powerful Headline",
                description: "Compelling description that drives action",
                color: "#ffffff",
              },
            };
          }
        })
      );
      setGeneratedCreatives(creatives);

      // Save the selected image to the database if it's not already there
      if (!generatedImages.includes(selectedImage)) {
        await addImageToCampaignImages(selectedImage);
      }
    } catch (error) {
      console.error("Error in creative generation:", error);
      message.error(t("Failed to generate creatives"));
    } finally {
      setIsGenerating(false);
    }
  };

  // Generate a new image using AI
  const generateNewImage = async () => {
    if (generatedImages.length >= 5) {
      return message.error(t("Maximum 5 creatives are allowed"));
    }

    setIsGenerating(true);
    try {
      const response = await PublicService.replicateGen({
        input: {
          prompt: `${JSON.stringify(funnel?.data?.pageContent || {}).slice(
            0,
            1000
          )}

For this landing page, we need a picture that describes that serves as an ad creative. Can you generate it?

The generated ad creative must not have any text or letters
`,
        },
        id: "bytedance/sdxl-lightning-4step:5f24084160c9089501c1b3545d9be3c27883ae2239b6f412990e82d4a6210f8f",
      });

      const generatedImage = response?.data?.output?.[0];

      if (generatedImage) {
        // Fetch the image from the replicate URL
        const imageResponse = await fetch(generatedImage);
        const blob = await imageResponse.blob();

        // Create a temporary file to upload to Cloudinary
        const file = new File([blob], `replicate_image_${Date.now()}.png`, {
          type: blob.type,
        });

        // Upload the image to Cloudinary
        const result = await UploadService.upload(file);
        const imageUrl = result?.data?.secure_url;

        // Set as selected image and save to campaign
        setSelectedImage(imageUrl);
        await addImageToCampaignImages(imageUrl);
        message.success(t("New image generated successfully"));
      }
    } catch (error) {
      console.error("Error generating image:", error);
      message.error(t("Failed to generate image"));
    } finally {
      setIsGenerating(false);
    }
  };

  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);

  const handleFinish = useCallback(async () => {
    if (loading) return;

    const id = searchParams.get("id");
    const res = await CrudService.getSingle("AdCampaign", id);
    const data = res.data;

    let finalAdImages = data.adImages;

    console.log(finalAdImages);
    if (finalAdImages.length < 1)
      return message.error(t("At least 1 image is required"));

    try {
      await UserService.extendTokenTime();
    } catch (e) {
      await AuthService.updateMe({ silent: true, metaAccessToken: "" });

      const me = await AuthService.me();
      store.dispatch(login(me.data.me));
      navigate("/dashboard/createAd?defaultStep=2");
    }

    const funnel = await CrudService.getSingle("Vacancy", data.vacancy);
    if (funnel.data?.length < 1)
      return message.error(t("At least 1 image is required"));

    if (user?.metaAccessToken) {
      await UserService.postAd({
        adImages: finalAdImages,
        adBudget: data?.monthlyBudget / 30,
        promote: funnel.data,
        adHeadline: data?.adHeadline,
        adDescription: data?.adDescription,
        adPrimaryText: data?.adPrimaryText,
      }).then(async (res) => {
        await CrudService.update("AdCampaign", data._id, {
          campaign: res.data?.campaign?.id,
        });
      });
    }

    if (user?.connectGoogle) {
      await GoogleService.createAd({
        days: 30,
        adDescription: [data?.adDescription],
        adHeadline: [data?.adHeadline],
        vacancyLocation:
          countries.find((c) => c.value === user.country)?.label ??
          "United States",
        path1: "thesalesai",
        path2: "page",
        final_urls: [`https://thesalesai.ai/page/${funnel?.data?._id}`],
        budget: data?.monthlyBudget / 30,
      }).then(async (res) => {
        await CrudService.update("AdCampaign", data._id, {
          campaignGoogle: res.data?.myCampaignId,
          googleCustomerId: res.data?.customerId,
        });
      });
    }

    await CrudService.update("AdCampaign", data._id, {
      enabled: true,
    });

    navigate("/dashboard/adautomation");
  }, [loading, navigate, searchParams, user, t]);

  return (
    <div className="p-4">
      <Card title={t("Ad Creative Generator")} className="mb-4">
        <div className="flex items-center space-x-4 mb-4">
          <Upload
            accept="image/*"
            showUploadList={false}
            customRequest={handleFileUpload}
          >
            <Button icon={<UploadOutlined />}>{t("Upload Image")}</Button>
          </Upload>
          <Button
            icon={<PictureOutlined />}
            onClick={() => setIsStockPickerVisible(true)}
          >
            {t("Choose Stock Image")}
          </Button>
          <Button
            onClick={generateNewImage}
            loading={isGenerating && !selectedImage}
            disabled={generatedImages.length >= 5}
          >
            {t("Generate New Image")}
          </Button>
        </div>
        {selectedImage && (
          <div className="mb-4">
            <img
              src={selectedImage || "/placeholder.svg"}
              alt="Selected"
              className="w-auto max-h-[50vh] rounded-lg shadow-md"
              style={{ maxWidth: "100%" }}
            />
          </div>
        )}
        <Button
          type={!selectedImage || isGenerating ? undefined : "primary"}
          onClick={generateCreatives}
          disabled={!selectedImage || isGenerating}
          loading={isGenerating && selectedImage}
        >
          {isGenerating ? t("Generating...") : t("Generate Creatives")}
        </Button>
      </Card>

      {isGenerating && (
        <div className="text-center my-8">
          <Spin size="large" />
          <p className="mt-2">{t("Generating creative designs...")}</p>
        </div>
      )}

      {generatedCreatives.length > 0 && (
        <div className="mb-4">
          <h2 className="text-xl font-bold mb-4">{t("Choose a Template")}</h2>
          <CreativeTemplates
            creatives={generatedCreatives}
            onSelect={(creative) => setSelectedCreative(creative)}
          />
        </div>
      )}

      {generatedImages.length > 0 && (
        <div className="mb-4">
          <h2 className="text-xl font-bold mb-4">{t("Saved Creatives")}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {generatedImages.map((imageUrl) => {
              const savedData = generatedImageData[sanitizeKey(imageUrl)];
              let parsedData = null;
              try {
                parsedData = savedData ? JSON.parse(savedData) : null;
              } catch (e) {
                console.error("Error parsing saved data:", e);
              }

              // Use the rendered image URL if available, otherwise use the original
              const displayUrl = parsedData?.renderedImageUrl || imageUrl;

              return (
                <Card
                  key={imageUrl}
                  hoverable
                  cover={
                    <div className="relative">
                      <div
                        style={{
                          position: "relative",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={displayUrl || "/placeholder.svg"}
                          alt="Saved Creative"
                          className="w-full h-full object-cover"
                        />
                        {parsedData && parsedData?.template && (
                          <>
                            {parsedData?.template?.defaultShapes?.map?.(
                              (shape, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "absolute",
                                    left: `${(shape.left / 600) * 100}%`,
                                    top: `${(shape.top / 400) * 100}%`,
                                    width: `${(shape.width / 600) * 100}%`,
                                    height: `${(shape.height / 400) * 100}%`,
                                    background: shape.fill,
                                    opacity: shape.opacity,
                                  }}
                                />
                              )
                            )}
                            <div
                              style={{
                                position: "absolute",
                                left: `${
                                  (parsedData.template?.textPlacement?.headline
                                    .left /
                                    600) *
                                  100
                                }%`,
                                top: `${
                                  (parsedData.template?.textPlacement?.headline
                                    .top /
                                    400) *
                                  100
                                }%`,
                                width: `${
                                  (parsedData.template?.textPlacement?.headline
                                    .width /
                                    600) *
                                  100
                                }%`,
                                color: parsedData.text.color,
                                fontSize: `${parsedData.template?.textPlacement?.headline?.fontSize}px`,
                                fontWeight: "bold",
                                textAlign:
                                  parsedData.template?.textPlacement?.headline
                                    .textAlign || "center",
                                textShadow: "0px 1px 2px rgba(0,0,0,0.5)",
                              }}
                            >
                              {parsedData.text.headline}
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                left: `${
                                  (parsedData.template?.textPlacement
                                    ?.description.left /
                                    600) *
                                  100
                                }%`,
                                top: `${
                                  (parsedData.template?.textPlacement
                                    ?.description.top /
                                    400) *
                                  100
                                }%`,
                                width: `${
                                  (parsedData.template?.textPlacement
                                    ?.description.width /
                                    600) *
                                  100
                                }%`,
                                color: parsedData.text.color,
                                fontSize: `${parsedData.template?.textPlacement?.description?.fontSize}px`,
                                textAlign:
                                  parsedData.template?.textPlacement
                                    ?.description.textAlign || "center",
                                textShadow: "0px 1px 2px rgba(0,0,0,0.5)",
                              }}
                            >
                              {parsedData.text.description}
                            </div>
                          </>
                        )}
                      </div>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        className="absolute top-2 right-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          Modal.confirm({
                            title: t("Delete Creative"),
                            content: t(
                              "Are you sure you want to delete this creative?"
                            ),
                            onOk: () => deleteImage(imageUrl),
                          });
                        }}
                      />
                    </div>
                  }
                  onClick={() => {
                    if (parsedData) {
                      // Always use the original image URL for editing, not the rendered one
                      const originalImageUrl =
                        parsedData.originalImageUrl || imageUrl;
                      setSelectedCreative({
                        ...parsedData,
                        image: originalImageUrl, // Use original image for editing
                      });
                    } else {
                      setSelectedImage(imageUrl);
                      const defaultCreative = {
                        template: adTemplates[0],
                        image: imageUrl,
                        text: {
                          headline: "Powerful Headline",
                          description:
                            "Compelling description that drives action",
                          color: "#ffffff",
                        },
                      };
                      setSelectedCreative(defaultCreative);
                    }
                  }}
                >
                  <Card.Meta
                    title={parsedData?.text?.headline || t("Click to edit")}
                    description={
                      parsedData?.lastModified
                        ? `${t("Last modified")}: ${new Date(
                            parsedData.lastModified
                          ).toLocaleDateString()}`
                        : t("Click to edit")
                    }
                  />
                </Card>
              );
            })}
          </div>
        </div>
      )}

      <Popconfirm
        title={t("Are you sure you want to publish the campaign?")}
        onConfirm={handleFinish}
        okText={t("Yes")}
        cancelText={t("No")}
      >
        <Button type="primary" className="mt-4">
          {t("Publish Campaign")}
        </Button>
      </Popconfirm>

      <StockImagePicker
        visible={isStockPickerVisible}
        onClose={() => setIsStockPickerVisible(false)}
        addImageToCampaignImages={handleImageSelect}
      />

      <Modal
        open={!!selectedCreative}
        onCancel={() => setSelectedCreative(null)}
        footer={null}
        width={800}
        destroyOnClose
      >
        {selectedCreative && (
          <CreativeEditor
            creative={selectedCreative}
            saveCreativeData={saveCreativeData}
            onSave={(editedCreative) => {
              // Update the local state
              setGeneratedCreatives((creatives) => {
                const newCreatives = [...creatives];
                const edited = newCreatives.find(
                  (c) => c?.template?.id === editedCreative?.template?.id
                );
                if (edited) {
                  edited.text = editedCreative.text;
                  edited.template = editedCreative.template;
                }
                return newCreatives;
              });

              // Close the modal
              setSelectedCreative(null);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default AdCreativeGenerator;
