import {
  CloudUploadOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  AimOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Upload,
  message,
  Space,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CrudService from "../../../service/CrudService";
import UploadService from "../../../service/UploadService";
import { HiInformationCircle } from "react-icons/hi2";
import WebinarEmail from "./WebinarEmail";

const { TextArea } = Input;

export default function Webinar({ vacancyId }) {
  const { t } = useTranslation();
  const [webinars, setWebinars] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingWebinar, setEditingWebinar] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [video, setVideo] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [webinarMails, setWebinarMails] = useState([]);

  const fetchWebinars = useCallback(async () => {
    try {
      const response = await CrudService.search("Webinar", 100, 1, {
        filters: { vacancyId },
        populate: "participants",
      });
      setWebinars(response.data.items);
    } catch (error) {
      message.error(t("Failed to fetch webinars"));
    }
  }, [vacancyId]);

  const fetchParticipants = useCallback(async () => {
    try {
      const response = await CrudService.search("VacancySubmission", 10000, 1, {
        filters: { VacancyId: vacancyId },
      });
      setParticipants(response.data.items);
    } catch (error) {
      message.error(t("Failed to fetch participants"));
    }
  }, [vacancyId]);

  useEffect(() => {
    fetchWebinars();
  }, [fetchWebinars]);
  useEffect(() => {
    fetchParticipants();
  }, [fetchParticipants, modalVisible]);

  const handleSubmit = useCallback(
    async (values) => {
      if (!values.videoUrl && !editingWebinar)
        return message.info(t("Video is required"));
      setLoading(true);
      try {
        if (editingWebinar) {
          await CrudService.update("Webinar", editingWebinar._id, {
            ...values,
            timeSlots: timeSlots ?? [],
            webinarMails: webinarMails ?? [],
            videoUrl: video || editingWebinar.videoUrl,
            scheduledDate: values.scheduledDate.toISOString(),
          });

          message.success(t("Webinar updated successfully"));
        } else {
          await CrudService.create("Webinar", {
            ...values,
            timeSlots: timeSlots ?? [],
            webinarMails: webinarMails ?? [],
            videoUrl: video,
            vacancyId,
            scheduledDate: values.scheduledDate.toISOString(),
          });
          message.success(t("Webinar created successfully"));
        }
        setModalVisible(false);
        setEditingWebinar(null);
        setTimeSlots([]);
        setWebinarMails([]);
        form.resetFields();
        fetchWebinars();
      } catch (error) {
        message.error(
          t(
            editingWebinar
              ? "Failed to update webinar"
              : "Failed to create webinar"
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [video, form, editingWebinar, timeSlots, webinarMails]
  );

  const handleEdit = (record) => {
    setEditingWebinar(record);
    setTimeSlots(record?.timeSlots ?? []);
    setWebinarMails(record?.webinarMails ?? []);
    form.setFieldsValue({
      ...record,
      scheduledDate: dayjs(record.scheduledDate),
      participants: record.participants.map((p) => p?._id),
    });
    setVideo(record.videoUrl);
    setModalVisible(true);
  };

  const columns = [
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("Date"),
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (date) => dayjs(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("Participants"),
      dataIndex: "participants",
      key: "participants",
      render: (participants) => participants?.length || 0,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const now = dayjs();
        const scheduled = dayjs(record.scheduledDate);
        if (now.isAfter(scheduled)) return t("Completed");
        return t("Upcoming");
      },
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/webinar/${record._id}`
              );
              message.success(t("Webinar link copied to clipboard"));
            }}
          >
            {t("Copy Link")}
          </Button>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            {t("Edit")}
          </Button>
        </>
      ),
    },
  ];

  const addTimeSlot = () => {
    setTimeSlots([
      ...timeSlots,
      {
        day: "Sunday",
        startHour: "9",
        startMinute: "55",
      },
    ]);
  };

  const removeTimeSlot = (index) => {
    setTimeSlots(timeSlots.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">{t("Webinars")}</h2>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingWebinar(null);
            setTimeSlots([]);
            form.resetFields();
            setVideo("");
            setModalVisible(true);
          }}
        >
          {t("Create Webinar")}
        </Button>
      </div>

      <Table dataSource={webinars} columns={columns} rowKey="_id" />

      <Modal
        title={editingWebinar ? t("Edit Webinar") : t("Create Webinar")}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingWebinar(null);
          setTimeSlots([]);
          setWebinarMails([]);
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="title"
            label={t("Webinar Title")}
            rules={[
              { required: true, message: t("Please input webinar title") },
            ]}
          >
            <Input className="dark:bg-gray-900" />
          </Form.Item>
          <Form.Item
            name="description"
            label={t("Description")}
            rules={[{ required: true, message: t("Please input description") }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="scheduledDate"
            label={t("Scheduled Date")}
            rules={[{ required: true, message: t("Please select date") }]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>
          <Form.Item
            name="participants"
            label={t("Select Participants")}
            rules={[
              { required: true, message: t("Please select participants") },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t("Select participants")}
              options={participants.map((p) => ({
                label: p.formData?.email || p._id,
                value: p._id,
              }))}
            />
          </Form.Item>
          <Form.Item label={t("Upload Webinar Video")}>
            <Upload.Dragger
              name="file"
              multiple={false}
              accept="video/*"
              beforeUpload={async (file) => {
                setUploading(true);
                try {
                  const res = await UploadService.upload(file);
                  const secureUrl = res.data.secure_url;
                  setVideo(secureUrl);
                  message.success(t("Video uploaded successfully"));
                } catch (error) {
                  console.error("File upload failed:", error);
                  message.error(t("Video upload failed. Please try again."));
                } finally {
                  setUploading(false);
                }
                return false;
              }}
              disabled={uploading}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">
                {uploading
                  ? t("Uploading...")
                  : t("Click or drag video to upload")}
              </p>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item label={t("Date")} className="mb-6">
            <Space>
              <Form.Item name={["dateRange", "start"]} noStyle>
                <Input
                  className="dark:bg-gray-900"
                  type="number"
                  style={{ width: 120 }}
                />
              </Form.Item>
              <span>{t("Days later")}</span>
              <Tooltip
                title={t(
                  "All leads joining this funnel will become participants of this webinar automatically after this many days. You can also manually make them participants above."
                )}
              >
                <HiInformationCircle />
              </Tooltip>
            </Space>
          </Form.Item>

          <div className="mb-6 max-w-full">
            <div className="mb-2 font-medium ">{t("Time Slots")}</div>

            <div className="max-w-full overflow-auto">
              {timeSlots.map((_, index) => (
                <Form.Item key={index} className="mb-4 ">
                  <Space align="baseline">
                    <Form.Item name={["timeSlots", index, "day"]} noStyle>
                      <Select
                        style={{ width: 120 }}
                        value={_?.day}
                        onChange={(e) => {
                          setTimeSlots((x) => {
                            const slots = [...x];
                            slots[index].day = e;
                            return slots;
                          });
                        }}
                      >
                        {[
                          { value: "Sunday", label: t("Sunday") },
                          { value: "Monday", label: t("Monday") },
                          { value: "Tuesday", label: t("Tuesday") },
                          { value: "Wednesday", label: t("Wednesday") },
                          { value: "Thursday", label: t("Thursday") },
                          { value: "Friday", label: t("Friday") },
                          { value: "Saturday", label: t("Saturday") },
                        ].map((day) => (
                          <Option key={day.value} value={day.value}>
                            {day.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={["timeSlots", index, "startHour"]} noStyle>
                      <Select
                        style={{ width: 80 }}
                        value={_?.startHour}
                        onChange={(e) => {
                          setTimeSlots((x) => {
                            const slots = [...x];
                            slots[index].startHour = e;
                            return slots;
                          });
                        }}
                      >
                        {Array.from({ length: 24 }, (_, i) => (
                          <Option key={i} value={i.toString()}>
                            {i}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <span>:</span>
                    <Form.Item
                      name={["timeSlots", index, "startMinute"]}
                      noStyle
                    >
                      <Select
                        style={{ width: 80 }}
                        value={_?.startMinute}
                        onChange={(e) => {
                          setTimeSlots((x) => {
                            const slots = [...x];
                            slots[index].startMinute = e;
                            return slots;
                          });
                        }}
                      >
                        {["00", "15", "30", "45", "55"].map((min) => (
                          <Option key={min} value={min}>
                            {min}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => removeTimeSlot(index)}
                    />
                  </Space>
                </Form.Item>
              ))}
            </div>

            <Button
              type="dashed"
              onClick={addTimeSlot}
              block
              icon={<PlusOutlined />}
            >
              {t("Add Time Slot")}
            </Button>
          </div>

          <div className="mb-6 max-w-full">
            <div className="mb-2 font-medium ">{t("Email Automations")}</div>
            <WebinarEmail
              value={webinarMails}
              onChange={(e) => setWebinarMails(e)}
            />
          </div>

          <Form.Item
            label={t("Chat feature")}
            name="chatFeature"
            className="mb-6"
          >
            <Select style={{ width: 200 }}>
              <Option value="Use">{t("Use")}</Option>
              <Option value="Don't Use">{t("Don't Use")}</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {editingWebinar ? t("Update Webinar") : t("Create Webinar")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
