"use client";

import { useState, useRef } from "react";
import { Button, Input, Slider, Space, Typography, message } from "antd";
import { useTranslation } from "react-i18next";
import UploadService from "../../../../service/UploadService";
import domtoimage from "dom-to-image";

const { Title } = Typography;

const CreativeEditor = ({ creative, onSave, saveCreativeData }) => {
  const { t } = useTranslation();
  const previewRef = useRef(null);
  const [headline, setHeadline] = useState(creative.text.headline);
  const [description, setDescription] = useState(creative.text.description);
  const [textColor, setTextColor] = useState(creative.text?.color || "#ffffff");
  const [fontSize, setFontSize] = useState(
    creative.template.textPlacement.headline.fontSize
  );
  const [descFontSize, setDescFontSize] = useState(
    creative.template.textPlacement.description.fontSize
  );

  // Helper function to get color in the right format
  const getColor = (colorObject) => {
    if (!colorObject) return "#ffffff";

    if (typeof colorObject === "string") {
      return colorObject;
    }

    if (colorObject.toHexString) {
      return colorObject.toHexString();
    }

    // Default fallback
    return "#ffffff";
  };

  // Add function to convert div to image
  const convertDivToImage = async (div) => {
    try {
      // Use dom-to-image to capture the div as a blob
      const blob = await domtoimage.toBlob(div, {
        quality: 0.95,
        bgcolor: "white",
        cacheBust: true,
      });

      return blob;
    } catch (error) {
      console.error("Error converting div to image:", error);
      throw error;
    }
  };

  // Update the handleSave function to use the simplified approach
  const handleSave = async () => {
    try {
      // Create the edited creative object
      const editedCreative = {
        ...creative,
        text: {
          headline,
          description,
          color: textColor,
        },
        template: {
          ...creative.template,
          textPlacement: {
            ...creative.template.textPlacement,
            headline: {
              ...creative.template.textPlacement.headline,
              fontSize: fontSize,
            },
            description: {
              ...creative.template.textPlacement.description,
              fontSize: descFontSize,
            },
          },
        },
      };

      // Convert the preview div to an image
      const previewDiv = previewRef.current;
      let blob;

      try {
        // Try using dom-to-image first
        blob = await convertDivToImage(previewDiv);
      } catch (error) {
        console.error("Primary image conversion failed:", error);
        message.warning(t("Using fallback image conversion method"));

        // Fallback: Use a simpler approach - render just the text content
        // This is a simplified version that might not capture all styling
        const canvas = document.createElement("canvas");
        canvas.width = 600;
        canvas.height = 400;
        const ctx = canvas.getContext("2d");

        // Draw background (use the original image)
        const img = new Image();
        img.crossOrigin = "anonymous";
        await new Promise((resolve) => {
          img.onload = resolve;
          img.src = creative.image;
        });

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Add semi-transparent overlay
        ctx.fillStyle = "rgba(0,0,0,0.5)";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Add headline
        ctx.fillStyle = getColor(textColor);
        ctx.font = `bold ${fontSize}px Arial`;
        ctx.textAlign = "center";
        ctx.fillText(headline, canvas.width / 2, 150);

        // Add description
        ctx.font = `${descFontSize}px Arial`;
        ctx.fillText(description, canvas.width / 2, 250);

        // Convert to blob
        blob = await new Promise((resolve) =>
          canvas.toBlob(resolve, "image/png")
        );
      }

      // Create a file from the blob
      const file = new File([blob], `creative_${Date.now()}.png`, {
        type: "image/png",
      });

      // Upload the rendered image to Cloudinary
      const uploadResult = await UploadService.upload(file);
      const renderedImageUrl = uploadResult.data.secure_url;

      // Save both the rendered image URL and the creative data
      // We're now using a simplified approach that doesn't require tracking current rendered URL
      await saveCreativeData(creative.image, editedCreative, renderedImageUrl);

      onSave(editedCreative);
    } catch (error) {
      console.error("Error saving creative:", error);
      message.error(t("Failed tosave creative"));
    }
  };

  return (
    <div className="p-4">
      <Title level={4} className="mb-4">
        {t("Edit Creative")}
      </Title>

      <div className="mb-4 border rounded-lg overflow-hidden">
        <div
          ref={previewRef}
          style={{
            position: "relative",
            width: "100%",
            height: "300px",
            overflow: "hidden",
          }}
        >
          <img
            src={creative.image || "/placeholder.svg"}
            alt="Creative Preview"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: "block",
            }}
            crossOrigin="anonymous"
          />

          {creative?.template?.defaultShapes?.map?.((shape, index) => (
            <div
              key={index}
              style={{
                position: "absolute",
                left: `${(shape.left / 600) * 100}%`,
                top: `${(shape.top / 400) * 100}%`,
                width: `${(shape.width / 600) * 100}%`,
                height: `${(shape.height / 400) * 100}%`,
                background: shape.fill,
                opacity: shape.opacity,
              }}
            />
          ))}

          <div
            style={{
              position: "absolute",
              left: `${
                (creative.template.textPlacement.headline.left / 600) * 100
              }%`,
              top: `${
                (creative.template.textPlacement.headline.top / 400) * 100
              }%`,
              width: `${
                (creative.template.textPlacement.headline.width / 600) * 100
              }%`,
              color: getColor(textColor),
              fontSize: `${fontSize}px`,
              fontWeight: "bold",
              textAlign:
                creative.template.textPlacement.headline.textAlign || "center",
              textShadow: "0px 1px 2px rgba(0,0,0,0.5)",
              lineHeight: 1.2,
            }}
          >
            {headline}
          </div>

          <div
            style={{
              position: "absolute",
              left: `${
                (creative.template.textPlacement.description.left / 600) * 100
              }%`,
              top: `${
                (creative.template.textPlacement.description.top / 400) * 100
              }%`,
              width: `${
                (creative.template.textPlacement.description.width / 600) * 100
              }%`,
              color: getColor(textColor),
              fontSize: `${descFontSize}px`,
              textAlign:
                creative.template.textPlacement.description.textAlign ||
                "center",
              textShadow: "0px 1px 2px rgba(0,0,0,0.5)",
              lineHeight: 1.3,
            }}
          >
            {description}
          </div>
        </div>
      </div>

      <Space direction="vertical" className="w-full">
        <div>
          <Typography.Text strong>{t("Headline")}</Typography.Text>
          <Input
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
            placeholder={t("Enter headline")}
            className="mb-2"
          />
        </div>

        <div>
          <Typography.Text strong>{t("Description")}</Typography.Text>
          <Input.TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t("Enter description")}
            className="mb-4"
            rows={3}
          />
        </div>

        <div>
          <Typography.Text strong>{t("Headline Font Size")}</Typography.Text>
          <Slider
            min={16}
            max={72}
            value={fontSize}
            onChange={setFontSize}
            className="mb-4"
          />
        </div>

        <div>
          <Typography.Text strong>{t("Description Font Size")}</Typography.Text>
          <Slider
            min={12}
            max={36}
            value={descFontSize}
            onChange={setDescFontSize}
            className="mb-4"
          />
        </div>

        <Button type="primary" onClick={handleSave} size="large">
          {t("Save Creative")}
        </Button>
      </Space>
    </div>
  );
};

export default CreativeEditor;
