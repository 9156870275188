import { useState, useCallback } from "react";
import { Button, Card, Form, Input, Space, Tooltip } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { HiInformationCircle } from "react-icons/hi2";
import { MentionsInput, Mention } from "react-mentions";

import classNamesBody from "../../Dashboard/Message/body.module.css";
import classNamesSubject from "../../Dashboard/Message/example.module.css";

const { TextArea } = Input;

const mentionStyle = {
  backgroundColor: "#e6f7ff",
  padding: "2px 4px",
  borderRadius: "2px",
};

export default function WebinarEmail({ value, onChange }) {
  const { t } = useTranslation();
  const [automations, setAutomations] = useState(value || []);

  const addAutomation = useCallback(() => {
    const newAutomations = [
      ...automations,
      {
        id: Date.now().toString(),
        title: "",
        body: "",
        hoursBeforeWebinar: 2,
      },
    ];
    setAutomations(newAutomations);
    onChange?.(newAutomations);
  }, [automations, onChange]);

  const removeAutomation = useCallback(
    (id) => {
      const newAutomations = automations.filter(
        (automation) => automation.id !== id
      );
      setAutomations(newAutomations);
      onChange?.(newAutomations);
    },
    [automations, onChange]
  );

  const updateAutomation = useCallback(
    (id, field, value) => {
      const newAutomations = automations.map((automation) =>
        automation.id === id ? { ...automation, [field]: value } : automation
      );
      setAutomations(newAutomations);
      onChange?.(newAutomations);
    },
    [automations, onChange]
  );

  const variables = [
    { id: "candidateFirstname", display: "Lead's Firstname" },
    { id: "candidateLastname", display: "Lead's Lastname" },
    { id: "candidateEmail", display: "Lead's Email" },
    { id: "webinarTitle", display: "Webinar Title" },
    { id: "webinarLink", display: "Webinar Link" },
    { id: "webinarDate", display: "Webinar Date" },
    { id: "webinarTime", display: "Webinar Time" },
  ];

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <Space>
          <span className="font-medium">{t("Email Automations")}</span>
          <Tooltip
            title={t(
              "Configure automated emails to be sent before webinar sessions. You can use variables like @[Lead's Firstname](candidateFirstname), @[Webinar Title](webinarTitle), @[Webinar Link](webinarLink), etc."
            )}
          >
            <HiInformationCircle className="text-gray-400" />
          </Tooltip>
        </Space>
        <Button type="dashed" icon={<PlusOutlined />} onClick={addAutomation}>
          {t("Add Email Automation")}
        </Button>
      </div>

      {automations.map((automation) => (
        <Card
          key={automation.id}
          className="mb-4"
          extra={
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => removeAutomation(automation.id)}
              danger
            />
          }
        >
          <Form layout="vertical">
            <Form.Item label={t("Email Subject")}>
              <MentionsInput
                value={automation.title}
                onChange={(e, newValue) =>
                  updateAutomation(automation.id, "title", newValue)
                }
                placeholder={t(
                  'e.g. "Your upcoming webinar: @[Webinar Title](webinarTitle)"'
                )}
                classNames={classNamesSubject}
              >
                <Mention
                  trigger="#"
                  data={variables}
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay
                  ) => (
                    <div className="ant-select-item ant-select-item-option">
                      {highlightedDisplay}
                    </div>
                  )}
                  style={mentionStyle}
                />
              </MentionsInput>
            </Form.Item>

            <Form.Item label={t("Email Body")}>
              <MentionsInput
                value={automation.body}
                onChange={(e, newValue) =>
                  updateAutomation(automation.id, "body", newValue)
                }
                placeholder={t(
                  "Dear @[Lead's Firstname](candidateFirstname),\n\nThis is a reminder about your upcoming webinar..."
                )}
                classNames={classNamesBody}
                component={TextArea}
                rows={4}
              >
                <Mention
                  trigger="#"
                  data={variables}
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay
                  ) => (
                    <div className="ant-select-item ant-select-item-option">
                      {highlightedDisplay}
                    </div>
                  )}
                  style={mentionStyle}
                />
              </MentionsInput>
            </Form.Item>

            <Space align="start">
              <Form.Item label={t("Hours Before Webinar")}>
                <Input
                  type="number"
                  min={1}
                  max={72}
                  value={automation.hoursBeforeWebinar}
                  onChange={(e) =>
                    updateAutomation(
                      automation.id,
                      "hoursBeforeWebinar",
                      Number.parseInt(e.target.value)
                    )
                  }
                />
              </Form.Item>

              <Form.Item
                label={t("Sending Behavior")}
                tooltip={t(
                  "This email will be sent repeatedly at the specified time before each webinar session until the participant watches the webinar"
                )}
              ></Form.Item>
            </Space>
          </Form>
        </Card>
      ))}

      {automations.length === 0 && (
        <div className="text-center text-gray-500 py-8">
          {t(
            'No email automations configured yet. Click "Add Email Automation" to create one.'
          )}
        </div>
      )}
    </div>
  );
}
