import { CheckCircleIcon } from "@heroicons/react/20/solid";
import {
  Badge,
  Button,
  message,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Typography,
} from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-alice-carousel";
import { useTranslation } from "react-i18next";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";
import { ImStatsDots } from "react-icons/im";
import { IoIosStats, IoMdMegaphone } from "react-icons/io";
import { IoMegaphone } from "react-icons/io5";
import { MdDelete, MdEdit, MdFileCopy, MdPreview } from "react-icons/md";
import { PiKanbanFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { brandColor, STANDARD_MOMENT_FORMAT } from "../../../data/constants";
import {
  getPartner,
  selectDarkMode,
  selectUser,
} from "../../../redux/auth/selectors";
import ATSService from "../../../service/ATSService";
import CrudService from "../../../service/CrudService";
import PublicService from "../../../service/PublicService";

const tiers = [
  {
    name: "Hire Fast",
    id: "fast",
    price: 45,
    description: "Promote for a day",
    days: 1,
    features: ["10-100 leads", "Live for 1 days", "Fill the funnel fast"],
  },
  {
    name: "Hire Better",
    id: "better",
    price: 295,
    description: "Promote for a week",
    days: 7,
    features: [
      "100-1000 leads",
      "Live for 7 days",
      "Find a great fit for the position",
    ],
  },
  {
    name: "Hire Best",
    id: "best",
    price: 595,
    description: "Promote for 2 weeks",
    days: 14,
    features: [
      "600-2000 leads",
      "Live for 14 days",
      "Find the best fit for the position",
    ],
  },
];

function truncateString(str, num) {
  if (!str) return "";
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

function removeDuplicateObjects(arr) {
  const uniqueObjects = {};
  const result = [];

  for (const obj of arr) {
    const id = obj._id;

    if (!uniqueObjects[id]) {
      uniqueObjects[id] = true;
      result.push(obj);
    }
  }

  return result;
}

const PAGE_LIMIT = 9;

export default function OutboundCalls() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const partner = useSelector(getPartner);
  const darkMode = useSelector(selectDarkMode);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancies, setVacancies] = useState([]);
  const [singleVacancy, setSingleVacancy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, contextHolder] = Modal.useModal();
  const [promoteFunnel, setPromoteFunnel] = useState(null);
  const [total, setTotal] = useState(0);
  const [activePromotions, setActivePromotions] = useState([]);

  const loadMoreVacancies = useCallback(
    async (filters = {}, text = "", alternativePage) => {
      if (!user) return;

      setLoading(true);
      try {
        const data = {
          filters: { ...filters, user_id: user._id, isSecondary: false },
          sort: { createdAt: -1 },
        };
        if (text) data.text = text;
        const response = await CrudService.search(
          "Vacancy",
          PAGE_LIMIT,
          alternativePage ?? page,
          data
        );
        const newVacancies = (
          await ATSService.countApplicants(
            response.data.items.map((item) => ({ _id: item._id }))
          )
        ).data;
        setVacancies((prevVacancies) => [
          ...prevVacancies,
          ...newVacancies.map((v) => ({
            ...v,
            ...response.data.items.find((a) => a._id === v._id),
          })),
        ]);
        setPage((prevPage) => prevPage + 1);
        setTotal(response.data.total);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [user,page]
  );

  useEffect(() => {
    ATSService.getActivePromotions().then(({ data }) => {
      setActivePromotions(data.activePromotions);
    });
  }, []);



  useEffect(() => {
    const getSingle = async () => {
      const id = searchParams.get("id");
      const isNew = searchParams.get("new");
      if (!id) return;

      const searchedVacancy = await CrudService.getSingle("Vacancy", id);
      setSingleVacancy({ ...searchedVacancy.data, isNew: isNew === "true" });
    };
    getSingle();
  }, [searchParams]);

  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setPage(1);
      setVacancies([]);

      const query = {};

      loadMoreVacancies(query, newValue, 1);
      setSingleVacancy(null);
    }, 1000);
  };

  useEffect(() => {
    loadMoreVacancies();
  }, []);

  const handleSecondConfirm = async (vacancyId, deleteCandidates) => {
    await ATSService.deleteFunnel(vacancyId, deleteCandidates);
    setSingleVacancy(null);
    setVacancies((c) => c.filter((e) => e._id !== vacancyId));
  };

  const handleFirstConfirm = (vacancyId) => {
    modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Please confirm the deletion of this funnel."),
      okText: t("DELETE"),
      cancelText: t("Cancel"),
      closable: true,
      onOk: async () => {
        handleSecondConfirm(vacancyId, false);
      },
    });
  };

  return (
    <>
      {contextHolder}

      <div className="container mx-auto p-4" id="vacancyContainer">
        <div className="relative mt-2 flex items-center">
          <input
            type="text"
            placeholder={t("Search Programs")}
            className="block w-full rounded-md border-0 py-1.5 pr-14  dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900  dark:bg-gray-900"
            value={searchTerm}
            onChange={handleInputChange}
            autoComplete="new-password"
          />

          <Button
            type="primary"
            loading={loading}
            onClick={async () => {
              if (loading) return;
              setLoading(true);
              try {
                const res = await ATSService.createVacancy({
                  vacancyTemplate: {
                    name: moment().format(STANDARD_MOMENT_FORMAT),
                  },
                });
                if (res.data?.vacancy?._id)
                  navigate(`/dashboard/vacancydetails/${res.data.vacancy._id}`);
              } catch (e) {
              } finally {
                setLoading(false);
              }
            }}
          >
            {t("Create")}
          </Button>
        </div>

        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 my-vacancies-grid mt-2">
          {removeDuplicateObjects(
            [singleVacancy, ...vacancies].filter((a) => !!a)
          ).map((vacancy) => (
            <Badge.Ribbon
              key={vacancy._id}
              size="small"
              style={{
                top: 60,
                display: vacancy?.isNew ? "block" : "none",
              }}
              text={t("New")}
              color={partner?.themeColor ?? brandColor}
            >
              <div className="max-w-sm rounded-xl overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl transition duration-300 ease-in-out">
                <div className="px-3 py-3 flex gap-3 items-center justify-between">
                  <div className="font-bold text-xl mb-2 whitespace-nowrap max-w-[200px] overflow-hidden truncate">
                    <Typography.Paragraph
                      editable={{
                        onChange: (e) => {
                          setVacancies((c) => {
                            const current = [...c];
                            const thisOne = current.find(
                              (a) => a._id === vacancy._id
                            );
                            if (!thisOne) return current;
                            thisOne.alternativeName = e;

                            return current;
                          });
                          if (singleVacancy?._id === vacancy?._id)
                            setSingleVacancy((x) => ({
                              ...x,
                              alternativeName: e,
                            }));
                          CrudService.update("Vacancy", vacancy._id, {
                            alternativeName: e,
                          });
                        },
                        value: vacancy.alternativeName || vacancy.name,
                        tooltip: t("Change funnel name"),
                      }}
                    >
                      {truncateString(vacancy.alternativeName, 25) ||
                        truncateString(vacancy.name, 25) ||
                        t("Unspecified")}
                    </Typography.Paragraph>
                  </div>
                  <Switch
                    size="small"
                    defaultChecked={vacancy.enabled !== false}
                    onChange={async (e) => {
                      await CrudService.update("Vacancy", vacancy._id, {
                        enabled: e,
                      });
                    }}
                  />
                </div>
                <div className="px-6 pb-4 gap-3 flex flex-col">
                  <div className="flex gap-3 justify-between">
                    <Link href={`/dashboard/vacancydetails?id=${vacancy._id}`}>
                      <Space className="bg-gray-300 rounded-lg px-2 py-1 text-indigo-500 font-bold cursor-pointer">
                        <Badge
                          count={vacancy.numberApplicants}
                          offset={[0, 1]}
                          overflowCount={999}
                          title={t("LTS")}
                          className="cursor-pointer text-indigo-500"
                        >
                          <PiKanbanFill size={23} title={t("LTS")} />
                        </Badge>
                        <div>{t("Leads")}</div>
                      </Space>
                    </Link>
                    <Popconfirm
                      title={t("Are you sure to duplicate this funnel?")}
                      onConfirm={async () => {
                        const vac = await ATSService.duplicateVacancy({
                          vacancyId: vacancy._id,
                        });
                        navigate(
                          `/dashboard/vacancydetails?id=${vac.data.vacancy._id}`
                        );
                      }}
                    >
                      <Space className="bg-gray-300 rounded-lg px-2 py-1 text-indigo-500 font-bold cursor-pointer">
                        <HiMiniDocumentDuplicate
                          size={23}
                          title={t("Duplicate Funnel")}
                          className="cursor-pointer text-indigo-500"
                        />
                        <div>{t("Duplicate")}</div>
                      </Space>
                    </Popconfirm>
                  </div>

                  <div className="flex gap-3 justify-between">
                    <Space
                      onClick={() => handleFirstConfirm(vacancy._id)}
                      className="bg-gray-300 rounded-lg px-2 py-1 text-indigo-500 font-bold cursor-pointer"
                    >
                      <MdDelete
                        size={23}
                        title={t("Delete")}
                        className="cursor-pointer text-red-500 "
                      />
                      <div>{t("Delete")}</div>
                    </Space>
                  </div>
                </div>
              </div>
            </Badge.Ribbon>
          ))}
        </div>
      </div>

      {total >= PAGE_LIMIT * (page - 1) && (
        <div className="flex justify-center mt-5">
          <Button loading={loading} onClick={() => loadMoreVacancies()}>
            {t("Load more")}
          </Button>
        </div>
      )}


      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!promoteFunnel}
        onCancel={() => setPromoteFunnel(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <div>
          <div className="mx-auto max-w-7xl px-6 ">
            <div className="mt-20 flow-root">
              <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto  ">
                {tiers.map((tier) => (
                  <div key={tier.id} className="  pt-16  lg:pt-0 xl:px-14">
                    <h3
                      id={tier.id}
                      className="text-base font-semibold leading-7 "
                    >
                      {t(tier.name)}
                    </h3>
                    <p className="mt-6 flex items-baseline gap-x-1">
                      <span className="text-5xl font-bold tracking-tight ">
                        ${tier.price}
                      </span>
                    </p>

                    <Popconfirm
                      title={t(
                        "Are you sure to promote? You will be charged ${{price}}.",
                        { price: tier.price }
                      )}
                      onConfirm={async () => {
                        try {
                          await ATSService.promoteFunnel(promoteFunnel, {
                            tier: tier.id,
                          });
                          ATSService.getActivePromotions().then(({ data }) => {
                            setActivePromotions(data.activePromotions);
                          });
                          setPromoteFunnel(null);
                        } catch (e) {
                          const link = e?.response?.data?.link;
                          if (link)
                            setTimeout(() => {
                              window.location.href = link;
                            }, 2000);
                        }
                      }}
                    >
                      <a
                        aria-describedby={tier.id}
                        className="mt-10 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {t("Promote now")}
                      </a>
                    </Popconfirm>
                    <p className="min-w-[100px] mt-10 text-sm font-semibold leading-6 ">
                      {t(tier.description)}
                    </p>
                    <ul
                      role="list"
                      className="mt-6 space-y-3 text-sm leading-6"
                    >
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckCircleIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          {t(feature)}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
