import { Button, Input, Popconfirm, Skeleton, Space, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import MultiStepComponent from "../../../components/MultiStepComponent";
import CrudService from "../../../service/CrudService";

import { FaCheckCircle, FaCode, FaGoogle } from "react-icons/fa";
import { FaMeta } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { login } from "../../../redux/auth/actions";
import { selectLoading, selectUser } from "../../../redux/auth/selectors";
import { store } from "../../../redux/store";
import AuthService from "../../../service/AuthService";
import GoogleService from "../../../service/GoogleService";
import PublicService from "../../../service/PublicService";
import UserService from "../../../service/UserService";

const CreateAd = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [landingpages, setLandingpages] = useState([]);
  const location = useLocation();
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();

  const [pixelId, setPixelId] = useState("");

  const handleSave = async () => {
    try {
      await AuthService.updateMe({ pixelId });
      // Optionally, you can show a success message or perform other actions
    } catch (error) {
      console.error("Error updating pixel ID:", error);
      // Optionally, you can show an error message or perform other actions
    }
  };

  useEffect(() => {
    AuthService.me().then((res) => {
      if (res.data?.me?.pixelId) setPixelId(res.data?.me?.pixelId ?? "");
    });
  }, []);

  useEffect(() => {
    if (user)
      CrudService.search("Vacancy", 10000, 1, {
        filters: {
          user_id: user._id,
        },
      }).then((res) => {
        setLandingpages(res.data.items);
      });
  }, [user]);

  const steps = [
    {
      name: t("Basic Information"),
      form: [
        {
          type: "input",
          label: t("Name of the campaign"),
          fieldName: "name",
          placeholder: t("Enter campaign name"),
          required: true,
        },
        {
          type: "select",
          label: t("Optimization Focus"),
          fieldName: "optimizationFocus",
          placeholder: t("Select optimization focus"),
          options: [
            { value: "clicks", label: t("Clicks") },
            { value: "conversions", label: t("Conversions") },
            { value: "sales", label: t("Sales") },
          ],
          required: true,
        },
        {
          type: "inputNumber",
          label: t("Monthly Budget (Local Currency)"),
          fieldName: "monthlyBudget",
          placeholder: t("Enter monthly budget"),
          required: true,
          min: 300,
        },
      ],
    },
    {
      name: t("Copy"),
      form: [
        {
          type: "input",
          label: t("Primary Text"),
          fieldName: "adPrimaryText",
          placeholder: t("Enter primary text"),
          required: true,
        },
        {
          type: "input",
          label: t("Headline"),
          fieldName: "adHeadline",
          placeholder: t("Enter headline"),
          required: true,
        },
        {
          type: "input",
          label: t("Description"),
          fieldName: "adDescription",
          placeholder: t("Enter description"),
          required: true,
        },
      ],
    },
    {
      name: t("Linking"),
      checker: () => {
        if (!user?.metaAccessToken)
          return { error: t("Please link your Meta account") };
        return true;
      },
      form: [
        {
          type: "custom",
          label: t("Meta Ads Linking"),
          fieldName: "linkingStatus",
          CustomInputComponent: ({}) => {
            const [loading, setLoading] = useState(false);
            const user = useSelector(selectUser);
            const navigate = useNavigate();

            const handleLink = async () => {
              setLoading(true);
              try {
                const res = await UserService.getOauthUri();
                localStorage.pref_id = formData._id;
                window.location.href = res.data.url; // Redirect to Meta OAuth
              } catch (error) {
                message.error(t("Failed to initiate linking process"));
              } finally {
                setLoading(false);
              }
            };

            const handleDisconnect = async () => {
              // Implement disconnect logic here
              await AuthService.updateMe({ silent: true, metaAccessToken: "" });

              const me = await AuthService.me();
              store.dispatch(login(me.data.me));
              navigate("/dashboard/createAd?defaultStep=2");
            };

            return (
              <div>
                {user?.metaAccessToken ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaMeta color="blue" size={24} />
                    <FaCheckCircle
                      color="green"
                      size={24}
                      style={{ marginLeft: 8 }}
                    />
                    <Popconfirm
                      title={t("Are you sure?")}
                      onConfirm={handleDisconnect}
                    >
                      <Button
                        type="danger"
                        className="bg-red-500 text-white"
                        style={{ marginLeft: "auto" }}
                      >
                        {t("Disconnect")}
                      </Button>
                    </Popconfirm>
                  </div>
                ) : (
                  <Space>
                    <FaMeta color="blue" size={24} />
                    <Button
                      type="primary"
                      onClick={handleLink}
                      loading={loading}
                    >
                      {t("Link Meta Ads Account")}
                    </Button>
                  </Space>
                )}
              </div>
            );
          },
        },
        {
          type: "custom",
          label: t("Enter Your Meta Pixel Code To Enable Conversion Tracking"),
          fieldName: "pixelCode",
          CustomInputComponent: ({ formData, onChange }) => {
            return (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaCode color="blue" size={24} />
                  <Input
                    className="rounded-md ml-10 mr-2"
                    placeholder={`1274665462104112`}
                    value={pixelId}
                    onChange={(e) => setPixelId(e.target.value)}
                  />
                  <Button
                    size="large"
                    className="px-2 py-1 bg-indigo-500 !hover:bg-indigo-500 text-white rounded-xl"
                    type="primary"
                    loading={loading}
                    onClick={handleSave}
                  >
                    {t("Save")}
                  </Button>
                </div>
              </div>
            );
          },
        },

        {
          type: "custom",
          label: t("Google Ads Linking"),
          fieldName: "linkingStatus3",
          CustomInputComponent: ({}) => {
            const [loading, setLoading] = useState(false);
            const user = useSelector(selectUser);
            const navigate = useNavigate();

            const handleLink = async () => {
              setLoading(true);
              try {
                const res = await GoogleService.getAuthURI();
                localStorage.pref_id = formData._id;
                window.location.href = res.data.authUri; // Redirect to Meta OAuth
              } catch (error) {
                message.error(t("Failed to initiate linking process"));
              } finally {
                setLoading(false);
              }
            };

            const handleDisconnect = async () => {
              // Implement disconnect logic here
              await AuthService.updateMe({ silent: true, connectGoogle: null });

              const me = await AuthService.me();
              store.dispatch(login(me.data.me));
              navigate("/dashboard/createAd?defaultStep=2");
            };

            return (
              <div>
                {user?.connectGoogle ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaGoogle color="blue" size={24} />
                    <FaCheckCircle
                      color="green"
                      size={24}
                      style={{ marginLeft: 8 }}
                    />
                    <Popconfirm
                      title={t("Are you sure?")}
                      onConfirm={handleDisconnect}
                    >
                      <Button
                        type="danger"
                        className="bg-red-500 text-white"
                        style={{ marginLeft: "auto" }}
                      >
                        {t("Disconnect")}
                      </Button>
                    </Popconfirm>
                  </div>
                ) : (
                  <Space>
                    <FaGoogle color="blue" size={24} />
                    <Button
                      type="primary"
                      onClick={handleLink}
                      loading={loading}
                    >
                      {t("Link Google Ads Account")}
                    </Button>
                  </Space>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      name: t("Functionality Selection"),
      form: [
        {
          type: "radio",
          label: t("Functionality"),
          fieldName: "functionalitySel",
          options: [
            {
              value: "full",
              label: t("Get full functionality including ad optimization"),
            },
            { value: "reporting", label: t("Only reporting functionality") },
          ],
          required: true,
        },
        {
          type: "inputNumber",
          label: t("Optimisation Period"),
          fieldName: "optimisationPeriod",
          min: 1,
          max: 30,
          tooltip: t(
            "If you select the full functionality option including ad optimization, you can also select the period in days in which the ads will be optimized. For example if it's set to 5, it will check the ad performance every 5 days, and make the necessary adjustments to improve the ads."
          ),
          required: true,
        },
        {
          type: "select",
          label: t("Target Funnel"),
          fieldName: "vacancy",
          placeholder: t("Select target funnel"),
          options: landingpages.map((l) => ({
            label: l?.alternativeName || l?.name,
            value: l?._id,
          })),
          required: true,
        },
      ],
    },
  ];

  useEffect(() => {
    const getData = (pref_id) => {
      const id = pref_id ?? searchParams.get("id");
      if (id && pref_id !== "null" && pref_id !== "undefined") {
        setFormData(null);

        CrudService.getSingle("AdCampaign", id).then((res) => {
          if (res.data) setFormData(res.data);
        });
      }
    };

    if (window.location.href.includes("defaultStep=2")) setActiveStep(2);

    const handleAccessToken = async (access_token) => {
      // Handle the OAuth access_token from Meta
      if (!user?.metaAccessToken) {
        await AuthService.updateMe({
          silent: true,
          metaAccessToken: access_token,
        });

        const me = await AuthService.me();
        store.dispatch(login(me.data.me));

        setActiveStep(2);
      }
      setTimeout(() => getData(localStorage.pref_id), 500);
    };

    const handleCode = async (code) => {
      // Handle the OAuth access_token from Meta
      await GoogleService.requestToken({
        code,
      });

      const me = await AuthService.me();
      store.dispatch(login(me.data.me));

      setActiveStep(2);
      setTimeout(() => getData(localStorage.pref_id), 500);
    };

    const access_token = window.location?.href?.split?.("access_token=")?.[1];
    const code = window.location?.href?.split?.("code=")?.[1];
    if (access_token) {
      handleAccessToken(access_token);
      setActiveStep(2);
    } else if (code) {
      handleCode(code);
    } else {
      getData();
    }
  }, [location, user]);

  const handleNext = async (data) => {
    const updated = { ...data };
    updated._id = undefined;
    await CrudService.update("AdCampaign", data._id, updated);
  };

  if (!formData) return <Skeleton active />;

  return (
    <>
      <MultiStepComponent
        steps={steps}
        defaultFormData={formData}
        onFinish={async (formData) => {
          await handleNext(formData);

          try {
            await UserService.extendTokenTime();
          } catch (e) {
            await AuthService.updateMe({ silent: true, metaAccessToken: "" });

            const me = await AuthService.me();
            store.dispatch(login(me.data.me));
            navigate("/dashboard/createAd?defaultStep=2");
          }

          navigate(
            `/dashboard/createAdCreatives?id=${
              formData?._id ?? searchParams.get("id")
            }`
          );
        }}
        onNext={handleNext}
        displayUndoRedo={true}
        defaultActiveStep={activeStep}
      />
    </>
  );
};

export default CreateAd;
