import { Card, Row, Col } from "antd";

const getColor = (colorObject) => {
  // Check if the object is null or undefined
  if (!colorObject) return "#ffffff";

  // Check if it's already a string (hex color)
  if (typeof colorObject === "string") {
    return colorObject;
  }

  // Check if the object has valid RGB values
  if (
    typeof colorObject.r === "number" &&
    typeof colorObject.g === "number" &&
    typeof colorObject.b === "number"
  ) {
    // Convert RGB to hex
    const toHex = (value) => {
      const hex = Math.round(value).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    return `#${toHex(colorObject.r)}${toHex(colorObject.g)}${toHex(
      colorObject.b
    )}`;
  }

  // Check if the object has a valid hex string in originalInput
  if (
    colorObject.originalInput &&
    typeof colorObject.originalInput === "object" &&
    "h" in colorObject.originalInput &&
    "s" in colorObject.originalInput &&
    "v" in colorObject.originalInput
  ) {
    // Convert HSV to RGB
    const { h, s, v } = colorObject.originalInput;
    const c = v * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = v - c;

    let r, g, b;
    if (h < 60) {
      [r, g, b] = [c, x, 0];
    } else if (h < 120) {
      [r, g, b] = [x, c, 0];
    } else if (h < 180) {
      [r, g, b] = [0, c, x];
    } else if (h < 240) {
      [r, g, b] = [0, x, c];
    } else if (h < 300) {
      [r, g, b] = [x, 0, c];
    } else {
      [r, g, b] = [c, 0, x];
    }

    // Convert to hex
    const toHex = (value) => {
      const hex = Math.round((value + m) * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  // If we couldn't derive a valid hex color, return white
  return "#ffffff";
};

const CreativeTemplates = ({ creatives, onSelect }) => {
  // Create a deep copy of creatives to prevent shared state
  const cloneCreative = (creative) => ({
    ...creative,
    text: { ...creative.text },
    template: {
      ...creative.template,
      defaultShapes: creative?.template?.defaultShapes?.map?.((shape) => ({
        ...shape,
      })),
      textPlacement: {
        headline: { ...creative.template.textPlacement.headline },
        description: { ...creative.template.textPlacement.description },
      },
    },
  });

  return (
    <Row gutter={[16, 16]}>
      {creatives.map((creative, index) => (
        <Col key={index} xs={24} sm={12} md={8}>
          <Card
            hoverable
            cover={
              <div
                style={{
                  position: "relative",
                  height: "200px",
                  overflow: "hidden",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <img
                  alt={`Creative ${index + 1}`}
                  src={creative.image || "/placeholder.svg"}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    display: "block",
                  }}
                  crossOrigin="anonymous"
                />
                {creative?.template?.defaultShapes?.map?.(
                  (shape, shapeIndex) => (
                    <div
                      key={shapeIndex}
                      style={{
                        position: "absolute",
                        left: `${(shape.left / 600) * 100}%`,
                        top: `${(shape.top / 400) * 100}%`,
                        width: `${(shape.width / 600) * 100}%`,
                        height: `${(shape.height / 400) * 100}%`,
                        background: shape.fill,
                        opacity: shape.opacity,
                      }}
                    />
                  )
                )}
                <div
                  style={{
                    position: "absolute",
                    left: `${
                      (creative.template.textPlacement.headline.left / 600) *
                      100
                    }%`,
                    top: `${
                      (creative.template.textPlacement.headline.top / 400) * 100
                    }%`,
                    width: `${
                      (creative.template.textPlacement.headline.width / 600) *
                      100
                    }%`,
                    color: getColor(creative.text?.color || "#ffffff"),
                    fontSize: `${creative.template.textPlacement.headline.fontSize}px`,
                    fontWeight: "bold",
                    textAlign:
                      creative.template.textPlacement.headline.textAlign ||
                      "center",
                    textShadow: "0px 1px 2px rgba(0,0,0,0.5)",
                    lineHeight: 1.2,
                  }}
                >
                  {creative.text.headline}
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: `${
                      (creative.template.textPlacement.description.left / 600) *
                      100
                    }%`,
                    top: `${
                      (creative.template.textPlacement.description.top / 400) *
                      100
                    }%`,
                    width: `${
                      (creative.template.textPlacement.description.width /
                        600) *
                      100
                    }%`,
                    color: getColor(creative.text?.color || "#ffffff"),
                    fontSize: `${creative.template.textPlacement.description.fontSize}px`,
                    textAlign:
                      creative.template.textPlacement.description.textAlign ||
                      "center",
                    textShadow: "0px 1px 2px rgba(0,0,0,0.5)",
                    lineHeight: 1.3,
                  }}
                >
                  {creative.text.description}
                </div>
              </div>
            }
            onClick={() => onSelect(cloneCreative(creative))}
          >
            <Card.Meta
              title={creative.template.name}
              description="Click to edit"
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CreativeTemplates;
