import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class CallManagementService {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  activeCalls() {
    return this.api.get("/activeCalls");
  }
  searchNumber() {
    return this.api.get(`/searchNumber`);
  }
  getUserNumbers() {
    return this.api.get(`/getUserNumbers`);
  }
  deleteNumber(phoneNumber) {
    return this.api.delete(
      `/deleteNumber?phoneNumber=${encodeURIComponent(phoneNumber)}`
    );
  }
  purchaseNumber(data) {
    return this.api.post(`/purchaseNumber`, data);
  }

  createAgent(data) {
    return this.api.post("/createAgent", data);
  }

  getUserAgents() {
    return this.api.get("/getUserAgents");
  }

  updateAgent(agentId, data) {
    return this.api.put(`/updateAgent/${agentId}`, data);
  }

  deleteAgent(agentId) {
    return this.api.delete(`/deleteAgent/${agentId}`);
  }

  assignAgentToNumber(data) {
    return this.api.post("/assignAgentToNumber", data);
  }
}

export default new CallManagementService(`${getBackendUrl()}/callManagement`);
