export const translationEn = {
  "Generate landing page with AI": "Generate landing page with AI",
  "Japanese Yen": "Japanese Yen",
  "U.S. dollar": "U.S. dollar",
  Euro: "Euro",
  Sterling: "Sterling",
  "Australian dollar": "Australian dollar",
  "Canadian dollar": "Canadian dollar",
  "Swiss franc": "Swiss franc",
  "Indian rupee": "Indian rupee",
  "UAE dirham": "UAE dirham",
  "A great headline to capture interest":
    "A great headline to capture interest",
  "5-10 word subline": "5-10 word subline",
  "A detailed description of our services / products":
    "A detailed description of our services / products",
  "5-10 word benefit": "5-10 word benefit",
  "Contact Us": "Contact Us",
  "Book Demo": "Book Demo",
  "Feature title of our product / service":
    "Feature title of our product / service",
  "Feature description of our product / service":
    "Feature description of our product / service",
  "Testimonial section title": "Testimonial section title",
  "Summary of what customers are saying about us":
    "Summary of what customers are saying about us",
  "Feature Category Title": "Feature Category Title",
  "Feature description (5-10 words)": "Feature description (5-10 words)",
  "Sign Up for Updates": "Sign Up for Updates",
  "Stay informed with the latest news from our team.":
    "Stay informed with the latest news from our team.",
  "A meaningful title for the entire benefits section":
    "A meaningful title for the entire benefits section",
  "Description of what our core benefits are":
    "Description of what our core benefits are",
  "Benefit title (2-5 words)": "Benefit title (2-5 words)",
  "Benefit description (10-20 words)": "Benefit description (10-20 words)",
  "Title for the section of our promise":
    "Title for the section of our promise",
  "Description of our promise": "Description of our promise",
  "Key Feature 1": "Key Feature 1",
  "Key Feature 2": "Key Feature 2",
  "Key Feature 3": "Key Feature 3",
  Home: "Home",
  "Get started": "Get started",
  "for free": "for free",
  "Already registered?": "Already registered?",
  "Sign in": "Sign in",
  "First name": "First name",
  "Last name": "Last name",
  "Email address": "Email address",
  Password: "Password",
  Login: "Login",
  "I accept the": "I accept the",
  "privacy policy": "privacy policy",
  and: "and",
  "terms of service": "terms of service",
  "Sign up": "Sign up",
  "Could not load user data": "Could not load user data",
  Upload: "Upload",
  Delete: "Delete",
  Add: "Add",
  "Please write some text first": "Please write some text first",
  "AI needs a little more context. Please write at least {{count}} characters.":
    "AI needs a little more context. Please write at least {{count}} characters.",
  Tip: "Tip",
  "{{fields}} {{isAre}} required": "{{fields}} {{isAre}} required",
  is: "is",
  are: "are",
  "Invalid email": "Invalid email",
  Previous: "Previous",
  Next: "Next",
  Finish: "Finish",
  "Edit Form Input": "Edit Form Input",
  Cancel: "Cancel",
  Save: "Save",
  Label: "Label",
  Type: "Type",
  "Short Input": "Short Input",
  Email: "Email",
  Phone: "Phone",
  "Large Input": "Large Input",
  "Input Number": "Input Number",
  Radio: "Radio",
  Select: "Select",
  Rate: "Rate",
  Slider: "Slider",
  Switch: "Switch",
  "Time Picker": "Time Picker",
  "Date Picker": "Date Picker",
  Checkbox: "Checkbox",
  "Color Picker": "Color Picker",
  Min: "Min",
  Max: "Max",
  Step: "Step",
  Rows: "Rows",
  Options: "Options",
  "Option Label": "Option Label",
  Remove: "Remove",
  "Add Option": "Add Option",
  "Skip Logic Conditions": "Skip Logic Conditions",
  "Add Condition": "Add Condition",
  "KPI Display Name": "KPI Display Name",
  "Add a descriptive name for your KPI to make it easily recognizable in your dashboard. This KPI will be based on the average value across all form submissions made by participants in response to this particular field.":
    "Add a descriptive name for your KPI to make it easily recognizable in your dashboard. This KPI will be based on the average value across all form submissions made by participants in response to this particular field.",
  "Enter metric name to track as a KPI": "Enter metric name to track as a KPI",
  Edit: "Edit",
  "Are you sure?": "Are you sure?",
  Japan: "Japan",
  China: "China",
  "United States": "United States",
  Germany: "Germany",
  India: "India",
  "United Kingdom": "United Kingdom",
  France: "France",
  Canada: "Canada",
  Italy: "Italy",
  Brazil: "Brazil",
  Australia: "Australia",
  "South Korea": "South Korea",
  Mexico: "Mexico",
  Spain: "Spain",
  Indonesia: "Indonesia",
  "Saudi Arabia": "Saudi Arabia",
  Netherlands: "Netherlands",
  Turkey: "Turkey",
  Switzerland: "Switzerland",
  Poland: "Poland",
  Argentina: "Argentina",
  Sweden: "Sweden",
  Norway: "Norway",
  Belgium: "Belgium",
  Ireland: "Ireland",
  Israel: "Israel",
  "United Arab Emirates": "United Arab Emirates",
  Thailand: "Thailand",
  Nigeria: "Nigeria",
  Egypt: "Egypt",
  Austria: "Austria",
  Singapore: "Singapore",
  Russia: "Russia",
  Bangladesh: "Bangladesh",
  Vietnam: "Vietnam",
  Malaysia: "Malaysia",
  "South Africa": "South Africa",
  Philippines: "Philippines",
  Denmark: "Denmark",
  Iran: "Iran",
  Pakistan: "Pakistan",
  "Hong Kong": "Hong Kong",
  Colombia: "Colombia",
  Romania: "Romania",
  Chile: "Chile",
  "Czech Republic (Czechia": "Czech Republic (Czechia",
  Finland: "Finland",
  Iraq: "Iraq",
  Portugal: "Portugal",
  "New Zealand": "New Zealand",
  Peru: "Peru",
  Qatar: "Qatar",
  Kazakhstan: "Kazakhstan",
  Greece: "Greece",
  Algeria: "Algeria",
  Kuwait: "Kuwait",
  Hungary: "Hungary",
  Ukraine: "Ukraine",
  Morocco: "Morocco",
  Ethiopia: "Ethiopia",
  Slovakia: "Slovakia",
  Ecuador: "Ecuador",
  Oman: "Oman",
  "Dominican Republic": "Dominican Republic",
  Kenya: "Kenya",
  Angola: "Angola",
  Guatemala: "Guatemala",
  Bulgaria: "Bulgaria",
  Luxembourg: "Luxembourg",
  Uzbekistan: "Uzbekistan",
  Azerbaijan: "Azerbaijan",
  Panama: "Panama",
  Tanzania: "Tanzania",
  "Sri Lanka": "Sri Lanka",
  Ghana: "Ghana",
  Belarus: "Belarus",
  Uruguay: "Uruguay",
  Croatia: "Croatia",
  Lithuania: "Lithuania",
  "Côte d'Ivoire": "Côte d'Ivoire",
  "Costa Rica": "Costa Rica",
  Serbia: "Serbia",
  Slovenia: "Slovenia",
  Myanmar: "Myanmar",
  "DR Congo": "DR Congo",
  Sudan: "Sudan",
  Jordan: "Jordan",
  Tunisia: "Tunisia",
  Libya: "Libya",
  Uganda: "Uganda",
  Bahrain: "Bahrain",
  Cameroon: "Cameroon",
  Bolivia: "Bolivia",
  Paraguay: "Paraguay",
  Latvia: "Latvia",
  Nepal: "Nepal",
  Estonia: "Estonia",
  "El Salvador": "El Salvador",
  Honduras: "Honduras",
  "Papua New Guinea": "Papua New Guinea",
  Cambodia: "Cambodia",
  Zambia: "Zambia",
  Cyprus: "Cyprus",
  "Trinidad and Tobago": "Trinidad and Tobago",
  Iceland: "Iceland",
  Senegal: "Senegal",
  Georgia: "Georgia",
  "Bosnia and Herzegovina": "Bosnia and Herzegovina",
  Macao: "Macao",
  Guinea: "Guinea",
  Gabon: "Gabon",
  Zimbabwe: "Zimbabwe",
  Botswana: "Botswana",
  Haiti: "Haiti",
  Armenia: "Armenia",
  "State of Palestine": "State of Palestine",
  "Burkina Faso": "Burkina Faso",
  Albania: "Albania",
  Mali: "Mali",
  Mozambique: "Mozambique",
  Malta: "Malta",
  Benin: "Benin",
  Jamaica: "Jamaica",
  Mongolia: "Mongolia",
  Brunei: "Brunei",
  Laos: "Laos",
  Nicaragua: "Nicaragua",
  Guyana: "Guyana",
  Madagascar: "Madagascar",
  Congo: "Congo",
  Moldova: "Moldova",
  Niger: "Niger",
  "North Macedonia": "North Macedonia",
  Rwanda: "Rwanda",
  Malawi: "Malawi",
  Mauritius: "Mauritius",
  Bahamas: "Bahamas",
  Chad: "Chad",
  Namibia: "Namibia",
  "Equatorial Guinea": "Equatorial Guinea",
  Kyrgyzstan: "Kyrgyzstan",
  Tajikistan: "Tajikistan",
  Mauritania: "Mauritania",
  Togo: "Togo",
  Maldives: "Maldives",
  Montenegro: "Montenegro",
  Barbados: "Barbados",
  Fiji: "Fiji",
  Eswatini: "Eswatini",
  Liberia: "Liberia",
  "Sierra Leone": "Sierra Leone",
  Suriname: "Suriname",
  Andorra: "Andorra",
  "Timor-Leste": "Timor-Leste",
  Burundi: "Burundi",
  Belize: "Belize",
  Lesotho: "Lesotho",
  "Central African Republic": "Central African Republic",
  "Cabo Verde": "Cabo Verde",
  Gambia: "Gambia",
  "Saint Lucia": "Saint Lucia",
  "Antigua and Barbuda": "Antigua and Barbuda",
  "Guinea-Bissau": "Guinea-Bissau",
  "Solomon Islands": "Solomon Islands",
  Seychelles: "Seychelles",
  Grenada: "Grenada",
  Comoros: "Comoros",
  Vanuatu: "Vanuatu",
  "Saint Kitts & Nevis": "Saint Kitts & Nevis",
  "St. Vincent & Grenadines": "St. Vincent & Grenadines",
  Samoa: "Samoa",
  Dominica: "Dominica",
  "Sao Tome & Principe": "Sao Tome & Principe",
  Micronesia: "Micronesia",
  "Marshall Islands": "Marshall Islands",
  Kiribati: "Kiribati",
  Tuvalu: "Tuvalu",
  "Email Verification": "Email Verification",
  "We have sent a code to your email {{email}}":
    "We have sent a code to your email {{email}}",
  Verify: "Verify",
  "Didn't receive the code?": "Didn't receive the code?",
  Resend: "Resend",
  "Sign out": "Sign out",
  "Change Image": "Change Image",
  "Your Name": "Your Name",
  "Your Email": "Your Email",
  "Your Phone": "Your Phone",
  "Click to {{action}} page after submit":
    "Click to {{action}} page after submit",
  edit: "edit",
  add: "add",
  "This form will send visitors into: {{stepName}}":
    "This form will send visitors into: {{stepName}}",
  "Your data is secured": "Your data is secured",
  "Thanks for signing up!": "Thanks for signing up!",
  "Change Video": "Change Video",
  "Change Thumbnail": "Change Thumbnail",
  "Edit Buttons": "Edit Buttons",
  "from {{count}}+ reviews": "from {{count}}+ reviews",
  "Reorder Items": "Reorder Items",
  "Add Component": "Add Component",
  "Stage Workflows": "Stage Workflows",
  "Add Lead": "Add Lead",
  Import: "Import",
  Export: "Export",
  "Email Sequence": "Email Sequence",
  "Load More": "Load More",
  "Send Email": "Send Email",
  Schedule: "Schedule",
  "Send SMS": "Send SMS",
  Whatsapp: "Whatsapp",
  Call: "Call",
  Reject: "Reject",
  "Undo Reject": "Undo Reject",
  "Lead Notes": "Lead Notes",
  Details: "Details",
  LinkedIn: "LinkedIn",
  Today: "Today",
  Yesterday: "Yesterday",
  "Today at": "Today at",
  Rename: "Rename",
  "Password reset": "Password reset",
  "Forgot your password? Type your email, and we will send an OTP.":
    "Forgot your password? Type your email, and we will send an OTP.",
  "Request OTP": "Request OTP",
  "Back to login": "Back to login",
  "Sign in to your account": "Sign in to your account",
  "Don't have an account?": "Don't have an account?",
  "for a {{trialDays}} day free trial.": "for a {{trialDays}} day free trial.",
  "to get started.": "to get started.",
  "Forgot password?": "Forgot password?",
  "We are adjusting the funnels to your ideal customer profile":
    "We are adjusting the funnels to your ideal customer profile",
  "We are setting up your messaging": "We are setting up your messaging",
  "We are creating a phone call strategy":
    "We are creating a phone call strategy",
  "We are generating your online marketing strategy":
    "We are generating your online marketing strategy",
  "We are tweaking your email messaging":
    "We are tweaking your email messaging",
  "We are analyzing market trends to tailor your strategy":
    "We are analyzing market trends to tailor your strategy",
  "We are crafting compelling ad copy for your campaigns":
    "We are crafting compelling ad copy for your campaigns",
  "We are refining your brand's visual identity":
    "We are refining your brand's visual identity",
  "We are researching keywords to boost your SEO":
    "We are researching keywords to boost your SEO",
  "We are conducting competitor analysis to identify opportunities":
    "We are conducting competitor analysis to identify opportunities",
  "We are drafting engaging email sequences":
    "We are drafting engaging email sequences",
  "We are creating captivating graphics for your content":
    "We are creating captivating graphics for your content",
  "We are setting up tracking pixels for accurate analytics":
    "We are setting up tracking pixels for accurate analytics",
  "We are enhancing your product descriptions for conversions":
    "We are enhancing your product descriptions for conversions",
  "We are segmenting your audience for targeted marketing":
    "We are segmenting your audience for targeted marketing",
  "We are brainstorming viral campaign ideas":
    "We are brainstorming viral campaign ideas",
  "We are optimizing your product photos for eCommerce":
    "We are optimizing your product photos for eCommerce",
  "We are conducting A/B tests for better campaign performance":
    "We are conducting A/B tests for better campaign performance",
  "We are developing interactive content for engagement":
    "We are developing interactive content for engagement",
  "We are building landing pages for lead generation":
    "We are building landing pages for lead generation",
  "We are setting up automated workflows for efficiency":
    "We are setting up automated workflows for efficiency",
  "We are exploring new advertising channels for growth":
    "We are exploring new advertising channels for growth",
  "We are designing eye-catching banners for promotions":
    "We are designing eye-catching banners for promotions",
  "We are crafting compelling value propositions":
    "We are crafting compelling value propositions",
  "We are curating user-generated content for authenticity":
    "We are curating user-generated content for authenticity",
  "We are translating your content for international markets":
    "We are translating your content for international markets",
  "We are designing infographics for data visualization":
    "We are designing infographics for data visualization",
  "We are setting up retargeting campaigns for lost leads":
    "We are setting up retargeting campaigns for lost leads",
  "We are conducting user testing for UX improvements":
    "We are conducting user testing for UX improvements",
  "We are creating case studies to showcase success stories":
    "We are creating case studies to showcase success stories",
  "We are optimizing your email subject lines for opens":
    "We are optimizing your email subject lines for opens",
  "We are developing video content for engagement":
    "We are developing video content for engagement",
  "We are conducting social listening for brand sentiment":
    "We are conducting social listening for brand sentiment",
  "We are optimizing your content for search engines":
    "We are optimizing your content for search engines",
  "We are designing promotional flyers for events":
    "We are designing promotional flyers for events",
  "We are optimizing your landing page loading speed":
    "We are optimizing your landing page loading speed",
  "We are crafting FAQs to address common queries":
    "We are crafting FAQs to address common queries",
  "We are conducting usability testing for website navigation":
    "We are conducting usability testing for website navigation",
  "We are setting up Google Analytics goals for tracking":
    "We are setting up Google Analytics goals for tracking",
  Onboarding: "Onboarding",
  "We are setting up your customized marketing system.":
    "We are setting up your customized marketing system.",
  Information: "Information",
  "Confirm OTP": "Confirm OTP",
  "Enter your new password": "Enter your new password",
  Submit: "Submit",
  "to your account.": "to your account.",
  "Account Activation": "Account Activation",
  "To access the plethora of tools and resources available on our platform, please choose a subscription plan that aligns with your requirements. Based on your current usage, we recommend subscribing to the {{name}} package or a higher-tier plan.":
    "To access the plethora of tools and resources available on our platform, please choose a subscription plan that aligns with your requirements. Based on your current usage, we recommend subscribing to the {{name}} package or a higher-tier plan.",
  "To unlock the full suite of tools and resources available through our platform, please select a subscription plan that best fits your needs.":
    "To unlock the full suite of tools and resources available through our platform, please select a subscription plan that best fits your needs.",
  "Payment frequency": "Payment frequency",
  Monthly: "Monthly",
  Annually: "Annually",
  "Most popular": "Most popular",
  month: "month",
  year: "year",
  "per team mate": "per team mate",
  "{{trialDays}} Days Free": "{{trialDays}} Days Free",
  "Buy Plan": "Buy Plan",
  "Post up to {{maxFunnels}} funnels": "Post up to {{maxFunnels}} funnels",
  "Unlimited funnels": "Unlimited funnels",
  "${{cost}} per additional funnel": "${{cost}} per additional funnel",
  "Up to {{maxCandidates}} leads": "Up to {{maxCandidates}} leads",
  "Unlimited leads": "Unlimited leads",
  "${{cost}} per additional lead": "${{cost}} per additional lead",
  "Up to {{maxMessaging}} messaging templates":
    "Up to {{maxMessaging}} messaging templates",
  "Unlimited messaging templates": "Unlimited messaging templates",
  "Up to {{maxTeamSize}} team mates": "Up to {{maxTeamSize}} team mates",
  "Unlimited team mates": "Unlimited team mates",
  "AI Campaign Generator": "AI Campaign Generator",
  "AI Ad Automation": "AI Ad Automation",
  "AI Cold Calling": "AI Cold Calling",
  "AI Email Marketing": "AI Email Marketing",
  "AI Webinar Automation": "AI Webinar Automation",
  "AI Lead Generation": "AI Lead Generation",
  "E-Learning": "E-Learning",
  "Basic Information": "Basic Information",
  "Phone Number*": "Phone Number*",
  "Address Line 1*": "Address Line 1*",
  "Address Line 2": "Address Line 2",
  "City*": "City*",
  "State*": "State*",
  "Zip Code*": "Zip Code*",
  "Country*": "Country*",
  "Company Information": "Company Information",
  "Company Name*": "Company Name*",
  "Company Website*": "Company Website*",
  Branding: "Branding",
  Logo: "Logo",
  "Upload your company logo": "Upload your company logo",
  "Communication Preferences": "Communication Preferences",
  "Calendar Link": "Calendar Link",
  "Enter your calendar link for scheduling":
    "Enter your calendar link for scheduling",
  "Mail Template": "Mail Template",
  "Enter your default mail template": "Enter your default mail template",
  "Service Details": "Service Details",
  "Target Age": "Target Age",
  "Target Gender": "Target Gender",
  "Target Interests": "Target Interests",
  "Target Industries": "Target Industries",
  "Target Keywords": "Target Keywords",
  "Describe your the benefits of your service*":
    "Describe your the benefits of your service*",
  "Target Age*": "Target Age*",
  "Target Gender*": "Target Gender*",
  Man: "Man",
  Woman: "Woman",
  Diverse: "Diverse",
  "Target Interests*": "Target Interests*",
  "Target Industries*": "Target Industries*",
  "Target Keywords*": "Target Keywords*",
  "Connection was not successful": "Connection was not successful",
  Host: "Host",
  Port: "Port",
  "DKIM Settings": "DKIM Settings",
  Domain: "Domain",
  "Key Selector": "Key Selector",
  "Private Key": "Private Key",
  Disconnect: "Disconnect",
  Connect: "Connect",
  "We are pleased to welcome you aboard as a valued user in the journey towards enhancing your marketing. To ensure a smooth and efficient setup process, we have prepared a step-by-step guide to assist you in configuring all the necessary elements.":
    "We are pleased to welcome you aboard as a valued user in the journey towards enhancing your marketing. To ensure a smooth and efficient setup process, we have prepared a step-by-step guide to assist you in configuring all the necessary elements.",
  "DomainKeys Identified Mail (DKIM) is an email authentication method that helps protect email senders and recipients from spam, spoofing, and phishing. It allows an email receiver to check that an email claimed to have come from a specific domain was indeed authorized by the owner of that domain. Using a DKIM generator, such as the one available at EasyDMARC's DKIM Record Generator (click on the lightbulb icon to navigate there), you can easily create these keys. Fill in the 'Domain' and 'Key Selector' fields with the appropriate information for your domain, then generate and use the provided private key here. Your public key will be part of the DKIM record you add to your domain's DNS settings.":
    "DomainKeys Identified Mail (DKIM) is an email authentication method that helps protect email senders and recipients from spam, spoofing, and phishing. It allows an email receiver to check that an email claimed to have come from a specific domain was indeed authorized by the owner of that domain. Using a DKIM generator, such as the one available at EasyDMARC's DKIM Record Generator (click on the lightbulb icon to navigate there), you can easily create these keys. Fill in the 'Domain' and 'Key Selector' fields with the appropriate information for your domain, then generate and use the provided private key here. Your public key will be part of the DKIM record you add to your domain's DNS settings.",
  "When you disconnect your SMTP, your emails will no longer be sent from your inbox.":
    "When you disconnect your SMTP, your emails will no longer be sent from your inbox.",
  Billing: "Billing",
  "Here you can manage your subscription plan.":
    "Here you can manage your subscription plan.",
  Current: "Current",
  "Funnels created": "Funnels created",
  Leads: "Leads",
  "Messaging templates stored": "Messaging templates stored",
  "Team mates": "Team mates",
  "Your plan has been upgraded": "Your plan has been upgraded",
  "Upgrade Plan": "Upgrade Plan",
  "Upgrade plan": "Upgrade plan",
  "Billing Management": "Billing Management",
  Invoices: "Invoices",
  "Connect Your Calendly to Get Started":
    "Connect Your Calendly to Get Started",
  "Head over to your Calendly dashboard.":
    "Head over to your Calendly dashboard.",
  "We see that you've successfully linked your Calendly account – that's a great first step. To streamline your scheduling process, please select a default event type from the options below. This will be the meeting template that interviewees will use to book a time with you.":
    "We see that you've successfully linked your Calendly account – that's a great first step. To streamline your scheduling process, please select a default event type from the options below. This will be the meeting template that interviewees will use to book a time with you.",
  "Selecting a default event type for interviews will help you to automate your scheduling and let you focus on the conversations that matter. If you need any help along the way, we're here for you!":
    "Selecting a default event type for interviews will help you to automate your scheduling and let you focus on the conversations that matter. If you need any help along the way, we're here for you!",
  "We are not able to provide you with any options, because you haven't set up any booking events in your Calendly account yet. No worries, it's a quick fix:":
    "We are not able to provide you with any options, because you haven't set up any booking events in your Calendly account yet. No worries, it's a quick fix:",
  "Create at least one event type to be used for interviews.":
    "Create at least one event type to be used for interviews.",
  "Come back here, and voilà, your event should appear.":
    "Come back here, and voilà, your event should appear.",
  "Would you like to instead disconnect your calendly integration?":
    "Would you like to instead disconnect your calendly integration?",
  "Your calendar scheduling functionality will stop working. Are you sure to proceed?":
    "Your calendar scheduling functionality will stop working. Are you sure to proceed?",
  Privacy: "Privacy",
  Terms: "Terms",
  "Acceptable Use": "Acceptable Use",
  Cookies: "Cookies",
  Disclaimer: "Disclaimer",
  "Create Landing Page": "Create Landing Page",
  "My Landing Pages": "My Landing Pages",
  "Ad Automation": "Ad Automation",
  "Contact List": "Contact List",
  Team: "Team",
  "Media Library": "Media Library",
  "My Users": "My Users",
  "My Stats": "My Stats",
  "Support Tickets": "Support Tickets",
  Settings: "Settings",
  "SaaS Configuration": "SaaS Configuration",
  "Feature Request": "Feature Request",
  "Bug Report": "Bug Report",
  "As a user, I want to be able to...": "As a user, I want to be able to...",
  "so that I can...": "so that I can...",
  "This feature will particularly help me with...":
    "This feature will particularly help me with...",
  Send: "Send",
  "I was trying to...": "I was trying to...",
  "Here are additional details on what happened...":
    "Here are additional details on what happened...",
  "Here is a step-by-step process to reproduce the error I am facing...":
    "Here is a step-by-step process to reproduce the error I am facing...",
  "{{timeframe}} {{idx}} ago": "{{timeframe}} {{idx}} ago",
  This: "This",
  "SaaS Statistics": "SaaS Statistics",
  "Signup surveys": "Signup surveys",
  "Total Users": "Total Users",
  "Website Purpose": "Website Purpose",
  "{{count}} ({{percentage}}%)": "{{count}} ({{percentage}}%)",
  "Discovery Source": "Discovery Source",
  Role: "Role",
  "Your overall performance": "Your overall performance",
  "Monthly Active Users": "Monthly Active Users",
  "Subscribed Users": "Subscribed Users",
  "Users Subscribed Once": "Users Subscribed Once",
  "Monthly Recurring Revenue": "Monthly Recurring Revenue",
  "Paid User Ratio": "Paid User Ratio",
  "Churn Rate": "Churn Rate",
  Clicks: "Clicks",
  Conversions: "Conversions",
  Qualified: "Qualified",
  Meetings: "Meetings",
  Customers: "Customers",
  "Last {{count}} hours": "Last {{count}} hours",
  "Last {{count}} days": "Last {{count}} days",
  "Last {{count}} weeks": "Last {{count}} weeks",
  "Last {{count}} months": "Last {{count}} months",
  "Last {{count}} years": "Last {{count}} years",
  "This is the overall performance across all recruiters on your SaaS":
    "This is the overall performance across all recruiters on your SaaS",
  "How your SaaS performed during the last {{segmentNum}} {{timeframeName}}s":
    "How your SaaS performed during the last {{segmentNum}} {{timeframeName}}s",
  "Search Users": "Search Users",
  Sort: "Sort",
  "Newest first": "Newest first",
  "Oldest first": "Oldest first",
  "Recent Activity": "Recent Activity",
  "Oldest Activity": "Oldest Activity",
  Filter: "Filter",
  "All users": "All users",
  "Subscribed Once": "Subscribed Once",
  "Currently subscribed": "Currently subscribed",
  "Currently in trial": "Currently in trial",
  Online: "Online",
  Trial: "Trial",
  "Manual Trial": "Manual Trial",
  "{{current}} / {{max}} Funnels": "{{current}} / {{max}} Funnels",
  "{{count}} Funnels": "{{count}} Funnels",
  "{{current}} / {{max}} Candidates": "{{current}} / {{max}} Candidates",
  "{{count}} Candidates": "{{count}} Candidates",
  "{{current}} / {{max}} Templates": "{{current}} / {{max}} Templates",
  "{{count}} Templates": "{{count}} Templates",
  "{{current}} / {{max}} Team mates": "{{current}} / {{max}} Team mates",
  "{{count}} Team mates": "{{count}} Team mates",
  "Access Allowed": "Access Allowed",
  "Joined: {{date}}": "Joined: {{date}}",
  "Activity: {{date}}": "Activity: {{date}}",
  "Load more": "Load more",
  "Adjusted trial end date": "Adjusted trial end date",
  "Resale Factor for Lead Sourcing": "Resale Factor for Lead Sourcing",
  "By default, all users are granted a standard trial period of {{trialDays}} days as per your SaaS configuration. As a partner, you have the discretion to extend the trial duration for individual users. Set the final date below to determine the length of the extended trial access for the selected user. This date represents the conclusion of the user's extended trial period. if user's extended trial access date is not specified below or has passed, and their account registration exceeds the default {{trialDays}}-day trial period, they will be required to select and purchase a suitable subscription package for continued system access.":
    "By default, all users are granted a standard trial period of {{trialDays}} days as per your SaaS configuration. As a partner, you have the discretion to extend the trial duration for individual users. Set the final date below to determine the length of the extended trial access for the selected user. This date represents the conclusion of the user's extended trial period. if user's extended trial access date is not specified below or has passed, and their account registration exceeds the default {{trialDays}}-day trial period, they will be required to select and purchase a suitable subscription package for continued system access.",
  "This configuration is to decide how many times more this specific user is charged for using the lead sourcing system compared to your cost. Leave it at 0 if the standard configuration from your SaaS Configuration &gt; RC System should apply.":
    "This configuration is to decide how many times more this specific user is charged for using the lead sourcing system compared to your cost. Leave it at 0 if the standard configuration from your SaaS Configuration &gt; RC System should apply.",
  "Phone Call": "Phone Call",
  Lead: "Lead",
  Number: "Number",
  "Copy Number": "Copy Number",
  "Copied to clipboard": "Copied to clipboard",
  "Hang up": "Hang up",
  "Search Team Members": "Search Team Members",
  "Invite Team Member": "Invite Team Member",
  "Access Level": "Access Level",
  "Read Only": "Read Only",
  "Read & Write": "Read & Write",
  "Are you sure to delete this team member?":
    "Are you sure to delete this team member?",
  Firstname: "Firstname",
  Lastname: "Lastname",
  "Send Invite": "Send Invite",
  "User Logs": "User Logs",
  "Lead: {{firstname}} {{lastname}} ({{email}})":
    "Lead: {{firstname}} {{lastname}} ({{email}})",
  "Nothing to show": "Nothing to show",
  "Search Contacts": "Search Contacts",
  "Newest customer": "Newest customer",
  "Oldest customer": "Oldest customer",
  "Best rating": "Best rating",
  "Worst rating": "Worst rating",
  "All leads": "All leads",
  "Meeting Scheduled": "Meeting Scheduled",
  Customer: "Customer",
  Rejected: "Rejected",
  "Your access is read-only": "Your access is read-only",
  "Are you sure to delete the lead?": "Are you sure to delete the lead?",
  "Signed up: {date}": "Signed up: {date}",
  "Closed: {date}": "Closed: {date}",
  "Security Alerts": "Security Alerts",
  "Notifications regarding account security, like login from a new device or location.":
    "Notifications regarding account security, like login from a new device or location.",
  "Platform Updates": "Platform Updates",
  "Information about new features, updates, or maintenance schedules.":
    "Information about new features, updates, or maintenance schedules.",
  Newsletter: "Newsletter",
  "Regular updates on news, insights, and tips from our community.":
    "Regular updates on news, insights, and tips from our community.",
  Support: "Support",
  "Receive updates on your support inquiries.":
    "Receive updates on your support inquiries.",
  "New Lead": "New Lead",
  "Get notified when a new lead has arrived in your funnel.":
    "Get notified when a new lead has arrived in your funnel.",
  "Profile Completion": "Profile Completion",
  "Personal Information": "Personal Information",
  "Use a permanent address where you can receive mail.":
    "Use a permanent address where you can receive mail.",
  Change: "Change",
  "Line 1": "Line 1",
  "Line 2": "Line 2",
  City: "City",
  "State / Province": "State / Province",
  "ZIP / Postal code": "ZIP / Postal code",
  Country: "Country",
  "Privacy Policy Link": "Privacy Policy Link",
  Messaging: "Messaging",
  "Configure the replacements for general messaging variables and general email templates here.":
    "Configure the replacements for general messaging variables and general email templates here.",
  "This is the replacement for the 'User Calendar Link' variable in your messages.":
    "This is the replacement for the 'User Calendar Link' variable in your messages.",
  "User Calendar Link": "User Calendar Link",
  "Company Name": "Company Name",
  "Company Website": "Company Website",
  "Email Template": "Email Template",
  "Ideal Customer Profile": "Ideal Customer Profile",
  "Configure the demographics of your ideal customer profile.":
    "Configure the demographics of your ideal customer profile.",
  "Describe your service details": "Describe your service details",
  Security: "Security",
  "Change your password here.": "Change your password here.",
  "Current password": "Current password",
  "New password": "New password",
  "Repeat new password": "Repeat new password",
  Account: "Account",
  "This is your account information.": "This is your account information.",
  Photo: "Photo",
  "Upload a clean profile avatar to personalize your profile.":
    "Upload a clean profile avatar to personalize your profile.",
  Brand: "Brand",
  "This section is to tailor the branding of your funnel pages, lead-facing materials (such as funnels and surveys).":
    "This section is to tailor the branding of your funnel pages, lead-facing materials (such as funnels and surveys).",
  "Brand Color": "Brand Color",
  Integrations: "Integrations",
  "Manage all of your integrations here.":
    "Manage all of your integrations here.",
  Calendly: "Calendly",
  "Selecting a default event type for interviews will help you to automate your scheduling and let you focus on the conversations that matter.":
    "Selecting a default event type for interviews will help you to automate your scheduling and let you focus on the conversations that matter.",
  Zoom: "Zoom",
  SMTP: "SMTP",
  Notifications: "Notifications",
  "We'll always let you know about important changes, but you pick what else you want to hear about.":
    "We'll always let you know about important changes, but you pick what else you want to hear about.",
  "By Email": "By Email",
  "New email": "New email",
  "your-domain.com": "your-domain.com",
  "UTM Campaign": "UTM Campaign",
  "UTM Source": "UTM Source",
  "UTM Medium": "UTM Medium",
  "UTM Content": "UTM Content",
  "UTM Term": "UTM Term",
  "Salesforce UUID": "Salesforce UUID",
  Back: "Back",
  "Funnel Statistics: {{name}}": "Funnel Statistics: {{name}}",
  Hires: "Hires",
  "Lead Surveys": "Lead Surveys",
  "Min: {{min}} | Max: {{max}}": "Min: {{min}} | Max: {{max}}",
  "Median: {{median}}": "Median: {{median}}",
  "UTM Tracking": "UTM Tracking",
  "No data available": "No data available",
  "How your funnel{{s}} performed during the last {{count}} {{timeframe}}s":
    "How your funnel{{s}} performed during the last {{count}} {{timeframe}}s",
  "WebSocket connection established": "WebSocket connection established",
  "Message Websocket!": "Message Websocket!",
  "Your issue has been marked as resolved":
    "Your issue has been marked as resolved",
  "Websocket error:": "Websocket error:",
  "WebSocket connection closed": "WebSocket connection closed",
  "How can we help you?": "How can we help you?",
  "View notifications": "View notifications",
  "Open user menu": "Open user menu",
  "Open main menu": "Open main menu",
  Dashboard: "Dashboard",
  "Close sidebar": "Close sidebar",
  "Open sidebar": "Open sidebar",
  MENU: "MENU",
  GENERAL: "GENERAL",
  "Include me in BCC": "Include me in BCC",
  Recipient: "Recipient",
  Subject: "Subject",
  "Type # to browse variables": "Type # to browse variables",
  "Possible variables": "Possible variables",
  "Prospect's Firstname": "Prospect's Firstname",
  "Prospect's Lastname": "Prospect's Lastname",
  "Prospect's Email": "Prospect's Email",
  "Company Address": "Company Address",
  "Current Date": "Current Date",
  "Current Time": "Current Time",
  "User Firstname": "User Firstname",
  "User Lastname": "User Lastname",
  "User Email": "User Email",
  "User Phone": "User Phone",
  Message: "Message",
  "AI Outreach": "AI Outreach",
  "Please enter a subject line": "Please enter a subject line",
  "Message cannot be empty": "Message cannot be empty",
  "Full name": "Full name",
  Title: "Title",
  Headline: "Headline",
  "Import into funnel": "Import into funnel",
  "Send email": "Send email",
  "Copy email: {email}": "Copy email: {email}",
  "Click to add email manually": "Click to add email manually",
  "Email Status": "Email Status",
  Verified: "Verified",
  Guessed: "Guessed",
  Unavailable: "Unavailable",
  Bounced: "Bounced",
  "Pending Manual Fulfillment": "Pending Manual Fulfillment",
  "Likely to Reply": "Likely to Reply",
  Yes: "Yes",
  No: "No",
  "Social Links": "Social Links",
  State: "State",
  Company: "Company",
  Departments: "Departments",
  "Sub Departments": "Sub Departments",
  Functions: "Functions",
  Seniority: "Seniority",
  "Delete ({count})": "Delete ({count})",
  "Manual Email Entry": "Manual Email Entry",
  "Error fetching data:": "Error fetching data:",
  "You need to add a payment method": "You need to add a payment method",
  "Partner Rates": "Partner Rates",
  "User Rates": "User Rates",
  "Confirm Fees": "Confirm Fees",
  "I agree to the payment policy": "I agree to the payment policy",
  "Copy email: {{email}}": "Copy email: {{email}}",
  "Click to unlock email": "Click to unlock email",
  "Confirm unlocking this email?": "Confirm unlocking this email?",
  APOLLO_PEOPLE_UPDATE: "APOLLO_PEOPLE_UPDATE",
  "Unable to verify contact.": "Unable to verify contact.",
  "Enter Keywords": "Enter Keywords",
  "Enter Seniority Level": "Enter Seniority Level",
  Owner: "Owner",
  Founder: "Founder",
  "C Suite": "C Suite",
  Partner: "Partner",
  VP: "VP",
  Head: "Head",
  Director: "Director",
  Manager: "Manager",
  Senior: "Senior",
  Entry: "Entry",
  Intern: "Intern",
  "Enter Job Titles": "Enter Job Titles",
  "Enter Organization Headcount": "Enter Organization Headcount",
  "Enter Organization Domains": "Enter Organization Domains",
  "{{count}} Result": "{{count}} Result",
  "in {{duration}} seconds": "in {{duration}} seconds",
  "Lead Sourcing Fees": "Lead Sourcing Fees",
  Understood: "Understood",
  Close: "Close",
  Search: "Search",
  "Bulk Unlock ({{count}})": "Bulk Unlock ({{count}})",
  "You can only unlock up to {{count}} prospects at once":
    "You can only unlock up to {{count}} prospects at once",
  "None of the selected prospects can be unlocked":
    "None of the selected prospects can be unlocked",
  "Unlocked {{count}} new contacts": "Unlocked {{count}} new contacts",
  "Import Prospect": "Import Prospect",
  "Please select a vacancy": "Please select a vacancy",
  "Please select a stage": "Please select a stage",
  "Prospect has been successfully imported":
    "Prospect has been successfully imported",
  "Search Vacancies": "Search Vacancies",
  "Select Stage": "Select Stage",
  "Please write something to copy it": "Please write something to copy it",
  "Outreach text copied to clipboard": "Outreach text copied to clipboard",
  "To explore available messaging variables, simply type '#' followed by your keyword, and a list of suggestions will appear for your convenience.":
    "To explore available messaging variables, simply type '#' followed by your keyword, and a list of suggestions will appear for your convenience.",
  "Import Leads Into Queue": "Import Leads Into Queue",
  "Please check and verify the data": "Please check and verify the data",
  Tags: "Tags",
  "Enter Tags": "Enter Tags",
  "Lead Type": "Lead Type",
  "Enter Lead Type": "Enter Lead Type",
  "LinkedIn URL": "LinkedIn URL",
  "Remove Dupes": "Remove Dupes",
  "Column of Imported File": "Column of Imported File",
  "Target Column": "Target Column",
  "Please select a mapping column for email":
    "Please select a mapping column for email",
  "Please select a mapping column for firstname or fullname":
    "Please select a mapping column for firstname or fullname",
  "Please select a mapping column for lastname or fullname":
    "Please select a mapping column for lastname or fullname",
  "Only partners": "Only partners",
  "Only recruiters": "Only recruiters",
  Joined: "Joined",
  "Do MMM, YYYY, HH:mm": "Do MMM, YYYY, HH:mm",
  Activity: "Activity",
  "Negative Keywords": "Negative Keywords",
  "Positive Keywords": "Positive Keywords",
  "Number of Leads": "Number of Leads",
  "Enter Number of Leads": "Enter Number of Leads",
  Insert: "Insert",
  Vacancies: "Vacancies",
  "Active Automations": "Active Automations",
  "{{count}} per day, {{type}}, {{stage}}":
    "{{count}} per day, {{type}}, {{stage}}",
  Positive: "Positive",
  Negative: "Negative",
  "Are you sure to delete the automation?":
    "Are you sure to delete the automation?",
  "Scheduled Meeting Details": "Scheduled Meeting Details",
  "Meeting Start Time": "Meeting Start Time",
  "Meeting End Time": "Meeting End Time",
  "Join Meeting": "Join Meeting",
  "Reschedule Event": "Reschedule Event",
  "Cancel Event": "Cancel Event",
  Template: "Template",
  "Sure to delete?": "Sure to delete?",
  "Save Current": "Save Current",
  "Lead's Firstname": "Lead's Firstname",
  "Lead's Lastname": "Lead's Lastname",
  "Lead's Email": "Lead's Email",
  "Lead's Phone": "Lead's Phone",
  "Job Title": "Job Title",
  "Job Location": "Job Location",
  "Job Application Link": "Job Application Link",
  "Interview Date": "Interview Date",
  "Interview Time": "Interview Time",
  "Interview Ending Date": "Interview Ending Date",
  "Interview Ending Time": "Interview Ending Time",
  "Interview Meeting Link": "Interview Meeting Link",
  "Calendly Cancel URL": "Calendly Cancel URL",
  "Calendly Reschedule URL": "Calendly Reschedule URL",
  "Application Status": "Application Status",
  "Rejection Reason": "Rejection Reason",
  "CV Submission Link": "CV Submission Link",
  "Survey Link": "Survey Link",
  "Interview Remaining Time": "Interview Remaining Time",
  "Message must include the variable Calendar Link":
    "Message must include the variable Calendar Link",
  "The meeting previously arranged has been cancelled{{cancelAt}}{{cancelledBy}}{{cancelReason}}":
    "The meeting previously arranged has been cancelled{{cancelAt}}{{cancelledBy}}{{cancelReason}}",
  "Empty rejection reason cannot need to be stored as a template":
    "Empty rejection reason cannot need to be stored as a template",
  "Provide a reason on why the lead was not a great fit. This text can be used in your email automations as a variable.":
    "Provide a reason on why the lead was not a great fit. This text can be used in your email automations as a variable.",
  "The rejection reason entered will be saved as a lead variable. No automated communication will occur without a custom workflow setup by you. For instance, a workflow could move leads to a 'unqualified' stage and trigger an email with the stored rejection reason. This process is entirely optional and customizable.":
    "The rejection reason entered will be saved as a lead variable. No automated communication will occur without a custom workflow setup by you. For instance, a workflow could move leads to a 'unqualified' stage and trigger an email with the stored rejection reason. This process is entirely optional and customizable.",
  "Enable Interview Confirmations": "Enable Interview Confirmations",
  "Message must include the variable Interview Meeting Link":
    "Message must include the variable Interview Meeting Link",
  "Customize your booking confirmation message here. Include essential details like the meeting link, date, and time. Save your template to ensure leads receive immediate confirmation upon booking.":
    "Customize your booking confirmation message here. Include essential details like the meeting link, date, and time. Save your template to ensure leads receive immediate confirmation upon booking.",
  "SMS cannot be empty": "SMS cannot be empty",
  "Toggle Image Position": "Toggle Image Position",
  Funnels: "Funnels",
  "Funnel Steps": "Funnel Steps",
  Reorder: "Reorder",
  "Create Funnel": "Create Funnel",
  "chat-user": "chat-user",
  "Support inquiry": "Support inquiry",
  "Are you sure to close this ticket?": "Are you sure to close this ticket?",
  Solved: "Solved",
  "Type a message...": "Type a message...",
  "Confirm Deletion": "Confirm Deletion",
  "Please confirm the deletion of this funnel.":
    "Please confirm the deletion of this funnel.",
  DELETE: "DELETE",
  "Search Campaigns": "Search Campaigns",
  "New Ad Campaign {{date}}": "New Ad Campaign {{date}}",
  "Create New Campaign": "Create New Campaign",
  New: "New",
  "Change funnel name": "Change funnel name",
  Unspecified: "Unspecified",
  Publish: "Publish",
  Stats: "Stats",
  Impressions: "Impressions",
  Spend: "Spend",
  "Cost Per Click": "Cost Per Click",
  CPM: "CPM",
  "Click Through Rate": "Click Through Rate",
  Reach: "Reach",
  Frequency: "Frequency",
  "First Delivery": "First Delivery",
  "Last Delivery": "Last Delivery",
  "Number of times users that scrolled past your ad":
    "Number of times users that scrolled past your ad",
  "Number of users that clicked on your ad":
    "Number of users that clicked on your ad",
  "Total Cost": "Total Cost",
  "Cost Per 1000 Impressions": "Cost Per 1000 Impressions",
  "Ratio of users clicking on your ad to the total users scrolling past your ad":
    "Ratio of users clicking on your ad to the total users scrolling past your ad",
  "Total amount of unique users that have seen your ad":
    "Total amount of unique users that have seen your ad",
  "Number of times your ad shows up per user on average":
    "Number of times your ad shows up per user on average",
  "Number of visitors that have converted to a lead":
    "Number of visitors that have converted to a lead",
  "Date of the first delivery of your ad":
    "Date of the first delivery of your ad",
  "Date of the last delivery of your ad":
    "Date of the last delivery of your ad",
  "Your ad has not yet been delivered to the target audience. You will see the statistics here as soon as your ad starts to deliver.":
    "Your ad has not yet been delivered to the target audience. You will see the statistics here as soon as your ad starts to deliver.",
  Metric: "Metric",
  Value: "Value",
  "Ad Campaign Statistics": "Ad Campaign Statistics",
  "Task Description": "Task Description",
  "Describe the task of your call agent":
    "Describe the task of your call agent",
  "Enter task description": "Enter task description",
  "Call Configuration": "Call Configuration",
  Speed: "Speed",
  Slow: "Slow",
  Normal: "Normal",
  Fast: "Fast",
  "Ultra Fast": "Ultra Fast",
  "Select the speed of speech": "Select the speed of speech",
  Language: "Language",
  "American English": "American English",
  "British English": "British English",
  Dutch: "Dutch",
  French: "French",
  German: "German",
  Italian: "Italian",
  Japanese: "Japanese",
  Polish: "Polish",
  Portuguese: "Portuguese",
  Russian: "Russian",
  Spanish: "Spanish",
  Swedish: "Swedish",
  Turkish: "Turkish",
  Chinese: "Chinese",
  "Select the language": "Select the language",
  "Maximum Call Duration": "Maximum Call Duration",
  "Record Call": "Record Call",
  "General Notes": "General Notes",
  Notes: "Notes",
  at: "at",
  "Are you sure to delete?": "Are you sure to delete?",
  Update: "Update",
  "Insert Note": "Insert Note",
  "Name of the campaign": "Name of the campaign",
  "Enter campaign name": "Enter campaign name",
  "Optimization Focus": "Optimization Focus",
  "Select optimization focus": "Select optimization focus",
  Sales: "Sales",
  "Monthly Budget (Local Currency)": "Monthly Budget (Local Currency)",
  "Enter monthly budget": "Enter monthly budget",
  Copy: "Copy",
  "Primary Text": "Primary Text",
  "Enter primary text": "Enter primary text",
  "Enter headline": "Enter headline",
  Description: "Description",
  "Enter description": "Enter description",
  Linking: "Linking",
  "Please link your Meta account": "Please link your Meta account",
  "Meta Ads Linking": "Meta Ads Linking",
  "Failed to initiate linking process": "Failed to initiate linking process",
  "Link Meta Ads Account": "Link Meta Ads Account",
  "Enter Your Meta Pixel Code To Enable Conversion Tracking":
    "Enter Your Meta Pixel Code To Enable Conversion Tracking",
  "Google Ads Linking": "Google Ads Linking",
  "Link Google Ads Account": "Link Google Ads Account",
  "Functionality Selection": "Functionality Selection",
  Functionality: "Functionality",
  "Get full functionality including ad optimization":
    "Get full functionality including ad optimization",
  "Only reporting functionality": "Only reporting functionality",
  "Optimisation Period": "Optimisation Period",
  "Target Funnel": "Target Funnel",
  "Select target funnel": "Select target funnel",
  "Please enter some text to add.": "Please enter some text to add.",
  "No caption selected to delete.": "No caption selected to delete.",
  "Enter caption text": "Enter caption text",
  "Add Text": "Add Text",
  "Delete Selection": "Delete Selection",
  "At least 1 image is required": "At least 1 image is required",
  "Maximum 5 creatives are allowed": "Maximum 5 creatives are allowed",
  "Failed to upload image": "Failed to upload image",
  "Generate New Image": "Generate New Image",
  "Upload Image": "Upload Image",
  "Are you sure you want to delete this image?":
    "Are you sure you want to delete this image?",
  "Are you sure you want to publish the campaign?":
    "Are you sure you want to publish the campaign?",
  "Publish Campaign": "Publish Campaign",
  "Describe your business...": "Describe your business...",
  Generate: "Generate",
  Saved: "Saved",
  Body: "Body",
  Delay: "Delay",
  "+ Add Message": "+ Add Message",
  "You have the option to set up a series of automated messages for leads who remain inactive at this stage. Each message will be dispatched if a lead's status remains unchanged in this particular stage for a duration exceeding the time limit you specify in hours. This feature is to enable consistent communication and engagement with leads throughout the marketing pipeline.":
    "You have the option to set up a series of automated messages for leads who remain inactive at this stage. Each message will be dispatched if a lead's status remains unchanged in this particular stage for a duration exceeding the time limit you specify in hours. This feature is to enable consistent communication and engagement with leads throughout the marketing pipeline.",
  "This message will be sent {{hours}} hour(s) after lead stays idle in this stage.":
    "This message will be sent {{hours}} hour(s) after lead stays idle in this stage.",
  "Optins (Leads)": "Optins (Leads)",
  "New Leads (Optin)": "New Leads (Optin)",
  "{{label}}/ Optin": "{{label}}/ Optin",
  Refresh: "Refresh",
  Engaged: "Engaged",
  Nurtured: "Nurtured",
  Webinar: "Webinar",
  Converted: "Converted",
  Lost: "Lost",
  "No data available for the selected timeframe.":
    "No data available for the selected timeframe.",
  "Meta Ads": "Meta Ads",
  "Ad Spent / Conversion": "Ad Spent / Conversion",
  "Google Ads": "Google Ads",
  "Funnel Library": "Funnel Library",
  Marketing: "Marketing",
  Partnership: "Partnership",
  "Confirm Overwrite": "Confirm Overwrite",
  "By applying this template, your current funnel configuration including stages, automated emails, and workflow settings will be replaced. All leads will be moved to the 'Uncategorized' column. Are you sure you want to proceed?":
    "By applying this template, your current funnel configuration including stages, automated emails, and workflow settings will be replaced. All leads will be moved to the 'Uncategorized' column. Are you sure you want to proceed?",
  Continue: "Continue",
  "The funnel template library is a curated collection of pre-built funnel configurations designed to streamline and standardize processes such as sales, marketing, or partnership engagement. It offers a range of funnel templates that encapsulate best practices and successful strategies, allowing for quick implementation and ensuring consistent approaches across different scenarios.":
    "The funnel template library is a curated collection of pre-built funnel configurations designed to streamline and standardize processes such as sales, marketing, or partnership engagement. It offers a range of funnel templates that encapsulate best practices and successful strategies, allowing for quick implementation and ensuring consistent approaches across different scenarios.",
  "Email Library": "Email Library",
  "All copies": "All copies",
  Membership: "Membership",
  Event: "Event",
  Feedback: "Feedback",
  Freebie: "Freebie",
  Meeting: "Meeting",
  "Recruiter Prospecting": "Recruiter Prospecting",
  "Back to Funnels": "Back to Funnels",
  "Email Sequencing": "Email Sequencing",
  EEODC: "EEODC",
  "EEO Data Collection": "EEO Data Collection",
  "The following questions are part of our commitment to ensuring equal opportunities. Participation in this data collection is entirely voluntary, and all questions are optional. Your responses will not influence any individual hiring decisions. Instead, the data collected will be aggregated across multiple participants. This process is designed exclusively for our internal use, to monitor our progress towards achieving specific goals related to ensuring equal employment opportunities. Should you prefer not to respond to any query, please feel free to proceed to the next section of the form.":
    "The following questions are part of our commitment to ensuring equal opportunities. Participation in this data collection is entirely voluntary, and all questions are optional. Your responses will not influence any individual hiring decisions. Instead, the data collected will be aggregated across multiple participants. This process is designed exclusively for our internal use, to monitor our progress towards achieving specific goals related to ensuring equal employment opportunities. Should you prefer not to respond to any query, please feel free to proceed to the next section of the form.",
  "Contact Information": "Contact Information",
  "Frequently Asked Questions": "Frequently Asked Questions",
  "+ {{teamPrice}} per team mate": "+ {{teamPrice}} per team mate",
  "Get started with the {{name}} plan for {{price}}":
    "Get started with the {{name}} plan for {{price}}",
  Pricing: "Pricing",
  Plan: "Plan",
  "Basic analytics": "Basic analytics",
  "Email support": "Email support",
  "Enhanced analytics": "Enhanced analytics",
  "Priority support": "Priority support",
  "Dedicated account manager": "Dedicated account manager",
  "quick links": "quick links",
  "Consent Preferences": "Consent Preferences",
  DSAR: "DSAR",
  "Do Not Sell or Share My Personal information":
    "Do Not Sell or Share My Personal information",
  "Limit the Use Of My Sensitive Personal Information":
    "Limit the Use Of My Sensitive Personal Information",
  "Copyright © {{year}} {{brandName}}. All rights reserved.":
    "Copyright © {{year}} {{brandName}}. All rights reserved.",
  "AAWAI Corporation": "AAWAI Corporation",
  "5-15-10-902, Yoyogi, Shibuya, Tokyo, Japan":
    "5-15-10-902, Yoyogi, Shibuya, Tokyo, Japan",
  "Done! Here is the link: {{link}}": "Done! Here is the link: {{link}}",
  "Upload Files": "Upload Files",
  "Uploaded image": "Uploaded image",
  "Search Leads": "Search Leads",
  "Preview Landing Page": "Preview Landing Page",
  "Hide Rejected": "Hide Rejected",
  "Cleanup Rejected": "Cleanup Rejected",
  "Please close the active tab": "Please close the active tab",
  "This column cannot be moved": "This column cannot be moved",
  "Cannot move lead into uncategorized": "Cannot move lead into uncategorized",
  "Not so quick! Try again in a few seconds.":
    "Not so quick! Try again in a few seconds.",
  "LinkedIn URL (optional)": "LinkedIn URL (optional)",
  "This will unrecoverably delete all leads that are marked as unqualified in this funnel":
    "This will unrecoverably delete all leads that are marked as unqualified in this funnel",
  "This column cannot be removed. You need to categorize or delete all of the leads.":
    "This column cannot be removed. You need to categorize or delete all of the leads.",
  "You are about to delete a stage (column). All of the corresponding applicants will be safely moved to the uncategorized column.":
    "You are about to delete a stage (column). All of the corresponding applicants will be safely moved to the uncategorized column.",
  "Hire Fast": "Hire Fast",
  "Promote for a day": "Promote for a day",
  "Hire Better": "Hire Better",
  "Promote for a week": "Promote for a week",
  "Hire Best": "Hire Best",
  "Promote for 2 weeks": "Promote for 2 weeks",
  "10-100 leads": "10-100 leads",
  "Live for 1 days": "Live for 1 days",
  "Fill the funnel fast": "Fill the funnel fast",
  "100-1000 leads": "100-1000 leads",
  "Live for 7 days": "Live for 7 days",
  "Find a great fit for the position": "Find a great fit for the position",
  "600-2000 leads": "600-2000 leads",
  "Live for 14 days": "Live for 14 days",
  "Find the best fit for the position": "Find the best fit for the position",
  "Search Programs": "Search Programs",
  Preview: "Preview",
  LTS: "LTS",
  Ads: "Ads",
  "Are you sure to duplicate this funnel?":
    "Are you sure to duplicate this funnel?",
  "Duplicate Funnel": "Duplicate Funnel",
  Duplicate: "Duplicate",
  "Promote now": "Promote now",
  "Are you sure to promote? You will be charged ${{price}}.":
    "Are you sure to promote? You will be charged ${{price}}.",
  "General Information": "General Information",
  "Job name": "Job name",
  Dentist: "Dentist",
  "Scroll stopper": "Scroll stopper",
  "Enter the scroll stopper text": "Enter the scroll stopper text",
  "Hero title": "Hero title",
  "Enter title on top of your funnel": "Enter title on top of your funnel",
  Requirements: "Requirements",
  "Enter the required skills": "Enter the required skills",
  "Preferred Work Location": "Preferred Work Location",
  "Enter your preferred work location": "Enter your preferred work location",
  "Engagement Type": "Engagement Type",
  Permanent: "Permanent",
  Temporary: "Temporary",
  "Fixed-Term": "Fixed-Term",
  Freelance: "Freelance",
  "Project-based": "Project-based",
  Retainer: "Retainer",
  Seasonal: "Seasonal",
  Internship: "Internship",
  Apprenticeship: "Apprenticeship",
  "Commission-Based": "Commission-Based",
  "Contract-to-Hire": "Contract-to-Hire",
  "Contract Type": "Contract Type",
  "Full-time": "Full-time",
  "Part-time": "Part-time",
  Casual: "Casual",
  "Zero-Hours": "Zero-Hours",
  "Profit-share": "Profit-share",
  "Calendly Event Type": "Calendly Event Type",
  "Benefits & Culture": "Benefits & Culture",
  "Benefits Title": "Benefits Title",
  "Title for Benefits Section": "Title for Benefits Section",
  "Benefits Description": "Benefits Description",
  "Describe the benefits of working with us":
    "Describe the benefits of working with us",
  Benefits: "Benefits",
  "Title of this benefit": "Title of this benefit",
  "Description of this benefit": "Description of this benefit",
  Icon: "Icon",
  Emoji: "Emoji",
  Testimonials: "Testimonials",
  "Testimonial Section Title": "Testimonial Section Title",
  "Enter the title for the testimonial section":
    "Enter the title for the testimonial section",
  Fullname: "Fullname",
  "Fullname of the team member": "Fullname of the team member",
  "The position of the team member": "The position of the team member",
  Testimonial: "Testimonial",
  "Application Submission": "Application Submission",
  "Call to Action": "Call to Action",
  "Call to action for application submission":
    "Call to action for application submission",
  "Post-Application": "Post-Application",
  "Thank You Message": "Thank You Message",
  "Enter the thank you message post-application":
    "Enter the thank you message post-application",
  "Application Under Review Information":
    "Application Under Review Information",
  "Information about the application review process":
    "Information about the application review process",
  "Next Steps After Application": "Next Steps After Application",
  "We are hiring {{jobName}}": "We are hiring {{jobName}}",
  "Specify a unique Calendly event type for each funnel, or leave blank to use the default account setting for interviews.":
    "Specify a unique Calendly event type for each funnel, or leave blank to use the default account setting for interviews.",
  "What does the team member think about the company?":
    "What does the team member think about the company?",
  "Describe the next steps after the application is submitted":
    "Describe the next steps after the application is submitted",
  "Preview Your Form": "Preview Your Form",
  "View a lead's perspective of the form on the left.":
    "View a lead's perspective of the form on the left.",
  "Customize Steps": "Customize Steps",
  "Publish Funnel": "Publish Funnel",
  "Ready to go live? Publish your funnel here.":
    "Ready to go live? Publish your funnel here.",
  "Edit Funnel Settings": "Edit Funnel Settings",
  "Back to funnel settings": "Back to funnel settings",
  "On the right, edit steps or form elements within each step.":
    "On the right, edit steps or form elements within each step.",
  "Business setup": "Business setup",
  "Create your first funnel": "Create your first funnel",
  "Setup your target customer": "Setup your target customer",
  "Connect your email sender": "Connect your email sender",
  "Configure your first funnel": "Configure your first funnel",
  "Setup your advertisement configuration":
    "Setup your advertisement configuration",
  "Create your first ad": "Create your first ad",
  "Welcome, {{name}}...": "Welcome, {{name}}...",
  "Your next steps": "Your next steps",
  "{{completed}} of {{total}}": "{{completed}} of {{total}}",
  "Setup Target Customer": "Setup Target Customer",
  "Setup Advertisement Configuration": "Setup Advertisement Configuration",
  "Catch Copy": "Catch Copy",
  "Automate all your sales for free!": "Automate all your sales for free!",
  "Ad Size (px x px)": "Ad Size (px x px)",
  "Select Brand Tone of Voice": "Select Brand Tone of Voice",
  "Select your brand color:": "Select your brand color:",
  Other: "Other",
  "Friendly & Conversational": "Friendly & Conversational",
  "Professional & Authoritative": "Professional & Authoritative",
  "Innovative & Inspirational": "Innovative & Inspirational",
  "Quirky & Humorous": "Quirky & Humorous",
  "General Workflow Configuration": "General Workflow Configuration",
  "When a lead applies, automatically assign them to the stage":
    "When a lead applies, automatically assign them to the stage",
  None: "None",
  "Workflow Configuration for": "Workflow Configuration for",
  "When lead enters stage {{stageName}}, send them":
    "When lead enters stage {{stageName}}, send them",
  "an automated email": "an automated email",
  "Change Email Template": "Change Email Template",
  "an automated SMS": "an automated SMS",
  "Change SMS Template": "Change SMS Template",
  "an AI call": "an AI call",
  "Change Call Script": "Change Call Script",
  "Webhook URL": "Webhook URL",
  "Webhook URL successfully updated": "Webhook URL successfully updated",
  "When lead enters stage {{stageName}}, mark them as":
    "When lead enters stage {{stageName}}, mark them as",
  engaged: "engaged",
  nurtured: "nurtured",
  "not nurtured": "not nurtured",
  "scheduled for sales call": "scheduled for sales call",
  "not scheduled for sales call": "not scheduled for sales call",
  converted: "converted",
  lost: "lost",
  Days: "Days",
  Hours: "Hours",
  Minutes: "Minutes",
  "{{days}} day(s)": "{{days}} day(s)",
  "{{hours}} hour(s)": "{{hours}} hour(s)",
  "{{minutes}} minute(s)": "{{minutes}} minute(s)",
  "When you send a meeting request to lead, automatically assign them to the stage":
    "When you send a meeting request to lead, automatically assign them to the stage",
  "When lead scheduled a meeting, automatically assign them to the stage":
    "When lead scheduled a meeting, automatically assign them to the stage",
  "When a scheduled meeting has been cancelled, automatically assign lead to the stage":
    "When a scheduled meeting has been cancelled, automatically assign lead to the stage",
  "When I mark lead as unqualified, automatically assign them to the stage":
    "When I mark lead as unqualified, automatically assign them to the stage",
  "When lead enters stage {{stageName}}, push lead data into an external webhook":
    "When lead enters stage {{stageName}}, push lead data into an external webhook",
  "When leads stay too long in stage {{stageName}}, move them into":
    "When leads stay too long in stage {{stageName}}, move them into",
  "Candidates will be moved after {{time}} of idle time waiting in this stage.":
    "Candidates will be moved after {{time}} of idle time waiting in this stage.",
  "No file selected.": "No file selected.",
  Timestamp: "Timestamp",
  Recording: "Recording",
  "Lead Folder": "Lead Folder",
  "AI Call Results": "AI Call Results",
  "Interview Notes": "Interview Notes",
  Summary: "Summary",
  Transcript: "Transcript",
  "No logs": "No logs",
  "Edit Contact": "Edit Contact",
  "Application Form": "Application Form",
  EEO_DATA_COLLECTION_MESSAGE: "EEO_DATA_COLLECTION_MESSAGE",
  "Lead has not submitted the application form":
    "Lead has not submitted the application form",
  "Email History": "Email History",
  System: "System",
  "Lead did not receive any emails from you":
    "Lead did not receive any emails from you",
  "SMS History": "SMS History",
  "Lead did not receive any SMS messages from you":
    "Lead did not receive any SMS messages from you",
  Tracking: "Tracking",
  Logs: "Logs",
  "Performed by": "Performed by",
  "This lead has been marked as unqualified. Provided reason: {{reason}}":
    "This lead has been marked as unqualified. Provided reason: {{reason}}",
  "Failed to fetch triggers": "Failed to fetch triggers",
  "Trigger added successfully": "Trigger added successfully",
  "Failed to add trigger": "Failed to add trigger",
  "Email template saved successfully": "Email template saved successfully",
  "Failed to save email template": "Failed to save email template",
  "Trigger deleted successfully": "Trigger deleted successfully",
  "Failed to delete trigger": "Failed to delete trigger",
  "Add New Trigger": "Add New Trigger",
  "Are you sure you want to delete this trigger?":
    "Are you sure you want to delete this trigger?",
  "Trigger Type": "Trigger Type",
  "Please select a trigger type": "Please select a trigger type",
  "Select a trigger type": "Select a trigger type",
  Optin: "Optin",
  Engagement: "Engagement",
  Appointment: "Appointment",
  "Add Trigger": "Add Trigger",
  "Edit Email Template": "Edit Email Template",
  immediately: "immediately",
  "Leads will receive the message {{timing}} after trigger.":
    "Leads will receive the message {{timing}} after trigger.",
  "after {{days}} day(s) {{hours}} hour(s) {{minutes}} minute(s)":
    "after {{days}} day(s) {{hours}} hour(s) {{minutes}} minute(s)",
  "Page #{{number}}": "Page #{{number}}",
  "Select the step to forward user into after form submission":
    "Select the step to forward user into after form submission",
  Open: "Open",
  "Clear Selection": "Clear Selection",
  "Add Step": "Add Step",
  Typography: "Typography",
  Borders: "Borders",
  "Spacing and Sizing": "Spacing and Sizing",
  "Flexbox & Grid": "Flexbox & Grid",
  Background: "Background",
  "Font Family": "Font Family",
  "Font Size": "Font Size",
  "Font Style": "Font Style",
  "Font Weight": "Font Weight",
  "Letter Spacing": "Letter Spacing",
  "Line Height": "Line Height",
  "Text Align": "Text Align",
  "Text Color": "Text Color",
  "Text Decoration": "Text Decoration",
  "Text Transform": "Text Transform",
  "Text Overflow": "Text Overflow",
  "Text Indent": "Text Indent",
  Whitespace: "Whitespace",
  "Word Break": "Word Break",
  "Border Radius": "Border Radius",
  "Border Width": "Border Width",
  "Border Style": "Border Style",
  "Border Color": "Border Color",
  Width: "Width",
  Height: "Height",
  "Minimum Width": "Minimum Width",
  "Maximum Width": "Maximum Width",
  "Minimum Height": "Minimum Height",
  "Maximum Height": "Maximum Height",
  "Flex Basis": "Flex Basis",
  "Flex Direction": "Flex Direction",
  "Flex Wrap": "Flex Wrap",
  Flex: "Flex",
  "Flex Grow": "Flex Grow",
  "Flex Shrink": "Flex Shrink",
  Order: "Order",
  "Grid Template Columns": "Grid Template Columns",
  "Grid Column Start / End": "Grid Column Start / End",
  "Grid Template Rows": "Grid Template Rows",
  "Grid Row Start / End": "Grid Row Start / End",
  "Grid Auto Flow": "Grid Auto Flow",
  "Grid Auto Columns": "Grid Auto Columns",
  "Grid Auto Rows": "Grid Auto Rows",
  Gap: "Gap",
  "Justify Content": "Justify Content",
  "Justify Items": "Justify Items",
  "Justify Self": "Justify Self",
  "Align Content": "Align Content",
  "Align Items": "Align Items",
  "Align Self": "Align Self",
  "Place Content": "Place Content",
  "Place Items": "Place Items",
  "Place Self": "Place Self",
  "Background Attachment": "Background Attachment",
  "Background Clip": "Background Clip",
  "Background Color": "Background Color",
  "Text Size": "Text Size",
  "Background Origin": "Background Origin",
  "Background Position": "Background Position",
  "Background Repeat": "Background Repeat",
  "Background Size": "Background Size",
  "Background Image": "Background Image",
  Layout: "Layout",
  Direction: "Direction",
  Row: "Row",
  "Row Reverse": "Row Reverse",
  Column: "Column",
  "Column Reverse": "Column Reverse",
  Justify: "Justify",
  Start: "Start",
  Center: "Center",
  End: "End",
  Between: "Between",
  Around: "Around",
  Evenly: "Evenly",
  Align: "Align",
  Baseline: "Baseline",
  Stretch: "Stretch",
  "Hide Advanced Options": "Hide Advanced Options",
  "Show Advanced Options": "Show Advanced Options",
  "Image Attributes": "Image Attributes",
  "Image Width": "Image Width",
  "Image Height": "Image Height",
  Text: "Text",
  "Text Content": "Text Content",
  "Tailwind CSS Class": "Tailwind CSS Class",
  "New Text": "New Text",
  "Nothing to duplicate within this component":
    "Nothing to duplicate within this component",
  Image: "Image",
  Component: "Component",
  "Edit Component": "Edit Component",
  "Insufficient leads in queue": "Insufficient leads in queue",
  "Stage not found": "Stage not found",
  "Partner or user not found": "Partner or user not found",
  "Unable to access partner": "Unable to access partner",
  "Partner is not activated": "Partner is not activated",
  "Unable to access partner DB": "Unable to access partner DB",
  "Partner Stripe is not setup": "Partner Stripe is not setup",
  "You need to add a payment method": "You need to add a payment method",
  "Your access is read-only": "Your access is read-only",
  "Please provide a prospect": "Please provide a prospect",
  "Prospect does not have a valid email":
    "Prospect does not have a valid email",
  "Subject line is too long": "Subject line is too long",
  "Vacancy not found": "Vacancy not found",
  "As a team member, you cannot promote funnels":
    "As a team member, you cannot promote funnels",
  "Please specify the funnel": "Please specify the funnel",
  "Feature is disabled": "Feature is disabled",
  "Funnel not found": "Funnel not found",
  "There is already an active promotion for this funnel":
    "There is already an active promotion for this funnel",
  "Tier not found": "Tier not found",
  "Partner stripe is not configured": "Partner stripe is not configured",
  "Vacancy does not exist": "Vacancy does not exist",
  "Template not found": "Template not found",
  "Please provide the candidateId": "Please provide the candidateId",
  "Unable to find the lead": "Unable to find the lead",
  "Invalid lead ID": "Invalid lead ID",
  "Out of service": "Out of service",
  "Password must contain minimum 8 characters":
    "Password must contain minimum 8 characters",
  "Master user not found": "Master user not found",
  "Subscription is required (tier)": "Subscription is required (tier)",
  "Subscription is required (id)": "Subscription is required (id)",
  "Subscription not found": "Subscription not found",
  "Subscription item is not found": "Subscription item is not found",
  "Interval is not specified": "Interval is not specified",
  "Pricing specification not found": "Pricing specification not found",
  "Invalid email or password": "Invalid email or password",
  Unable: "Unable",
  "Your latest OTP is not expired yet": "Your latest OTP is not expired yet",
  "Invalid OTP": "Invalid OTP",
  "Wrong password": "Wrong password",
  "Repeated password does not match": "Repeated password does not match",
  "No access": "No access",
  "Minimum Purchase Amount must be $10 or higher":
    "Minimum Purchase Amount must be $10 or higher",
  "Resale factor for lead sourcing must be 1.5 or higher":
    "Resale factor for lead sourcing must be 1.5 or higher",
  "User not found": "User not found",
  "Wrong code": "Wrong code",
  "Your code has expired": "Your code has expired",
  "As a team member, you cannot perform this action":
    "As a team member, you cannot perform this action",
  "No subscription to cancel": "No subscription to cancel",
  "Please provide a valid email": "Please provide a valid email",
  "Email is already in use": "Email is already in use",
  "Invalid password": "Invalid password",
  "This service is currently not available":
    "This service is currently not available",
  "Calendly is currently disabled": "Calendly is currently disabled",
  "Super admin not found": "Super admin not found",
  "Invalid user role": "Invalid user role",
  "User has no access token": "User has no access token",
  "Could not upload the image": "Could not upload the image",
  "Access blocked!": "Access blocked!",
  "Invalid link": "Invalid link",
  "CV not found": "CV not found",
  "No active customer account found": "No active customer account found",
  "Either locationIds or vacancyLocation must be provided":
    "Either locationIds or vacancyLocation must be provided",
  "descriptions must be provided": "descriptions must be provided",
  "headlines must be provided": "headlines must be provided",
  "keywords must be provided": "keywords must be provided",
  "Maximum length for path is 15 characters":
    "Maximum length for path is 15 characters",
  "Please ensure all of the provided final URLs are valid":
    "Please ensure all of the provided final URLs are valid",
  "Google connection has expired": "Google connection has expired",
  "You must create a customer account in Google Ads":
    "You must create a customer account in Google Ads",
  "Lead not found": "Lead not found",
  "Lead does not have phone number": "Lead does not have phone number",
  "Lead does not have email": "Lead does not have email",
  "Invalid candidate ID": "Invalid candidate ID",
  "Already published": "Already published",
  "Domain already registered": "Domain already registered",
  "This link has expired": "This link has expired",
  "Calendar connection not available": "Calendar connection not available",
  "Partner not found": "Partner not found",
  "You have already signed": "You have already signed",
  Unauthorized: "Unauthorized",
  "SMTP not specified": "SMTP not specified",
  "Please provide an email": "Please provide an email",
  "Firstname is required": "Firstname is required",
  "Lastname is required": "Lastname is required",
  "This email is not available": "This email is not available",
  "Ad creative is required": "Ad creative is required",
  "Access blocked!!": "Access blocked!!",
  "Calendly is not connected": "Calendly is not connected",
  "Not connected": "Not connected",
  "SMS has been sent successfully": "SMS has been sent successfully",
  "Email has been sent successfully": "Email has been sent successfully",
  "Successfully forwarded the resumes": "Successfully forwarded the resumes",
  "Lead has scheduled a meeting": "Lead has scheduled a meeting",
  "Sent calendar scheduling request to candidate":
    "Sent calendar scheduling request to candidate",
  "Sent calendar scheduling request to lead":
    "Sent calendar scheduling request to lead",
  "You are not at the required job location":
    "You are not at the required job location",
  "Your skill set does not match our current needs":
    "Your skill set does not match our current needs",
  "Your application did not show the required level of expertise":
    "Your application did not show the required level of expertise",
  "We require more proficiency in certain software or tools":
    "We require more proficiency in certain software or tools",
  "We are seeking a lead with a different educational background.":
    "We are seeking a lead with a different educational background.",
  "We need a lead who is available to work different hours":
    "We need a lead who is available to work different hours",
  "Your salary expectations exceed the budget for this position":
    "Your salary expectations exceed the budget for this position",
  "We need someone with more experience in a similar role":
    "We need someone with more experience in a similar role",
  "We are looking for a lead who has a more extensive portfolio.":
    "We are looking for a lead who has a more extensive portfolio.",
  "We require a lead with a longer commitment to the next role.":
    "We require a lead with a longer commitment to the next role.",
  "Deleted successfully": "Deleted successfully",
  "Deleted successfully": "Deleted successfully",
  "Lead updated successfully": "Lead updated successfully",
  "You need to put your payment details on file":
    "You need to put your payment details on file",
  "Funnel has been successfully promoted":
    "Funnel has been successfully promoted",
  "CV has been submit manually": "CV has been submit manually",
  "Welcome aboard!": "Welcome aboard!",
  "Please check your email inbox": "Please check your email inbox",
  "Password reset successfully": "Password reset successfully",
  "Password changed successfully": "Password changed successfully",
  "Upgrade successful": "Upgrade successful",
  "Upgrade successful": "Upgrade successful",
  "Email has been updated": "Email has been updated",
  "Ticket closed": "Ticket closed",
  "Permission denied": "Permission denied",
  "CV has been submit by candidate": "CV has been submit by candidate",
  "CV has been successfully forwarded to the hiring manager":
    "CV has been successfully forwarded to the hiring manager",
  "Error getting user statistics": "Error getting user statistics",
  "We have forwarded the CV page to your email":
    "We have forwarded the CV page to your email",
  "You have successfully signed the document":
    "You have successfully signed the document",
  "You have successfully signed the document":
    "You have successfully signed the document",
  Success: "Success",
  "You need to put your payment details on file":
    "You need to put your payment details on file",
  "Invitation sent successfully": "Invitation sent successfully",
  "Permission denied": "Permission denied",
  "Wrong model": "Wrong model",
  "Friendly & Conversational": "Friendly & Conversational",
  "Professional & Authoritative": "Professional & Authoritative",
  "Innovative & Inspirational": "Innovative & Inspirational",
  "Quirky & Humorous": "Quirky & Humorous",
  "What is the name of your business?": "What is the name of your business?",
  "Please describe your product or service":
    "Please describe your product or service",
  "What are your pricing plans?": "What are your pricing plans?",
  "What are your benefits?": "What are your benefits?",
  "Who is your audience?": "Who is your audience?",
  Step: "Step",
  "What is the name of your business?": "What is the name of your business?",
  "{{step1}} of {{step2}}": "{{step1}} of {{step2}}",
  "Please describe your product or service":
    "Please describe your product or service",
  "What are your pricing plans?": "What are your pricing plans?",
  "What are your benefits?": "What are your benefits?",
  "Who is your audience?": "Who is your audience?",
  "What is your brand's tone and voice?":
    "What is your brand's tone and voice?",
  "AI Suggestion:": "AI Suggestion:",
  Use: "Use",
  "Select your brand color:": "Select your brand color:",
  Other: "Other",
  "Does it look right?": "Does it look right?",
  "Company Description": "Company Description",
  Benefits: "Benefits",
  "Customer Difficulties": "Customer Difficulties",
  "Pricing Details": "Pricing Details",
  "Which layout do you like more?": "Which layout do you like more?",
  Default: "Default",
  Secondary: "Secondary",
  "Your Purpose": "Your Purpose",
  "Who will you be building websites for?":
    "Who will you be building websites for?",
  "Clients as a freelancer": "Clients as a freelancer",
  "Clients as part of an agency": "Clients as part of an agency",
  "My company or business": "My company or business",
  "The company I work for": "The company I work for",
  Others: "Others",
  "How You Found": "How You Found",
  "How did you hear about SalesAI?": "How did you hear about SalesAI?",
  "Friend / Colleague": "Friend / Colleague",
  Twitter: "Twitter",
  Youtube: "Youtube",
  Instagram: "Instagram",
  Tiktok: "Tiktok",
  Facebook: "Facebook",
  "Facebook Ads": "Facebook Ads",
  Google: "Google",
  "Google Ads": "Google Ads",
  "Other Search Engines": "Other Search Engines",
  Others: "Others",
  "Role Description": "Role Description",
  "What best describes your role?": "What best describes your role?",
  "Small to medium size business owner": "Small to medium size business owner",
  Enterprener: "Enterprener",
  "Large business owner": "Large business owner",
  "Marketing agency": "Marketing agency",
  Marketer: "Marketer",
  "Web designer": "Web designer",
  Others: "Others",
  Next: "Next",
  "What are the customer pain points who would like to use your service?":
    "What are the customer pain points who would like to use your service?",
  "We provide our customers an AI system to automate their lead generation and sales.":
    "We provide our customers an AI system to automate their lead generation and sales.",
  "What are the customer pain points who would like to use your service?":
    "What are the customer pain points who would like to use your service?",
  "The main features are: Creating customized sales automation funnels for clients to streamline their sales process and increase conversions.":
    "The main features are: Creating customized sales automation funnels for clients to streamline their sales process and increase conversions.",
  "While we work on creating a customized sales strategy for your business, please take a few seconds to answer the survey to move on.":
    "While we work on creating a customized sales strategy for your business, please take a few seconds to answer the survey to move on.",
  "We are curious to know more about you to personalize your experience even more!":
    "We are curious to know more about you to personalize your experience even more!",
  "Myself (personal brand or portfolio)":
    "Myself (personal brand or portfolio)",
  Features: "Features",
  "Customer Struggles": "Customer Struggles",
  CTA: "CTA",
  "Features Slider": "Features Slider",
  Divider: "Divider",
  "Lead Form": "Lead Form",
  "Value Proposition": "Value Proposition",
  HeadlineComponent: "HeadlineComponent",
  Hero: "Hero",

  "Failed to fetch assistants": "Failed to fetch assistants",
  "Failed to fetch phone numbers": "Failed to fetch phone numbers",
  "Assistant deleted successfully": "Assistant deleted successfully",
  "Failed to delete assistant": "Failed to delete assistant",
  "Assistant updated successfully": "Assistant updated successfully",
  "Assistant created successfully": "Assistant created successfully",
  "Failed to submit assistant": "Failed to submit assistant",
  "Phone number added successfully": "Phone number added successfully",
  "Failed to add phone number": "Failed to add phone number",
  "Assistant activated successfully": "Assistant activated successfully",
  "Failed to activate assistant": "Failed to activate assistant",
  Name: "Name",
  "First Message": "First Message",
  Language: "Language",
  Gender: "Gender",
  Male: "Male",
  Speed: "Speed",
  Actions: "Actions",
  Edit: "Edit",
  Delete: "Delete",
  Status: "Status",
  Active: "Active",
  Activate: "Activate",
  "Call Agents": "Call Agents",
  "Create New Assistant": "Create New Assistant",
  "Edit Assistant": "Edit Assistant",
  "Please input the name": "Please input the name",
  "Please input the first message": "Please input the first message",
  "Please select the gender": "Please select the gender",
  Female: "Female",
  "Please select the language": "Please select the language",
  "Please input the speed": "Please input the speed",
  "Task Description": "Task Description",
  "Please input the task description": "Please input the task description",
  Update: "Update",
  "Add Phone Number": "Add Phone Number",
  "Phone Number": "Phone Number",
  "Please input the phone number": "Please input the phone number",
  "Twilio Account SID": "Twilio Account SID",
  "Please input the Twilio Account SID": "Please input the Twilio Account SID",
  "Twilio Auth Token": "Twilio Auth Token",
  "Please input the Twilio Auth Token": "Please input the Twilio Auth Token",
  "Name (Optional)": "Name (Optional)",
  "Activate Assistant": "Activate Assistant",
  "Select a phone number": "Select a phone number",
  "Create Assistant": "Create Assistant",
  Create: "Create",

  "Forward Call Number": "Forward Call Number",
  "Please input the number to forward to if the customer needs personal support":
    "Please input the number to forward to if the customer needs personal support",
  "Enter coupon code": "Enter coupon code",

  "Connect your Google calendar": "Connect your Google calendar",
  "Google Calendar Connection": "Google Calendar Connection",
  "Google Calendar is connected.": "Google Calendar is connected.",
  "Connect Google Calendar": "Connect Google Calendar",
  "Outbound Calling": "Outbound Calling",
  "Phone Configuration": "Phone Configuration",
  Webinars: "Webinars",

  "Failed to fetch webinars": "Failed to fetch webinars",
  "Failed to fetch participants": "Failed to fetch participants",
  "Webinar created successfully": "Webinar created successfully",
  "Failed to create webinar": "Failed to create webinar",
  Title: "Title",
  Date: "Date",
  Participants: "Participants",
  Status: "Status",
  Completed: "Completed",
  Upcoming: "Upcoming",
  Actions: "Actions",
  "Webinar link copied to clipboard": "Webinar link copied to clipboard",
  "Copy Link": "Copy Link",
  Webinars: "Webinars",
  "Create Webinar": "Create Webinar",
  "Create Webinar": "Create Webinar",
  "Webinar Title": "Webinar Title",
  "Please input webinar title": "Please input webinar title",
  Description: "Description",
  "Please input description": "Please input description",
  "Scheduled Date": "Scheduled Date",
  "Please select date": "Please select date",
  "Select Participants": "Select Participants",
  "Please select participants": "Please select participants",
  "Select participants": "Select participants",
  "Upload Webinar Video": "Upload Webinar Video",
  "Please upload video": "Please upload video",
  "Video uploaded successfully": "Video uploaded successfully",
  "Click or drag video to upload": "Click or drag video to upload",
  "Create Webinar": "Create Webinar",
  Webinar: "Webinar",
  "Phone Numbers": "Phone Numbers",
  "Inbound Calling": "Inbound Calling",

  "Video is required": "Video is required",
  "Days later": "Days later",
  Date: "Date",
  "Time Slots": "Time Slots",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  "Add Time Slot": "Add Time Slot",
  "Chat feature": "Chat feature",
  Use: "Use",
  "Don't Use": "Don't Use",
  "All leads joining this funnel will become participants of this webinar automatically after this many days. You can also manually make them participants above.":
    "All leads joining this funnel will become participants of this webinar automatically after this many days. You can also manually make them participants above.",

  "Your browser does not support the video tag.":
    "Your browser does not support the video tag.",
  viewers: "viewers",
  Comments: "Comments",
  "Post Comment": "Post Comment",
  You: "You",

  "Email Automations": "Email Automations",
  "Add Email Automation": "Add Email Automation",
  "Email Subject": "Email Subject",
  "Email Body": "Email Body",
  "Hours Before Webinar": "Hours Before Webinar",
  "Sending Behavior": "Sending Behavior",

  "Webinar has ended": "Webinar has ended",
  "Thank you for your interest. The webinar is no longer available.":
    "Thank you for your interest. The webinar is no longer available.",
  "Next webinar starts on {{nextDay}} at {{nextTime}}":
    "Next webinar starts on {{nextDay}} at {{nextTime}}",
  "Choose Stock Image": "Choose Stock Image",
  "Search for images...": "Search for images...",
  "Your Phone Numbers": "Your Phone Numbers",
  "Are you sure you want to remove this number?": "Are you sure you want to remove this number?",
  "Yes": "Yes",
  "No": "No",
  "Remove": "Remove",
  "Agent assigned": "Agent assigned",
  "Purchase Phone Number": "Purchase Phone Number",
  "Search Available Numbers": "Search Available Numbers",
  "Select a Number": "Select a Number",
  "Choose a phone number": "Choose a phone number",
  "Purchase Number": "Purchase Number",
  "Phone Numbers": "Phone Numbers",
  "AI Agents": "AI Agents",
  "Agent Assignment": "Agent Assignment",
  "Please select a number to purchase": "Please select a number to purchase",
  "Agent created successfully": "Agent created successfully",
  "Agent updated successfully": "Agent updated successfully",
  "Agent deleted successfully": "Agent deleted successfully",
  "Language": "Language",
  "Please select a language": "Please select a language",
  "Select language": "Select language",
  "Voice Gender": "Voice Gender",
  "Please select voice gender": "Please select voice gender",
  "Select gender": "Select gender",
  "Voice": "Voice",
  "Please select a voice": "Please select a voice",
  "Select a voice": "Select a voice",
  "Create AI Agent": "Create AI Agent",
  "Agent Name": "Agent Name",
  "Please enter agent name": "Please enter agent name",
  "e.g., Sales Assistant": "e.g., Sales Assistant",
  "Description": "Description",
  "Brief description of what this agent does": "Brief description of what this agent does",
  "Agent Prompt": "Agent Prompt",
  "Please enter agent prompt": "Please enter agent prompt",
  "Instructions for how the agent should behave and respond": "Instructions for how the agent should behave and respond",
  "Active": "Active",
  "Inactive": "Inactive",
  "Create Agent": "Create Agent",
  "Your AI Agents": "Your AI Agents",
  "Edit": "Edit",
  "Are you sure you want to delete this agent?": "Are you sure you want to delete this agent?",
  "Delete": "Delete",
  "AI Agent": "AI Agent",
  "Status": "Status",
  "Prompt": "Prompt",
  "Edit Agent": "Edit Agent",
  "Update Agent": "Update Agent",
  "Assign Agent to Phone Number": "Assign Agent to Phone Number",
  "Phone Number": "Phone Number",
  "Please select a phone number": "Please select a phone number",
  "Select a phone number": "Select a phone number",
  "Please select an agent": "Please select an agent",
  "Select an agent": "Select an agent",
  "Assign Agent": "Assign Agent",
  "Current Assignments": "Current Assignments",
  "No agent assignments yet": "No agent assignments yet",
  "Unknown Agent": "Unknown Agent",
  "Agent Prompt": "Agent Prompt",
  "Please enter agent prompt": "Please enter agent prompt",
  "Instructions for how the agent should behave and respond": "Instructions for how the agent should behave and respond",
  "First Message": "First Message",
  "The first message the agent will say when a call starts": "The first message the agent will say when a call starts",
  "Active": "Active",
};

export const translationJp = {
  "Generate landing page with AI": "AIでランディングページを生成",
  "Japanese Yen": "日本円",
  "U.S. dollar": "米ドル",
  Euro: "ユーロ",
  Sterling: "ポンド",
  "Australian dollar": "オーストラリアドル",
  "Canadian dollar": "カナダドル",
  "Swiss franc": "スイスフラン",
  "Indian rupee": "インドルピー",
  "UAE dirham": "UAEディルハム",
  "A great headline to capture interest": "興味を引く見出し",
  "5-10 word subline": "5〜10文字のサブタイトル",
  "A detailed description of our services / products": "サービス/製品の詳細",
  "5-10 word benefit": "5〜10文字のメリット訴求",
  "Contact Us": "お問い合わせ",
  "Book Demo": "デモを予約",
  "Feature title of our product / service": "製品/サービスの機能のタイトル",
  "Feature description of our product / service": "製品/サービスの機能の説明",
  "Testimonial section title": "お客様の声のタイトル",
  "Summary of what customers are saying about us":
    "お客様が私たちについて言っていることの概要",
  "Feature Category Title": "機能カテゴリのタイトル",
  "Feature description (5-10 words)": "機能の簡単な説明（5〜10文字）",
  "Sign Up for Updates": "最新情報を登録",
  "Stay informed with the latest news from our team.":
    "当チームからの最新ニュースをお知らせします。",
  "A meaningful title for the entire benefits section":
    "全体の利点セクションの意味のあるタイトル",
  "Description of what our core benefits are": "当社の主要な利点の説明",
  "Benefit title (2-5 words)": "利点のタイトル（2〜5文字）",
  "Benefit description (10-20 words)": "利点の説明（10〜20文字）",
  "Title for the section of our promise": "当社の約束セクションのタイトル",
  "Description of our promise": "当社の約束の説明",
  "Key Feature 1": "主要機能1",
  "Key Feature 2": "主要機能2",
  "Key Feature 3": "主要機能3",
  Home: "ホーム",
  "Get started": "始める",
  "for free": "無料で",
  "Already registered?": "すでに登録していますか？",
  "Sign in": "ログイン",
  "First name": "名",
  "Last name": "姓",
  "Email address": "メールアドレス",
  Password: "パスワード",
  Login: "ログイン",
  "I accept the": "同意します",
  "privacy policy": "プライバシーポリシー",
  and: "と",
  "terms of service": "利用規約",
  "Sign up": "登録する",
  "Could not load user data": "ユーザーデータを読み込めませんでした",
  Upload: "アップロード",
  Delete: "削除",
  Add: "追加",
  "Please write some text first": "まず、何か入力してください",
  "AI needs a little more context. Please write at least {{count}} characters.":
    "AIにはもう少し文脈が必要です。少なくとも{{count}}文字記入ください。",
  Tip: "ヒント",
  "{{fields}} {{isAre}} required": "{{fields}} は必要です",
  is: "は",
  are: "は",
  "Invalid email": "無効なメールアドレス",
  Previous: "戻る",
  Next: "次へ",
  Finish: "完了",
  "Edit Form Input": "フォーム入力を編集",
  Cancel: "キャンセル",
  Save: "保存",
  Label: "ラベル",
  Type: "タイプ",
  "Short Input": "短い入力",
  Email: "メール",
  Phone: "電話",
  "Large Input": "大きな入力",
  "Input Number": "番号を入力",
  Radio: "ラジオボタン",
  Select: "選択",
  Rate: "評価",
  Slider: "スライダー",
  Switch: "スイッチ",
  "Time Picker": "時間選択",
  "Date Picker": "日付選択",
  Checkbox: "チェックボックス",
  "Color Picker": "色選択",
  Min: "最小",
  Max: "最大",
  Step: "ステップ",
  Rows: "行",
  Options: "オプション",
  "Option Label": "オプションラベル",
  Remove: "削除",
  "Add Option": "オプションを追加",
  "Skip Logic Conditions": "スキップロジック条件",
  "Add Condition": "条件を追加",
  "KPI Display Name": "KPI表示名",
  "Add a descriptive name for your KPI to make it easily recognizable in your dashboard. This KPI will be based on the average value across all form submissions made by participants in response to this particular field.":
    "KPIに説明文を追加し、ダッシュボードで簡単に認識できるようにする。このKPIは、特定のフィールドに対する参加者によるすべてのフォーム送信の平均値に基づく。",
  "Enter metric name to track as a KPI": "KPIとして追跡する項目を入力",
  Edit: "編集",
  "Are you sure?": "よろしいですか？",
  Japan: "日本",
  China: "中国",
  "United States": "アメリカ合衆国",
  Germany: "ドイツ",
  India: "インド",
  "United Kingdom": "イギリス",
  France: "フランス",
  Canada: "カナダ",
  Italy: "イタリア",
  Brazil: "ブラジル",
  Australia: "オーストラリア",
  "South Korea": "韓国",
  Mexico: "メキシコ",
  Spain: "スペイン",
  Indonesia: "インドネシア",
  "Saudi Arabia": "サウジアラビア",
  Netherlands: "オランダ",
  Turkey: "トルコ",
  Switzerland: "スイス",
  Poland: "ポーランド",
  Argentina: "アルゼンチン",
  Sweden: "スウェーデン",
  Norway: "ノルウェー",
  Belgium: "ベルギー",
  Ireland: "アイルランド",
  Israel: "イスラエル",
  "United Arab Emirates": "アラブ首長国連邦",
  Thailand: "タイ",
  Nigeria: "ナイジェリア",
  Egypt: "エジプト",
  Austria: "オーストリア",
  Singapore: "シンガポール",
  Russia: "ロシア",
  Bangladesh: "バングラデシュ",
  Vietnam: "ベトナム",
  Malaysia: "マレーシア",
  "South Africa": "南アフリカ",
  Philippines: "フィリピン",
  Denmark: "デンマーク",
  Iran: "イラン",
  Pakistan: "パキスタン",
  "Hong Kong": "香港",
  Colombia: "コロンビア",
  Romania: "ルーマニア",
  Chile: "チリ",
  "Czech Republic (Czechia": "チェコ共和国 (チェコ)",
  Finland: "フィンランド",
  Iraq: "イラク",
  Portugal: "ポルトガル",
  "New Zealand": "ニュージーランド",
  Peru: "ペルー",
  Qatar: "カタール",
  Kazakhstan: "カザフスタン",
  Greece: "ギリシャ",
  Algeria: "アルジェリア",
  Kuwait: "クウェート",
  Hungary: "ハンガリー",
  Ukraine: "ウクライナ",
  Morocco: "モロッコ",
  Ethiopia: "エチオピア",
  Slovakia: "スロバキア",
  Ecuador: "エクアドル",
  Oman: "オマーン",
  "Dominican Republic": "ドミニカ共和国",
  Kenya: "ケニア",
  Angola: "アンゴラ",
  Guatemala: "グアテマラ",
  Bulgaria: "ブルガリア",
  Luxembourg: "ルクセンブルク",
  Uzbekistan: "ウズベキスタン",
  Azerbaijan: "アゼルバイジャン",
  Panama: "パナマ",
  Tanzania: "タンザニア",
  "Sri Lanka": "スリランカ",
  Ghana: "ガーナ",
  Belarus: "ベラルーシ",
  Uruguay: "ウルグアイ",
  Croatia: "クロアチア",
  Lithuania: "リトアニア",
  "Côte d'Ivoire": "コートジボワール",
  "Costa Rica": "コスタリカ",
  Serbia: "セルビア",
  Slovenia: "スロベニア",
  Myanmar: "ミャンマー",
  "DR Congo": "コンゴ民主共和国",
  Sudan: "スーダン",
  Jordan: "ヨルダン",
  Tunisia: "チュニジア",
  Libya: "リビア",
  Uganda: "ウガンダ",
  Bahrain: "バーレーン",
  Cameroon: "カメルーン",
  Bolivia: "ボリビア",
  Paraguay: "パラグアイ",
  Latvia: "ラトビア",
  Nepal: "ネパール",
  Estonia: "エストニア",
  "El Salvador": "エルサルバドル",
  Honduras: "ホンジュラス",
  "Papua New Guinea": "パプアニューギニア",
  Cambodia: "カンボジア",
  Zambia: "ザンビア",
  Cyprus: "キプロス",
  "Trinidad and Tobago": "トリニダード・トバゴ",
  Iceland: "アイスランド",
  Senegal: "セネガル",
  Georgia: "ジョージア",
  "Bosnia and Herzegovina": "ボスニア・ヘルツェゴビナ",
  Macao: "マカオ",
  Guinea: "ギニア",
  Gabon: "ガボン",
  Zimbabwe: "ジンバブエ",
  Botswana: "ボツワナ",
  Haiti: "ハイチ",
  Armenia: "アルメニア",
  "State of Palestine": "パレスチナ国",
  "Burkina Faso": "ブルキナファソ",
  Albania: "アルバニア",
  Mali: "マリ",
  Mozambique: "モザンビーク",
  Malta: "マルタ",
  Benin: "ベナン",
  Jamaica: "ジャマイカ",
  Mongolia: "モンゴル",
  Brunei: "ブルネイ",
  Laos: "ラオス",
  Nicaragua: "ニカラグア",
  Guyana: "ガイアナ",
  Madagascar: "マダガスカル",
  Congo: "コンゴ",
  Moldova: "モルドバ",
  Niger: "ニジェール",
  "North Macedonia": "北マケドニア",
  Rwanda: "ルワンダ",
  Malawi: "マラウイ",
  Mauritius: "モーリシャス",
  Bahamas: "バハマ",
  Chad: "チャド",
  Namibia: "ナミビア",
  "Equatorial Guinea": "赤道ギニア",
  Kyrgyzstan: "キルギス",
  Tajikistan: "タジキスタン",
  Mauritania: "モーリタニア",
  Togo: "トーゴ",
  Maldives: "モルディブ",
  Montenegro: "モンテネグロ",
  Barbados: "バルバドス",
  Fiji: "フィジー",
  Eswatini: "エスワティニ",
  Liberia: "リベリア",
  "Sierra Leone": "シエラレオネ",
  Suriname: "スリナム",
  Andorra: "アンドラ",
  "Timor-Leste": "東ティモール",
  Burundi: "ブルンジ",
  Belize: "ベリーズ",
  Lesotho: "レソト",
  "Central African Republic": "中央アフリカ共和国",
  "Cabo Verde": "カーボベルデ",
  Gambia: "ガンビア",
  "Saint Lucia": "セントルシア",
  "Antigua and Barbuda": "アンティグア・バーブーダ",
  "Guinea-Bissau": "ギニアビサウ",
  "Solomon Islands": "ソロモン諸島",
  Seychelles: "セーシェル",
  Grenada: "グレナダ",
  Comoros: "コモロ",
  Vanuatu: "バヌアツ",
  "Saint Kitts & Nevis": "セントクリストファー・ネーヴィス",
  "St. Vincent & Grenadines": "セントビンセント・グレナディーン",
  Samoa: "サモア",
  Dominica: "ドミニカ",
  "Sao Tome & Principe": "サントメ・プリンシペ",
  Micronesia: "ミクロネシア",
  "Marshall Islands": "マーシャル諸島",
  Kiribati: "キリバス",
  Tuvalu: "ツバル",
  "Email Verification": "メール認証",
  "We have sent a code to your email {{email}}":
    "{{email}}のメールアドレスにコードを送信しました",
  Verify: "確認",
  "Didn't receive the code?": "コードを受け取りませんでしたか？",
  Resend: "再送",
  "Sign out": "ログアウト",
  "Change Image": "画像を変更",
  "Your Name": "あなたの名前",
  "Your Email": "あなたのメール",
  "Your Phone": "あなたの電話番号",
  "Click to {{action}} page after submit": "上記の内容で送信する",
  edit: "編集",
  add: "追加",
  "This form will send visitors into: {{stepName}}":
    "このフォームは訪問者を{{stepName}}に送ります",
  "Your data is secured":
    "データはプライバシーポリシーに則って安全に管理されています",
  "Thanks for signing up!": "ご登録、ありがとうございます！",
  "Change Video": "ビデオを変更",
  "Change Thumbnail": "サムネイルを変更",
  "Edit Buttons": "ボタンを編集",
  "from {{count}}+ reviews": "{{count}}以上のレビューから",
  "Reorder Items": "アイテムを並べ替え",
  "Add Component": "コンポーネントを追加",
  "Stage Workflows": "ステージワークフロー",
  "Add Lead": "リードを追加",
  Import: "インポート",
  Export: "エクスポート",
  "Email Sequence": "メールシーケンス",
  "Load More": "さらに読み込む",
  "Send Email": "メールを送信",
  Schedule: "スケジュール",
  "Send SMS": "SMSを送信",
  Whatsapp: "WhatsApp",
  Call: "電話",
  Reject: "拒否",
  "Undo Reject": "拒否を元に戻す",
  "Lead Notes": "リードノート",
  Details: "詳細",
  LinkedIn: "LinkedIn",
  Today: "今日",
  Yesterday: "昨日",
  "Today at": "今日の",
  Rename: "名前を変更",
  "Password reset": "パスワードリセット",
  "Forgot your password? Type your email, and we will send an OTP.":
    "パスワードを忘れましたか？メールアドレスを入力すると、OTP（ワンタイムパスワード）を送信します。",
  "Request OTP": "OTP（ワンタイムパスワード）をリクエストする",
  "Back to login": "ログインに戻る",
  "Sign in to your account": "アカウントにログイン",
  "Don't have an account?": "アカウントをお持ちでないですか？",
  "for a {{trialDays}} day free trial.": "{{trialDays}}日間の無料トライアルに",
  "to get started.": "開始するために",
  "Forgot password?": "パスワードを忘れましたか？",
  "We are adjusting the funnels to your ideal customer profile":
    "ファネルを理想的な顧客プロファイルに調整中・・・",
  "We are setting up your messaging": "メッセージを設定中・・・",
  "We are creating a phone call strategy": "電話スクリプトを作成中・・・",
  "We are generating your online marketing strategy":
    "オンラインマーケティング戦略を生成中・・・",
  "We are tweaking your email messaging": "ステップメールを作成中・・・",
  "We are analyzing market trends to tailor your strategy":
    "市場動向を分析して戦略を調整中・・・",
  "We are crafting compelling ad copy for your campaigns":
    "キャンペーンのための魅力的な広告文を作成中・・・",
  "We are refining your brand's visual identity":
    "ブランドのビジュアルアイデンティティを洗練中・・・",
  "We are researching keywords to boost your SEO":
    "SEOを強化するためのキーワードを調査中・・・",
  "We are conducting competitor analysis to identify opportunities":
    "競合分析を実施中・・・",
  "We are drafting engaging email sequences":
    "魅力的なメールシーケンスを作成中・・・",
  "We are creating captivating graphics for your content":
    "コンテンツ用の魅力的なグラフィックを作成中・・・",
  "We are setting up tracking pixels for accurate analytics":
    "正確な分析のためにトラッキングピクセルを設定中・・・",
  "We are enhancing your product descriptions for conversions":
    "コンバージョンのために製品説明を強化中・・・",
  "We are segmenting your audience for targeted marketing":
    "ターゲットマーケティングのためにオーディエンスをセグメント化中・・・",
  "We are brainstorming viral campaign ideas":
    "バズるキャンペーンのアイデアをブレインストーミング中・・・",
  "We are optimizing your product photos for eCommerce":
    "eコマース用に製品写真を最適化中・・・",
  "We are conducting A/B tests for better campaign performance":
    "キャンペーンのパフォーマンス向上のためにA/Bテストを作成中・・・",
  "We are developing interactive content for engagement":
    "エンゲージメントを高めるためのインタラクティブコンテンツを開発中・・・",
  "We are building landing pages for lead generation":
    "リード獲得のためのランディングページを構築中・・・",
  "We are setting up automated workflows for efficiency":
    "業務効率化のため、自動化作業フローを設定中・・・",
  "We are exploring new advertising channels for growth":
    "あなたのビジネスの成長を目指して、新たな広告経路を検索中・・・",
  "We are designing eye-catching banners for promotions":
    "宣伝用の目を引くバナーをデザイン中・・・",
  "We are crafting compelling value propositions":
    "魅力的な価値提案を作成中・・・",
  "We are curating user-generated content for authenticity":
    "信頼性向上のため、ユーザーが作成したコンテンツを収集中・・・",
  "We are translating your content for international markets":
    "国際市場向けにコンテンツを翻訳中・・・",
  "We are designing infographics for data visualization":
    "データを視覚化するため、視覚情報をデザイン中・・・",
  "We are setting up retargeting campaigns for lost leads":
    "失ったリードを取り戻すため、再ターゲット広告を設定中・・・",
  "We are conducting user testing for UX improvements":
    "ユーザー体験改善のため、ユーザーテストを実施中・・・",
  "We are creating case studies to showcase success stories":
    "成功事例を紹介する事例研究を作成中・・・",
  "We are optimizing your email subject lines for opens":
    "開封率向上を目指して、メールの件名を最適化中・・・",
  "We are developing video content for engagement":
    "エンゲージメント向上のため、動画コンテンツを開発中・・・",
  "We are conducting social listening for brand sentiment":
    "ブランドに対する意見や感想を把握するため、ソーシャルリスニングを実行中・・・",
  "We are optimizing your content for search engines":
    "検索エンジン向けにコンテンツを最適化中・・・",
  "We are designing promotional flyers for events":
    "イベント用の宣伝チラシをデザイン中・・・",
  "We are optimizing your landing page loading speed":
    "ランディングページの表示速度を最適化中・・・",
  "We are crafting FAQs to address common queries":
    "よくある質問を作成し、一般的な質問への対応を設定中・・・",
  "We are conducting usability testing for website navigation":
    "ウェブサイトのナビゲーション改善のため、使いやすさテストを実施中・・・",
  "We are setting up Google Analytics goals for tracking":
    "追跡のためにGoogleアナリティクスの目標を設定中・・・",
  Onboarding: "初期設定を整備中・・・",
  "We are setting up your customized marketing system.":
    "カスタマイズした販売促進システムを設定中・・・",
  Information: "情報",
  "Confirm OTP": "OTPを確認",
  "Enter your new password": "新しいパスワードを入力",
  Submit: "送信",
  "to your account.": "自分のアカウントへ",
  "Account Activation": "アカウント有効化",
  "To access the plethora of tools and resources available on our platform, please choose a subscription plan that aligns with your requirements. Based on your current usage, we recommend subscribing to the {{name}} package or a higher-tier plan.":
    "当社のプラットフォームで利用できる豊富なツールとリソースにアクセスするには、ご希望に合ったプランをお選びください。現在のご利用状況に基づき、{{name}}プランまたはそれ以上のプランへのご登録をおすすめします。",
  "To unlock the full suite of tools and resources available through our platform, please select a subscription plan that best fits your needs.":
    "当社のプラットフォームで利用できるすべてのツールとリソースにアクセスするには、あなたのニーズに最適なサブスクリプションプランをお選びください。",
  "Payment frequency": "支払い回数",
  Monthly: "月払い",
  Annually: "年払い",
  "Most popular": "一番人気",
  month: "月",
  year: "年",
  "per team mate": "チームメイト1人あたり",
  "{{trialDays}} Days Free": "{{trialDays}}日間無料",
  "Buy Plan": "プランを購入",
  "Post up to {{maxFunnels}} funnels": "最大{{maxFunnels}}のファネルを投稿",
  "Unlimited funnels": "ファネル無制限",
  "${{cost}} per additional funnel": "追加ファネル1つあたり${{cost}}",
  "Up to {{maxCandidates}} leads": "最大{{maxCandidates}}リード",
  "Unlimited leads": "リード無制限",
  "${{cost}} per additional lead": "追加リード1つあたり${{cost}}",
  "Up to {{maxMessaging}} messaging templates":
    "最大{{maxMessaging}}メッセージテンプレート",
  "Unlimited messaging templates": "メッセージテンプレート無制限",
  "Up to {{maxTeamSize}} team mates": "最大{{maxTeamSize}}人のチームメイト",
  "Unlimited team mates": "チーム人数無制限",
  "AI Campaign Generator": "AI自動キャンペーン生成",
  "AI Ad Automation": "AI自動広告",
  "AI Cold Calling": "AI自動電話",
  "AI Email Marketing": "AI自動メールマーケティング",
  "AI Webinar Automation": "AI自動リード獲得ウェビナー",
  "AI Lead Generation": "AI自動リード獲得",
  "E-Learning": "Eラーニング",
  "Basic Information": "基本情報",
  "Phone Number*": "電話番号*",
  "Address Line 1*": "住所1*",
  "Address Line 2": "住所2",
  "City*": "市区町村*",
  "State*": "州*",
  "Zip Code*": "郵便番号*",
  "Country*": "国*",
  "Company Information": "会社情報",
  "Company Name*": "会社名*",
  "Company Website*": "会社のウェブサイト*",
  Branding: "ブランディング",
  Logo: "ロゴ",
  "Upload your company logo": "会社のロゴをアップロード",
  "Communication Preferences": "通信設定",
  "Calendar Link": "カレンダーリンク",
  "Enter your calendar link for scheduling":
    "スケジュール設定のためにカレンダーリンクを入力",
  "Mail Template": "メールテンプレート",
  "Enter your default mail template": "デフォルトのメールテンプレートを入力",
  "Service Details": "サービス詳細",
  "Target Age": "ターゲット層の年齢",
  "Target Gender": "ターゲット層の性別",
  "Target Interests": "ターゲット層の興味",
  "Target Industries": "ターゲット層の業界",
  "Target Keywords": "ターゲット層の関連キーワード",
  "Describe your the benefits of your service*":
    "サービスのメリットを説明ください*",
  "Target Age*": "ターゲット層の年齢*",
  "Target Gender*": "ターゲット層の性別*",
  Man: "男性",
  Woman: "女性",
  Diverse: "多様",
  "Target Interests*": "ターゲット層の興味*",
  "Target Industries*": "ターゲット層の業界*",
  "Target Keywords*": "ターゲット層の関連キーワード*",
  "Connection was not successful": "接続に失敗しました",
  Host: "ホスト",
  Port: "ポート",
  "DKIM Settings": "DKIM設定",
  Domain: "ドメイン",
  "Key Selector": "キーセレクター",
  "Private Key": "プライベートキー",
  Disconnect: "切断",
  Connect: "接続",
  "We are pleased to welcome you aboard as a valued user in the journey towards enhancing your marketing. To ensure a smooth and efficient setup process, we have prepared a step-by-step guide to assist you in configuring all the necessary elements.":
    "マーケティングの強化に向けた取り組みに、ご参加いただきありがとうございます。スムーズで効率的な設定プロセスを実現するために、必要な要素を構成するためのステップバイステップガイドを用意しました。",
  "DomainKeys Identified Mail (DKIM) is an email authentication method that helps protect email senders and recipients from spam, spoofing, and phishing. It allows an email receiver to check that an email claimed to have come from a specific domain was indeed authorized by the owner of that domain. Using a DKIM generator, such as the one available at EasyDMARC's DKIM Record Generator (click on the lightbulb icon to navigate there), you can easily create these keys. Fill in the 'Domain' and 'Key Selector' fields with the appropriate information for your domain, then generate and use the provided private key here. Your public key will be part of the DKIM record you add to your domain's DNS settings.":
    "DomainKeys Identified Mail（DKIM）は、スパム、スプーフィング、フィッシングからメール送信者と受信者を保護するためのメール認証方法です。これにより、メール受信者は、特定のドメインから送信されたとされるメールが実際にそのドメインの所有者によって許可されたものであるかどうかを確認できます。EasyDMARCのDKIMレコードジェネレーター（電球アイコンをクリックしてアクセス）を使用すれば、これらのキーを簡単に作成できます。あなたのドメインに必要な情報を「ドメイン」と「キーセレクター」フィールドに入力し、ここで生成されたプライベートキーを使用してください。あなたのパブリックキーは、ドメインのDNS設定に追加するDKIMレコードの一部となります。",
  "When you disconnect your SMTP, your emails will no longer be sent from your inbox.":
    "SMTPを切断すると、あなたの受信トレイからメールは送信されなくなります。",
  Billing: "請求",
  "Here you can manage your subscription plan.":
    "こちらでサブスクリプションプランを管理できます。",
  Current: "現在",
  "Funnels created": "作成済ファネル",
  Leads: "リード",
  "Messaging templates stored": "保存されたメッセージテンプレート",
  "Team mates": "チームメイト",
  "Your plan has been upgraded": "プランがアップグレードされました",
  "Upgrade Plan": "プランをアップグレード",
  "Upgrade plan": "プランをアップグレード",
  "Billing Management": "請求管理",
  Invoices: "請求書",
  "Connect Your Calendly to Get Started": "開始するにはCalendlyを接続",
  "Head over to your Calendly dashboard.":
    "Calendlyのダッシュボードに移動してください。",
  "We see that you've successfully linked your Calendly account – that's a great first step. To streamline your scheduling process, please select a default event type from the options below. This will be the meeting template that interviewees will use to book a time with you.":
    "Calendlyアカウントのリンクが成功しました！スケジュールをスムーズに設定するために、以下からデフォルトのイベントタイプを選んでください。面談者があなたと時間を予約する際に使用するミーティングテンプレートになります。",
  "Selecting a default event type for interviews will help you to automate your scheduling and let you focus on the conversations that matter. If you need any help along the way, we're here for you!":
    "面談のデフォルトイベントタイプを選択すると、スケジュールが自動化され、重要な会話に集中できるようになります。途中でお困りの際は、私たちがサポートいたしますので、ご安心ください！",
  "We are not able to provide you with any options, because you haven't set up any booking events in your Calendly account yet. No worries, it's a quick fix:":
    "Calendlyアカウントに予約イベントがまだ設定されていないため、オプションを提供できません。簡単に修正できますので、ご安心ください！",
  "Create at least one event type to be used for interviews.":
    "面談に使用するために、少なくとも1つのイベントタイプを作成してください。",
  "Come back here, and voilà, your event should appear.":
    "ここに戻って、ほら、イベントが表示されるはずです。",
  "Would you like to instead disconnect your calendly integration?":
    "代わりにCalendly連携を切断しますか？",
  "Your calendar scheduling functionality will stop working. Are you sure to proceed?":
    "カレンダーのスケジュール機能が停止しますが、続行してもよろしいですか？",
  Privacy: "プライバシー",
  Terms: "利用規約",
  "Acceptable Use": "利用可能な範囲",
  Cookies: "クッキー",
  Disclaimer: "免責事項",
  "Create Landing Page": "ランディングページを作成",
  "My Landing Pages": "ランディングページ",
  "Ad Automation": "広告自動化",
  "Contact List": "連絡先リスト",
  Team: "チーム",
  "Media Library": "メディアライブラリ",
  "My Users": "私のユーザー",
  "My Stats": "私の統計",
  "Support Tickets": "サポートチケット",
  Settings: "設定",
  "SaaS Configuration": "SaaS設定",
  "Feature Request": "機能リクエスト",
  "Bug Report": "バグ報告",
  "As a user, I want to be able to...":
    "ユーザーとして、次のことをできるようにしたい",
  "so that I can...": "そうすることで次のようなことを実現できます",
  "This feature will particularly help me with...":
    "この機能は特に、次のようなケースに役立ちます",
  Send: "送信",
  "I was trying to...":
    "次のようなことを実施しようとしてうまくいきませんでした",
  "Here are additional details on what happened...":
    "こちらが何が起こったのかについての追加の詳細です..",
  "Here is a step-by-step process to reproduce the error I am facing...":
    "直面しているエラーを再現するための手順を以下に示します..",
  "{{timeframe}} {{idx}} ago": "{{timeframe}} {{idx}} 前",
  This: "これ",
  "SaaS Statistics": "SaaS統計",
  "Signup surveys": "サインアップ調査",
  "Total Users": "総ユーザー数",
  "Website Purpose": "ウェブサイトの目的",
  "{{count}} ({{percentage}}%)": "{{count}} ({{percentage}}%)",
  "Discovery Source": "発見元",
  Role: "役割",
  "Your overall performance": "全体的なパフォーマンス",
  "Monthly Active Users": "月間アクティブユーザー数",
  "Subscribed Users": "登録ユーザー",
  "Users Subscribed Once": "一度登録したユーザー",
  "Monthly Recurring Revenue": "月間定期収益",
  "Paid User Ratio": "有料ユーザー比率",
  "Churn Rate": "解約率",
  Clicks: "クリック",
  Conversions: "コンバージョン",
  Qualified: "条件に合う",
  Meetings: "ミーティング",
  Customers: "顧客",
  "Last {{count}} hours": "過去{{count}}時間",
  "Last {{count}} days": "過去{{count}}日間",
  "Last {{count}} weeks": "過去{{count}}週間",
  "Last {{count}} months": "過去{{count}}ヶ月",
  "Last {{count}} years": "過去{{count}}年",
  "This is the overall performance across all recruiters on your SaaS":
    "あなたのSaaSにおけるすべてのリクルーターの全体的なパフォーマンス",
  "How your SaaS performed during the last {{segmentNum}} {{timeframeName}}s":
    "あなたのSaaSが過去{{segmentNum}}{{timeframeName}}にどのようにパフォーマンスしたか",
  "Search Users": "ユーザーを検索",
  Sort: "並べ替え",
  "Newest first": "新しい順",
  "Oldest first": "古い順",
  "Recent Activity": "最近のアクティビティ",
  "Oldest Activity": "最も古いアクティビティ",
  Filter: "フィルター",
  "All users": "すべてのユーザー",
  "Subscribed Once": "一度登録",
  "Currently subscribed": "現在登録中",
  "Currently in trial": "現在トライアル中",
  Online: "オンライン",
  Trial: "トライアル",
  "Manual Trial": "手動トライアル",
  "{{current}} / {{max}} Funnels": "{{current}} / {{max}} ファネル",
  "{{count}} Funnels": "{{count}} ファネル",
  "{{current}} / {{max}} Candidates": "{{current}} / {{max}} 候補者",
  "{{count}} Candidates": "{{count}} 候補者",
  "{{current}} / {{max}} Templates": "{{current}} / {{max}} テンプレート",
  "{{count}} Templates": "{{count}} テンプレート",
  "{{current}} / {{max}} Team mates": "{{current}} / {{max}} チームメイト",
  "{{count}} Team mates": "{{count}} チームメイト",
  "Access Allowed": "アクセス許可",
  "Joined: {{date}}": "参加日: {{date}}",
  "Activity: {{date}}": "活動日: {{date}}",
  "Load more": "もっと読み込む",
  "Adjusted trial end date": "調整済みトライアル終了日",
  "Resale Factor for Lead Sourcing": "リードソーシングの再販係数",
  "By default, all users are granted a standard trial period of {{trialDays}} days as per your SaaS configuration. As a partner, you have the discretion to extend the trial duration for individual users. Set the final date below to determine the length of the extended trial access for the selected user. This date represents the conclusion of the user's extended trial period. if user's extended trial access date is not specified below or has passed, and their account registration exceeds the default {{trialDays}}-day trial period, they will be required to select and purchase a suitable subscription package for continued system access.":
    "デフォルトでは、すべてのユーザーにSaaSの設定に基づいて標準の{{trialDays}}日間のトライアル期間が付与されます。パートナーとして、各ユーザーのトライアル期間を延長する権限があります。以下に最終日を設定し、選択されたユーザーの拡張トライアルアクセス期間を決定してください。この日付はユーザーの拡張トライアル期間の終了を示します。ユーザーの拡張トライアルアクセスの日付が指定されていない場合や過ぎている場合、またはアカウント登録がデフォルトの{{trialDays}}日間のトライアル期間を超えている場合は、継続的なシステムアクセスのために適切なサブスクリプションパッケージを選択して購入する必要があります。",
  "This configuration is to decide how many times more this specific user is charged for using the lead sourcing system compared to your cost. Leave it at 0 if the standard configuration from your SaaS Configuration &gt; RC System should apply.":
    "この設定は、リードソーシングシステムの使用に対してこの特定のユーザーに課金される金額があなたのコストと比較して何倍になるかを決定します。SaaS設定 > RCシステムの標準設定を適用する場合は0のままにしてください。",
  "Phone Call": "電話",
  Lead: "リード",
  Number: "番号",
  "Copy Number": "番号をコピー",
  "Copied to clipboard": "クリップボードにコピーしました",
  "Hang up": "切断",
  "Search Team Members": "チームメンバーを検索",
  "Invite Team Member": "チームメンバーを招待",
  "Access Level": "アクセスレベル",
  "Read Only": "読み取り専用",
  "Read & Write": "読み取り＆書き込み",
  "Are you sure to delete this team member?":
    "このチームメンバーを削除してもよろしいですか？",
  Firstname: "名",
  Lastname: "姓",
  "Send Invite": "招待を送信",
  "User Logs": "ユーザーログ",
  "Lead: {{firstname}} {{lastname}} ({{email}})":
    "リード: {{firstname}} {{lastname}} ({{email}})",
  "Nothing to show": "表示するものがありません",
  "Search Contacts": "連絡先を検索",
  "Newest customer": "最新の顧客",
  "Oldest customer": "初期の顧客",
  "Best rating": "最高評価",
  "Worst rating": "最低評価",
  "All leads": "すべてのリード",
  "Meeting Scheduled": "ミーティングが設定されました",
  Customer: "顧客",
  Rejected: "拒否",
  "Your access is read-only": "読み取り専用です",
  "Are you sure to delete the lead?": "リードを削除してもよろしいですか？",
  "Signed up: {date}": "登録日: {date}",
  "Closed: {date}": "終了日: {date}",
  "Security Alerts": "セキュリティアラート",
  "Notifications regarding account security, like login from a new device or location.":
    "新しいデバイスや場所からのログインなど、アカウントのセキュリティに関する通知。",
  "Platform Updates": "プラットフォーム更新",
  "Information about new features, updates, or maintenance schedules.":
    "新機能や更新情報、メンテナンススケジュールのお知らせ",
  Newsletter: "ニュースレター",
  "Regular updates on news, insights, and tips from our community.":
    "コミュニティからのニュース、インサイト、ヒントに関する定期的なアップデート",
  Support: "サポート",
  "Receive updates on your support inquiries.":
    "サポートへのお問い合わせに関する最新情報をお届けします",
  "New Lead": "新しいリード",
  "Get notified when a new lead has arrived in your funnel.":
    "新しいリードがファネルに追加された際に通知を受け取る",
  "Profile Completion": "プロフィール完成",
  "Personal Information": "個人情報",
  "Use a permanent address where you can receive mail.":
    "確実にメールを受け取れる普段ご使用のアドレスをご利用ください",
  Change: "変更",
  "Line 1": "住所1",
  "Line 2": "住所2",
  City: "市区町村",
  "State / Province": "州/県",
  "ZIP / Postal code": "郵便番号",
  Country: "国",
  "Privacy Policy Link": "プライバシーポリシーリンク",
  Messaging: "メッセージ管理",
  "Configure the replacements for general messaging variables and general email templates here.":
    "一般的なメッセージ変数とメールテンプレートの置換設定を行います",
  "This is the replacement for the 'User Calendar Link' variable in your messages.":
    "メッセージ内で「ユーザーカレンダーリンク」変数を置き換えるための設定です",
  "User Calendar Link": "ユーザーカレンダーリンク",
  "Company Name": "会社名",
  "Company Website": "会社のウェブサイト",
  "Email Template": "メールテンプレート",
  "Ideal Customer Profile": "理想顧客のプロファイル",
  "Configure the demographics of your ideal customer profile.":
    "理想顧客のプロファイルに基づく人口属性を設定します",
  "Describe your service details": "サービスの詳細を説明してください",
  Security: "セキュリティ",
  "Change your password here.": "ここでパスワードを変更してください。",
  "Current password": "現在のパスワード",
  "New password": "新しいパスワード",
  "Repeat new password": "新しいパスワードを再入力",
  Account: "アカウント",
  "This is your account information.": "あなたのアカウント情報です。",
  Photo: "写真",
  "Upload a clean profile avatar to personalize your profile.":
    "プロフィールをパーソナライズするために、清潔感のあるアバター画像をアップロードしてください",
  Brand: "ブランド",
  "This section is to tailor the branding of your funnel pages, lead-facing materials (such as funnels and surveys).":
    "このセクションでは、ファネルページやリード向けの資料（ファネルやアンケートなど）のブランディングを調整できます",
  "Brand Color": "ブランドカラー",
  Integrations: "統合",
  "Manage all of your integrations here.": "ここで全ての統合を管理できます",
  Calendly: "Calendly",
  "Selecting a default event type for interviews will help you to automate your scheduling and let you focus on the conversations that matter.":
    "面談用のデフォルトイベントタイプを選択することで、スケジュールの自動化が進み、重要な会話に集中できるようになります",
  Zoom: "Zoom",
  SMTP: "SMTP",
  Notifications: "通知",
  "We'll always let you know about important changes, but you pick what else you want to hear about.":
    "重要な変更等については必ずお知らせしますが、それ以外の通知は自由に選ぶことができます",
  "By Email": "メールで",
  "New email": "新規のメール",
  "your-domain.com": "your-domain.com",
  "UTM Campaign": "UTMキャンペーン",
  "UTM Source": "UTMソース",
  "UTM Medium": "UTMメディア",
  "UTM Content": "UTMコンテンツ",
  "UTM Term": "UTM用語",
  "Salesforce UUID": "Salesforce UUID",
  Back: "戻る",
  "Funnel Statistics: {{name}}": "ファネル統計: {{name}}",
  Hires: "採用",
  "Lead Surveys": "リード調査",
  "Min: {{min}} | Max: {{max}}": "最小: {{min}} | 最大: {{max}}",
  "Median: {{median}}": "中央値: {{median}}",
  "UTM Tracking": "UTMトラッキング",
  "No data available": "データがありません",
  "How your funnel{{s}} performed during the last {{count}} {{timeframe}}s":
    "過去{{count}}{{timeframe}}におけるファネルパフォーマンス",
  "WebSocket connection established": "WebSocket接続が確立されました",
  "Message Websocket!": "メッセージWebSocket！",
  "Your issue has been marked as resolved":
    "あなたの問題は解決済みとしてマークされました",
  "Websocket error:": "WebSocketエラー:",
  "WebSocket connection closed": "WebSocket接続が閉じられました",
  "How can we help you?": "どのようにお手伝いできますか？",
  "View notifications": "通知を見る",
  "Open user menu": "ユーザーメニューを開く",
  "Open main menu": "メインメニューを開く",
  Dashboard: "ダッシュボード",
  "Close sidebar": "サイドバーを閉じる",
  "Open sidebar": "サイドバーを開く",
  MENU: "メニュー",
  GENERAL: "一般",
  "Include me in BCC": "BCCに自分を含める",
  Recipient: "受信者",
  Subject: "件名",
  "Type # to browse variables": "変数を閲覧するには#を入力",
  "Possible variables": "可能な変数",
  "Prospect's Firstname": "見込み客の名",
  "Prospect's Lastname": "見込み客の姓",
  "Prospect's Email": "見込み客のメールアドレス",
  "Company Address": "会社住所",
  "Current Date": "現在の日付",
  "Current Time": "現在の時間",
  "User Firstname": "ユーザーの名",
  "User Lastname": "ユーザーの姓",
  "User Email": "ユーザーのメール",
  "User Phone": "ユーザーの電話",
  Message: "メッセージ",
  "AI Outreach": "AIアウトリーチ",
  "Please enter a subject line": "件名を入力してください",
  "Message cannot be empty": "メッセージは空のままにすることはできません",
  "Full name": "フルネーム",
  Title: "タイトル",
  Headline: "見出し",
  "Import into funnel": "ファネルにインポート",
  "Send email": "メールを送信",
  "Copy email: {email}": "メールをコピー: {email}",
  "Click to add email manually": "メールを手動で追加するにはクリック",
  "Email Status": "メールステータス",
  Verified: "確認済み",
  Guessed: "予測済み",
  Unavailable: "利用不可",
  Bounced: "バウンス",
  "Pending Manual Fulfillment": "手動履行待ち",
  "Likely to Reply": "返信の可能性あり",
  Yes: "はい",
  No: "いいえ",
  "Social Links": "ソーシャルリンク",
  State: "州",
  Company: "会社",
  Departments: "部門",
  "Sub Departments": "サブ部門",
  Functions: "機能",
  Seniority: "上級",
  "Delete ({count})": "削除 ({count})",
  "Manual Email Entry": "手動メール入力",
  "Error fetching data:": "データ取得エラー:",
  "You need to add a payment method": "支払い方法を追加してください。",
  "Partner Rates": "パートナー料金",
  "User Rates": "ユーザー料金",
  "Confirm Fees": "料金を確認",
  "I agree to the payment policy": "支払いポリシーに同意します",
  "Copy email: {{email}}": "メールをコピー: {{email}}",
  "Click to unlock email": "メールをアンロックするにはクリックしてください。",
  "Confirm unlocking this email?":
    "このメールのアンロックを確認してもよろしいですか？",
  APOLLO_PEOPLE_UPDATE: "APOLLO_PEOPLE_UPDATE",
  "Unable to verify contact.": "連絡先を確認できません。",
  "Enter Keywords": "キーワードを入力",
  "Enter Seniority Level": "役職を入力してください。",
  Owner: "オーナー",
  Founder: "創業者/CEO",
  "C Suite": "役員",
  Partner: "共同経営者",
  VP: "事業部長/本部長/部長",
  Head: "支社長、所長、工場長など",
  Director: "課長",
  Manager: "係長",
  Senior: "リーダー",
  Entry: "社員",
  Intern: "インターン生",
  "Enter Job Titles": "職位を入力",
  "Enter Organization Headcount": "組織の従業員数を入力",
  "Enter Organization Domains": "組織のドメインを入力",
  "{{count}} Result": "{{count}} 結果",
  "in {{duration}} seconds": "{{duration}}秒以内に",
  "Lead Sourcing Fees": "リードソーシング料金",
  Understood: "了解しました",
  Close: "閉じる",
  Search: "検索",
  "Bulk Unlock ({{count}})": "一括アンロック ({{count}})",
  "You can only unlock up to {{count}} prospects at once":
    "一度に最大{{count}}の見込み客をアンロックできます",
  "None of the selected prospects can be unlocked":
    "選択された見込み客のいずれもアンロックできません",
  "Unlocked {{count}} new contacts":
    "{{count}}の新しい連絡先をアンロックしました",
  "Import Prospect": "見込み客をインポート",
  "Please select a vacancy": "空き状況を選択してください",
  "Please select a stage": "ステージを選択してください",
  "Prospect has been successfully imported":
    "見込み客が正常にインポートされました",
  "Search Vacancies": "空き状況を検索",
  "Select Stage": "ステージを選択",
  "Please write something to copy it": "コピーするために何か入力してください",
  "Outreach text copied to clipboard":
    "アウトリーチ用のテキストがクリップボードにコピーされました。",
  "To explore available messaging variables, simply type '#' followed by your keyword, and a list of suggestions will appear for your convenience.":
    "利用可能なメッセージング変数を探すには、'#'を入力した後にキーワードを入力すると、便利な提案リストが表示されます。",
  "Import Leads Into Queue": "リードをキューにインポート",
  "Please check and verify the data": "データを確認して検証してください",
  Tags: "タグ",
  "Enter Tags": "タグを入力",
  "Lead Type": "リードタイプ",
  "Enter Lead Type": "リードタイプを入力",
  "LinkedIn URL": "LinkedIn URL",
  "Remove Dupes": "重複を削除",
  "Column of Imported File": "インポートされたファイルの列",
  "Target Column": "ターゲット列",
  "Please select a mapping column for email":
    "メールのマッピング列を選択してください",
  "Please select a mapping column for firstname or fullname":
    "名またはフルネームのマッピング列を選択してください",
  "Please select a mapping column for lastname or fullname":
    "姓またはフルネームのマッピング列を選択してください",
  "Only partners": "パートナーのみ",
  "Only recruiters": "リクルーターのみ",
  Joined: "参加日",
  "Do MMM, YYYY, HH:mm": "MMM, YYYY, HH:mmで実行",
  Activity: "活動",
  "Negative Keywords": "ネガティブキーワード",
  "Positive Keywords": "ポジティブキーワード",
  "Number of Leads": "リード数",
  "Enter Number of Leads": "リード数を入力",
  Insert: "挿入",
  Vacancies: "空き状況",
  "Active Automations": "アクティブな自動化",
  "{{count}} per day, {{type}}, {{stage}}":
    "1日{{count}}回, {{type}}, {{stage}}",
  Positive: "ポジティブ",
  Negative: "ネガティブ",
  "Are you sure to delete the automation?":
    "自動化を削除してもよろしいですか？",
  "Scheduled Meeting Details": "予定されたミーティングの詳細",
  "Meeting Start Time": "ミーティング開始時間",
  "Meeting End Time": "ミーティング終了時間",
  "Join Meeting": "ミーティングに参加",
  "Reschedule Event": "イベントを再スケジュール",
  "Cancel Event": "イベントをキャンセル",
  Template: "テンプレート",
  "Sure to delete?": "削除してもよろしいですか？",
  "Save Current": "現在の内容を保存",
  "Lead's Firstname": "リードの名",
  "Lead's Lastname": "リードの姓",
  "Lead's Email": "リードのメール",
  "Lead's Phone": "リードの電話",
  "Job Title": "職位",
  "Job Location": "勤務地",
  "Job Application Link": "求人応募用リンク",
  "Interview Date": "面接日",
  "Interview Time": "面接時間",
  "Interview Ending Date": "面接終了日",
  "Interview Ending Time": "面接終了時間",
  "Interview Meeting Link": "面接ミーティングリンク",
  "Calendly Cancel URL": "CalendlyキャンセルURL",
  "Calendly Reschedule URL": "Calendly再スケジュールURL",
  "Application Status": "応募状況",
  "Rejection Reason": "不採用理由",
  "CV Submission Link": "履歴書提出リンク",
  "Survey Link": "アンケートリンク",
  "Interview Remaining Time": "面接残り時間",
  "Message must include the variable Calendar Link":
    "メッセージにはカレンダーリンクの変数を含める必要があります。",
  "The meeting previously arranged has been cancelled{{cancelAt}}{{cancelledBy}}{{cancelReason}}":
    "以前に予約されたミーティングがキャンセルされました{{cancelAt}}{{cancelledBy}}{{cancelReason}}",
  "Empty rejection reason cannot need to be stored as a template":
    "空欄の不採用理由はテンプレートとして保存する必要はありません",
  "Provide a reason on why the lead was not a great fit. This text can be used in your email automations as a variable.":
    "リードが適していなかった理由を教えてください。このテキストは、メール自動化で変数として使用できます。",
  "The rejection reason entered will be saved as a lead variable. No automated communication will occur without a custom workflow setup by you. For instance, a workflow could move leads to a 'unqualified' stage and trigger an email with the stored rejection reason. This process is entirely optional and customizable.":
    "入力された不採用理由はリード変数として保存されます。カスタムワークフローを設定しない限り、自動化されたコミュニケーションは発生しません。たとえば、ワークフローによってリードを「不適格」ステージに移動させ、保存された拒否理由を含むメールをトリガーすることができます。このプロセスは完全にオプションであり、カスタマイズ可能です。",
  "Enable Interview Confirmations": "面接確認を有効にする",
  "Message must include the variable Interview Meeting Link":
    "メッセージには「面接ミーティングリンク」という変数を含める必要があります。",
  "Customize your booking confirmation message here. Include essential details like the meeting link, date, and time. Save your template to ensure leads receive immediate confirmation upon booking.":
    "ここで予約確認メッセージをカスタマイズします。ミーティングリンク、日付、時間などの重要な詳細を含めてください。テンプレートを保存すれば、リードが予約時に即座に確認を受け取ることができます。",
  "SMS cannot be empty": "SMSは空欄にできません",
  "Toggle Image Position": "画像位置を切り替え",
  Funnels: "ファネル",
  "Funnel Steps": "ファネルステップ",
  Reorder: "並べ替え",
  "Create Funnel": "ファネルの作成",
  "chat-user": "チャットユーザー",
  "Support inquiry": "サポート問い合わせ",
  "Are you sure to close this ticket?": "このチケットを閉じてよろしいですか？",
  Solved: "解決済み",
  "Type a message...": "メッセージを入力...",
  "Confirm Deletion": "削除を確定",
  "Please confirm the deletion of this funnel.":
    "このファネルの削除を確認してください。",
  DELETE: "削除",
  "Search Campaigns": "キャンペーンを検索",
  "New Ad Campaign {{date}}": "新しい広告キャンペーン {{date}}",
  "Create New Campaign": "新しいキャンペーンを作成",
  New: "新規",
  "Change funnel name": "ファネル名を変更",
  Unspecified: "未指定",
  Publish: "公開",
  Stats: "統計",
  Impressions: "インプレッション",
  Spend: "費用",
  "Cost Per Click": "クリック単価",
  CPM: "CPM",
  "Click Through Rate": "クリック率",
  Reach: "リーチ",
  Frequency: "頻度",
  "First Delivery": "最初の配信",
  "Last Delivery": "最後の配信",
  "Number of times users that scrolled past your ad":
    "広告をスクロールしたユーザーの回数",
  "Number of users that clicked on your ad": "広告をクリックしたユーザーの数",
  "Total Cost": "総費用",
  "Cost Per 1000 Impressions": "1000インプレッションあたりの費用",
  "Ratio of users clicking on your ad to the total users scrolling past your ad":
    "広告をクリックしたユーザーの比率と広告をスクロールした全ユーザーの比率",
  "Total amount of unique users that have seen your ad":
    "広告を見たユニークユーザーの総数",
  "Number of times your ad shows up per user on average":
    "ユーザー1人あたりに広告が表示される平均回数",
  "Number of visitors that have converted to a lead":
    "リードに変換された訪問者の数",
  "Date of the first delivery of your ad": "広告の最初の配信日",
  "Date of the last delivery of your ad": "広告の最後の配信日",
  "Your ad has not yet been delivered to the target audience. You will see the statistics here as soon as your ad starts to deliver.":
    "広告はまだターゲットオーディエンスに配信されていません。広告配信が開始されると、ここに統計が表示されます。",
  Metric: "メトリック",
  Value: "値",
  "Ad Campaign Statistics": "広告キャンペーン統計",
  "Task Description": "タスクの説明",
  "Describe the task of your call agent":
    "コールエージェントのタスク内容を説明してください。",
  "Enter task description": "タスクの説明を入力",
  "Call Configuration": "コール設定",
  Speed: "速度",
  Slow: "遅い",
  Normal: "普通",
  Fast: "速い",
  "Ultra Fast": "超高速",
  "Select the speed of speech": "話す速度を選択",
  Language: "言語",
  "American English": "アメリカ英語",
  "British English": "イギリス英語",
  Dutch: "オランダ語",
  French: "フランス語",
  German: "ドイツ語",
  Italian: "イタリア語",
  Japanese: "日本語",
  Polish: "ポーランド語",
  Portuguese: "ポルトガル語",
  Russian: "ロシア語",
  Spanish: "スペイン語",
  Swedish: "スウェーデン語",
  Turkish: "トルコ語",
  Chinese: "中国語",
  "Select the language": "言語を選択",
  "Maximum Call Duration": "最大通話時間",
  "Record Call": "通話を記録",
  "General Notes": "一般的なノート",
  Notes: "ノート",
  at: "時",
  "Are you sure to delete?": "削除してもよろしいですか？",
  Update: "更新",
  "Insert Note": "ノートを挿入",
  "Name of the campaign": "キャンペーン名",
  "Enter campaign name": "キャンペーン名を入力",
  "Optimization Focus": "最適化の焦点",
  "Select optimization focus": "最適化の焦点を選択",
  Sales: "販売",
  "Monthly Budget (Local Currency)": "月間予算（現地通貨）",
  "Enter monthly budget": "月間予算を入力",
  Copy: "コピー",
  "Primary Text": "プライマリテキスト",
  "Enter primary text": "プライマリテキストを入力",
  "Enter headline": "見出しを入力",
  Description: "説明",
  "Enter description": "説明を入力",
  Linking: "リンク",
  "Please link your Meta account": "Metaアカウントをリンクしてください",
  "Meta Ads Linking": "Meta広告リンク",
  "Failed to initiate linking process": "リンクプロセスの開始に失敗しました",
  "Link Meta Ads Account": "Meta広告アカウントをリンク",
  "Enter Your Meta Pixel Code To Enable Conversion Tracking":
    "コンバージョントラッキングを有効にするためにMetaピクセルコードを入力",
  "Google Ads Linking": "Google広告リンク",
  "Link Google Ads Account": "Google広告アカウントをリンク",
  "Functionality Selection": "機能選択",
  Functionality: "機能",
  "Get full functionality including ad optimization":
    "広告最適化を含む完全な機能を取得",
  "Only reporting functionality": "報告機能のみ",
  "Optimisation Period": "最適化期間",
  "Target Funnel": "ターゲットファネル",
  "Select target funnel": "ターゲットファネルを選択",
  "Please enter some text to add.": "追加するテキストを入力してください。",
  "No caption selected to delete.":
    "削除するキャプションが選択されていません。",
  "Enter caption text": "キャプションテキストを入力",
  "Add Text": "テキストを追加",
  "Delete Selection": "選択を削除",
  "At least 1 image is required": "少なくとも1枚の画像が必要です",
  "Maximum 5 creatives are allowed":
    "最大5つのクリエイティブが許可されています",
  "Failed to upload image": "画像のアップロードに失敗しました",
  "Generate New Image": "新しい画像を生成",
  "Upload Image": "画像をアップロード",
  "Are you sure you want to delete this image?":
    "この画像を削除してもよろしいですか？",
  "Are you sure you want to publish the campaign?":
    "キャンペーンを公開してもよろしいですか？",
  "Publish Campaign": "キャンペーンを公開",
  "Describe your business...": "あなたのビジネスについて説明してください...",
  Generate: "生成",
  Saved: "保存済み",
  Body: "本文",
  Delay: "遅延",
  "You have the option to set up a series of automated messages for leads who remain inactive at this stage. Each message will be dispatched if a lead's status remains unchanged in this particular stage for a duration exceeding the time limit you specify in hours. This feature is to enable consistent communication and engagement with leads throughout the marketing pipeline.":
    "このステージで非アクティブなリードに対して、一連の自動メッセージを設定するオプションがあります。リードのステータスがこの特定のステージで、指定した時間（時間単位）の制限を超えて変更されない場合に、各メッセージが送信されます。この機能は、マーケティングパイプライン全体でリードとの一貫したコミュニケーションとエンゲージメントを促進するために用意されています。",
  "This message will be sent {{hours}} hour(s) after lead stays idle in this stage.":
    "このメッセージは、リードがこのステージで{{hours}}時間アイドル状態の後に送信されます。",
  "Optins (Leads)": "オプトイン（リード）",
  "New Leads (Optin)": "新しいリード（オプトイン）",
  "{{label}}/ Optin": "{{label}}/ オプトイン",
  Refresh: "リフレッシュ",
  Engaged: "接触済み",
  Nurtured: "育成済み",
  Webinar: "ウェビナー",
  Converted: "変換済み",
  Lost: "失った",
  "No data available for the selected timeframe.":
    "選択された期間にはデータがありません。",
  "Meta Ads": "Meta広告",
  "Ad Spent / Conversion": "広告費/コンバージョン",
  "Google Ads": "Google 広告",
  "Funnel Library": "ファネルライブラリ",
  Marketing: "マーケティング",
  Partnership: "パートナーシップ",
  "Confirm Overwrite": "上書きを確認",
  "By applying this template, your current funnel configuration including stages, automated emails, and workflow settings will be replaced. All leads will be moved to the 'Uncategorized' column. Are you sure you want to proceed?":
    "このテンプレートを適用すると、ステージ、自動メール、ワークフロー設定を含む現在のファネル設定が置き換えられます。すべてのリードが「未分類」列に移動します。続行してもよろしいですか？",
  Continue: "続行",
  "The funnel template library is a curated collection of pre-built funnel configurations designed to streamline and standardize processes such as sales, marketing, or partnership engagement. It offers a range of funnel templates that encapsulate best practices and successful strategies, allowing for quick implementation and ensuring consistent approaches across different scenarios.":
    "ファネルテンプレートライブラリは、販売、マーケティング、パートナーシップエンゲージメントなどのプロセスを合理化および標準化するために設計された、事前に構築されたファネル構成のキュレーションされたコレクションです。ベストプラクティスや成功した戦略を反映したさまざまなファネルテンプレートを提供し、迅速な実装と異なるシナリオでの一貫したアプローチを確保します。",
  "Email Library": "メールライブラリ",
  "All copies": "すべてのコピー",
  Membership: "メンバーシップ",
  Event: "イベント",
  Feedback: "フィードバック",
  Freebie: "無料特典",
  Meeting: "ミーティング",
  "Recruiter Prospecting": "リクルーター見込み客検索",
  "Back to Funnels": "ファネルに戻る",
  "Email Sequencing": "メール連続送信",
  EEODC: "EEODC",
  "EEO Data Collection": "EEOデータ収集",
  "The following questions are part of our commitment to ensuring equal opportunities. Participation in this data collection is entirely voluntary, and all questions are optional. Your responses will not influence any individual hiring decisions. Instead, the data collected will be aggregated across multiple participants. This process is designed exclusively for our internal use, to monitor our progress towards achieving specific goals related to ensuring equal employment opportunities. Should you prefer not to respond to any query, please feel free to proceed to the next section of the form.":
    "以下の質問は、当社が平等な機会を確保するための取り組みの一環です。このデータ収集への参加は完全に任意であり、すべての質問に答える必要はありません。あなたの回答は、個々の採用決定に影響を与えることはありません。収集されたデータは、複数の参加者の情報を集計して使用します。このプロセスは、平等な雇用機会の実現に向けた進捗を内部で監視するために設計されています。どの質問にも回答したくない場合は、フォームの次のセクションに進んでください。",
  "Contact Information": "連絡先情報",
  "Frequently Asked Questions": "よくある質問",
  "+ {{teamPrice}} per team mate": "+ チームメイト1人あたり{{teamPrice}}",
  "Get started with the {{name}} plan for {{price}}":
    "{{price}}で{{name}}プランを開始",
  Pricing: "料金",
  Plan: "プラン",
  "Basic analytics": "基本分析",
  "Email support": "メールサポート",
  "Enhanced analytics": "高度な分析",
  "Priority support": "優先サポート",
  "Dedicated account manager": "専任アカウントマネージャー",
  "quick links": "クイックリンク",
  "Consent Preferences": "同意設定",
  DSAR: "DSAR",
  "Do Not Sell or Share My Personal information":
    "私の個人情報を売却したり共有したりしないでください。",
  "Limit the Use Of My Sensitive Personal Information":
    "私の機微な個人情報の利用を制限してください。",
  "Copyright © {{year}} {{brandName}}. All rights reserved.":
    "著作権 © {{year}} {{brandName}}. 全著作権所有。",
  "AAWAI Corporation": "AAWAI株式会社",
  "5-15-10-902, Yoyogi, Shibuya, Tokyo, Japan":
    "〒151-0052 東京都渋谷区代々木5-15-10-902",
  "Done! Here is the link: {{link}}":
    "完了しました！リンクはこちらです: {{link}}",
  "Upload Files": "ファイルをアップロード",
  "Uploaded image": "アップロードされた画像",
  "Search Leads": "リードを検索",
  "Preview Landing Page": "ランディングページをプレビュー",
  "Hide Rejected": "拒否されたものを非表示にする",
  "Cleanup Rejected": "拒否されたものを整理する",
  "Please close the active tab": "アクティブなタブを閉じてください",
  "This column cannot be moved": "この列は移動できません",
  "Cannot move lead into uncategorized": "リードを未分類に移動できません",
  "Not so quick! Try again in a few seconds.":
    "少々お待ちください！数秒後にもう一度お試しください。",
  "LinkedIn URL (optional)": "LinkedIn URL（任意）",
  "This will unrecoverably delete all leads that are marked as unqualified in this funnel":
    "これにより、このファネルで不適格とマークされたすべてのリードが完全に削除されます。",
  "This column cannot be removed. You need to categorize or delete all of the leads.":
    "この列は削除できません。すべてのリードを分類または削除する必要があります。",
  "You are about to delete a stage (column). All of the corresponding applicants will be safely moved to the uncategorized column.":
    "ステージ（列）を削除しようとしています。この操作により、対応するすべての応募者は安全に未分類の列に移動されます。",
  "Hire Fast": "スピーディーに採用",
  "Promote for a day": "1日間プロモート",
  "Hire Better": "より良い採用",
  "Promote for a week": "1週間プロモート",
  "Hire Best": "最高の採用",
  "Promote for 2 weeks": "2週間プロモート",
  "10-100 leads": "10-100リード",
  "Live for 1 days": "1日間ライブ",
  "Fill the funnel fast": "ファネルを迅速に埋める",
  "100-1000 leads": "100-1000リード",
  "Live for 7 days": "7日間ライブ",
  "Find a great fit for the position": "ポジションに最適な人材を見つける",
  "600-2000 leads": "600-2000リード",
  "Live for 14 days": "14日間ライブ",
  "Find the best fit for the position": "ポジションに最適な人材を見つける",
  "Search Programs": "プログラムを検索",
  Preview: "プレビュー",
  LTS: "LTS",
  Ads: "広告",
  "Are you sure to duplicate this funnel?":
    "このファネルを複製してもよろしいですか？",
  "Duplicate Funnel": "ファネルを複製",
  Duplicate: "複製",
  "Promote now": "今すぐ宣伝",
  "Are you sure to promote? You will be charged ${{price}}.":
    "プロモートしてもよろしいですか？${{price}}が請求されます。",
  "General Information": "一般情報",
  "Job name": "ジョブ名",
  Dentist: "歯科医",
  "Scroll stopper": "スクロールストッパー",
  "Enter the scroll stopper text": "スクロールストッパーテキストを入力",
  "Hero title": "ヒーロータイトル",
  "Enter title on top of your funnel": "ファネルの上部にタイトルを入力",
  Requirements: "要件",
  "Enter the required skills": "必要なスキルを入力",
  "Preferred Work Location": "希望勤務地",
  "Enter your preferred work location": "希望勤務地を入力",
  "Engagement Type": "エンゲージメントタイプ",
  Permanent: "常勤",
  Temporary: "一時的",
  "Fixed-Term": "期間限定",
  Freelance: "フリーランス",
  "Project-based": "プロジェクト単位",
  Retainer: "報酬契約",
  Seasonal: "期間限定",
  Internship: "インターンシップ",
  Apprenticeship: "見習い",
  "Commission-Based": "歩合制",
  "Contract-to-Hire": "契約から正社員へ",
  "Contract Type": "契約社員",
  "Full-time": "フルタイム",
  "Part-time": "パートタイム",
  Casual: "カジュアル",
  "Zero-Hours": "ゼロ時間",
  "Profit-share": "利益分配",
  "Calendly Event Type": "Calendlyイベントタイプ",
  "Benefits & Culture": "福利厚生と文化",
  "Benefits Title": "福利厚生タイトル",
  "Title for Benefits Section": "福利厚生セクションのタイトル",
  "Benefits Description": "福利厚生の説明",
  "Describe the benefits of working with us":
    "当社と働くメリットを説明してください",
  Benefits: "メリット",
  "Title of this benefit": "メリットのタイトル",
  "Description of this benefit": "メリットの説明",
  Icon: "アイコン",
  Emoji: "絵文字",
  Testimonials: "お客様の声",
  "Testimonial Section Title": "推薦文セクションのタイトル",
  "Enter the title for the testimonial section":
    "推薦文セクションのタイトルを入力",
  Fullname: "フルネーム",
  "Fullname of the team member": "チームメンバーのフルネーム",
  "The position of the team member": "チームメンバーの役職",
  Testimonial: "推薦文",
  "Application Submission": "応募提出",
  "Call to Action": "行動を促すメッセージ",
  "Call to action for application submission": "応募を促すメッセージ",
  "Post-Application": "応募後",
  "Thank You Message": "ありがとうメッセージ",
  "Enter the thank you message post-application":
    "応募後のありがとうメッセージを入力",
  "Application Under Review Information": "応募情報の審査状況",
  "Information about the application review process":
    "応募審査プロセスに関する情報",
  "Next Steps After Application": "応募後の次のステップ",
  "We are hiring {{jobName}}": "{{jobName}}を採用しています",
  "Specify a unique Calendly event type for each funnel, or leave blank to use the default account setting for interviews.":
    "各ファネルに対してユニークなCalendlyイベントタイプを指定するか、面接のデフォルトアカウント設定を使用するために空白のままにしてください。",
  "What does the team member think about the company?":
    "チームメンバーは会社についてどう思っていますか？",
  "Describe the next steps after the application is submitted":
    "応募完了後の次のステップを説明してください",
  "Preview Your Form": "フォームをプレビュー",
  "View a lead's perspective of the form on the left.":
    "左側にリードの視点からフォームを表示します。",
  "Customize Steps": "ステップをカスタマイズ",
  "Publish Funnel": "ファネルを公開",
  "Ready to go live? Publish your funnel here.":
    "ライブ開始の準備はできましたか？ここでファネルを公開してください。",
  "Edit Funnel Settings": "ファネル設定を編集",
  "Back to funnel settings": "ファネル設定に戻る",
  "On the right, edit steps or form elements within each step.":
    "右側で各ステップやフォーム要素を編集します。",
  "Business setup": "ビジネス設定",
  "Create your first funnel": "最初のファネルを作成",
  "Setup your target customer": "ターゲット顧客を設定",
  "Connect your email sender": "メール送信者を接続",
  "Configure your first funnel": "最初のファネルを構成",
  "Setup your advertisement configuration": "広告設定を行う",
  "Create your first ad": "最初の広告を作成",
  "Welcome, {{name}}...": "ようこそ、{{name}}...",
  "Your next steps": "次のステップ",
  "{{completed}} of {{total}}": "{{completed}} / {{total}}",
  "Setup Target Customer": "ターゲット顧客を設定",
  "Setup Advertisement Configuration": "広告設定を行う",
  "Catch Copy": "キャッチコピー",
  "Automate all your sales for free!": "すべての販売を無料で自動化！",
  "Ad Size (px x px)": "広告サイズ（px x px）",
  "Select Brand Tone of Voice": "ブランドのトーンを選ぶ",
  "Select your brand color:": "ブランドカラーを選択してください:",
  Other: "その他",
  "Friendly & Conversational": "フレンドリーで会話的",
  "Professional & Authoritative": "プロフェッショナルで権威的",
  "Innovative & Inspirational": "革新的でインスピレーションを与える",
  "Quirky & Humorous": "風変わりでユーモアがある",
  "General Workflow Configuration": "一般的なワークフロー設定",
  "When a lead applies, automatically assign them to the stage":
    "リードを応募すると、自動的にそのステージに割り当てます。",
  None: "なし",
  "Workflow Configuration for": "のワークフロー設定",
  "When lead enters stage {{stageName}}, send them":
    "リードがステージ{{stageName}}に入ると、送信する",
  "an automated email": "自動化されたメール",
  "Change Email Template": "メールテンプレートを変更",
  "an automated SMS": "自動化されたSMS",
  "Change SMS Template": "SMSテンプレートを変更",
  "an AI call": "AIコール",
  "Change Call Script": "コールスクリプトを変更",
  "Webhook URL": "Webhook URL",
  "Webhook URL successfully updated": "Webhook URLが正常に更新されました",
  "When lead enters stage {{stageName}}, mark them as":
    "リードがステージ{{stageName}}に入ると、以下としてマークする",
  engaged: "エンゲージ済み",
  nurtured: "育成済み",
  "not nurtured": "育成されていない",
  "scheduled for sales call": "営業コールが予定されています",
  "not scheduled for sales call": "営業コールが予定されていません",
  converted: "コンバート済み",
  lost: "失った",
  Days: "日",
  Hours: "時間",
  Minutes: "分",
  "{{days}} day(s)": "{{days}}日",
  "{{hours}} hour(s)": "{{hours}}時間",
  "{{minutes}} minute(s)": "{{minutes}}分",
  "When you send a meeting request to lead, automatically assign them to the stage":
    "リードにミーティングリクエストを送信すると、自動的にステージに割り当てる",
  "When lead scheduled a meeting, automatically assign them to the stage":
    "リードがミーティングを予定すると、自動的にステージに割り当てる",
  "When a scheduled meeting has been cancelled, automatically assign lead to the stage":
    "予定されたミーティングがキャンセルされた場合、自動的にリードをステージに割り当てる",
  "When I mark lead as unqualified, automatically assign them to the stage":
    "リードを不適格としてマークすると、自動的にステージに割り当てる",
  "When lead enters stage {{stageName}}, push lead data into an external webhook":
    "リードがステージ{{stageName}}に入ると、リードデータを外部Webhookにプッシュする",
  "When leads stay too long in stage {{stageName}}, move them into":
    "リードがステージ{{stageName}}に長く留まる場合、以下に移動する",
  "Candidates will be moved after {{time}} of idle time waiting in this stage.":
    "{{time}}のアイドル時間後に候補者がこのステージに待機している場合、移動されます。",
  "No file selected.": "ファイルが選択されていません。",
  Timestamp: "タイムスタンプ",
  Recording: "録音",
  "Lead Folder": "リードフォルダー",
  "AI Call Results": "AIコール結果",
  "Interview Notes": "面接ノート",
  Summary: "概要",
  Transcript: "トランスクリプト",
  "No logs": "ログがありません",
  "Edit Contact": "連絡先を編集",
  "Application Form": "応募フォーム",
  EEO_DATA_COLLECTION_MESSAGE: "EEOデータ収集メッセージ",
  "Lead has not submitted the application form":
    "リードは応募フォームを提出していません",
  "Email History": "メール履歴",
  System: "システム",
  "Lead did not receive any emails from you":
    "リードはあなたからメールを受け取っていません",
  "SMS History": "SMS履歴",
  "Lead did not receive any SMS messages from you":
    "リードはあなたからSMSメッセージを受け取っていません",
  Tracking: "トラッキング",
  Logs: "ログ",
  "Performed by": "実行者",
  "This lead has been marked as unqualified. Provided reason: {{reason}}":
    "このリードは不適格としてマークされています。提供された理由: {{reason}}",
  "Failed to fetch triggers": "トリガーの取得に失敗しました",
  "Trigger added successfully": "トリガーが正常に追加されました",
  "Failed to add trigger": "トリガーの追加に失敗しました",
  "Email template saved successfully":
    "メールテンプレートが正常に保存されました",
  "Failed to save email template": "メールテンプレートの保存に失敗しました",
  "Trigger deleted successfully": "トリガーが正常に削除されました",
  "Failed to delete trigger": "トリガーの削除に失敗しました",
  "Add New Trigger": "新しいトリガーを追加",
  "Are you sure you want to delete this trigger?":
    "このトリガーを削除してもよろしいですか？",
  "Trigger Type": "トリガータイプ",
  "Please select a trigger type": "トリガータイプを選択してください",
  "Select a trigger type": "トリガータイプを選択",
  Optin: "オプトイン",
  Engagement: "エンゲージメント",
  Appointment: "アポイントメント",
  "Add Trigger": "トリガーを追加",
  "Edit Email Template": "メールテンプレートを編集",
  immediately: "すぐに",
  "Leads will receive the message {{timing}} after trigger.":
    "リードはトリガー後{{timing}}にメッセージを受け取ります。",
  "after {{days}} day(s) {{hours}} hour(s) {{minutes}} minute(s)":
    "{{days}}日{{hours}}時間{{minutes}}分後",
  "Page #{{number}}": "ページ #{{number}}",
  "Select the step to forward user into after form submission":
    "フォーム送信後にユーザーが転送されるステップを選択してください",
  Open: "開く",
  "Clear Selection": "選択をクリア",
  "Add Step": "ステップを追加",
  Typography: "タイポグラフィ",
  Borders: "境界線",
  "Spacing and Sizing": "間隔とサイズ",
  "Flexbox & Grid": "Flexbox＆Grid",
  Background: "背景",
  "Font Family": "フォントファミリー",
  "Font Size": "フォントサイズ",
  "Font Style": "フォントスタイル",
  "Font Weight": "フォントウェイト",
  "Letter Spacing": "文字間隔",
  "Line Height": "行間",
  "Text Align": "テキスト揃え",
  "Text Color": "テキストカラー",
  "Text Decoration": "テキスト装飾",
  "Text Transform": "テキスト変換",
  "Text Overflow": "テキストオーバーフロー",
  "Text Indent": "テキストインデント",
  Whitespace: "空白",
  "Word Break": "単語区切り",
  "Border Radius": "ボーダー半径",
  "Border Width": "ボーダー幅",
  "Border Style": "ボーダースタイル",
  "Border Color": "ボーダーカラー",
  Width: "幅",
  Height: "高さ",
  "Minimum Width": "最小幅",
  "Maximum Width": "最大幅",
  "Minimum Height": "最小高さ",
  "Maximum Height": "最大高さ",
  "Flex Basis": "フレックス基準",
  "Flex Direction": "フレックス方向",
  "Flex Wrap": "フレックスラップ",
  Flex: "フレックス",
  "Flex Grow": "フレックス成長",
  "Flex Shrink": "フレックス縮小",
  Order: "順序",
  "Grid Template Columns": "グリッドテンプレート列",
  "Grid Column Start / End": "グリッド列開始/終了",
  "Grid Template Rows": "グリッドテンプレート行",
  "Grid Row Start / End": "グリッド行開始/終了",
  "Grid Auto Flow": "グリッド自動フロー",
  "Grid Auto Columns": "グリッド自動列",
  "Grid Auto Rows": "グリッド自動行",
  Gap: "ギャップ",
  "Justify Content": "コンテンツを正当化",
  "Justify Items": "アイテムを正当化",
  "Justify Self": "自己を正当化",
  "Align Content": "コンテンツを揃える",
  "Align Items": "アイテムを揃える",
  "Align Self": "自身を揃える",
  "Place Content": "コンテンツを配置",
  "Place Items": "アイテムを配置",
  "Place Self": "自身を配置",
  "Background Attachment": "背景のアタッチメント",
  "Background Clip": "背景クリップ",
  "Background Color": "背景色",
  "Text Size": "テキストサイズ",
  "Background Origin": "背景オリジン",
  "Background Position": "背景位置",
  "Background Repeat": "背景の繰り返し",
  "Background Size": "背景サイズ",
  "Background Image": "背景画像",
  Layout: "レイアウト",
  Direction: "方向",
  Row: "行",
  "Row Reverse": "行逆",
  Column: "列",
  "Column Reverse": "列逆",
  Justify: "正当化",
  Start: "開始",
  Center: "中央",
  End: "終了",
  Between: "間",
  Around: "周囲",
  Evenly: "均等",
  Align: "揃える",
  Baseline: "ベースライン",
  Stretch: "ストレッチ",
  "Hide Advanced Options": "高度なオプションを隠す",
  "Show Advanced Options": "高度なオプションを表示",
  "Image Attributes": "画像属性",
  "Image Width": "画像幅",
  "Image Height": "画像高さ",
  Text: "テキスト",
  "Text Content": "テキスト内容",
  "Tailwind CSS Class": "Tailwind CSSクラス",
  "New Text": "新しいテキスト",
  "Nothing to duplicate within this component":
    "このコンポーネント内に複製するものがありません",
  Image: "画像",
  Component: "コンポーネント",
  "Edit Component": "コンポーネントを編集",
  "Insufficient leads in queue": "キュー内のリードが不十分です",
  "Stage not found": "ステージが見つかりません",
  "Partner or user not found": "パートナーまたはユーザーが見つかりません",
  "Unable to access partner": "パートナーにアクセスできません",
  "Partner is not activated": "パートナーが有効化されていません",
  "Unable to access partner DB": "パートナーのデータベースにアクセスできません",
  "Partner Stripe is not setup": "パートナーのStripeが設定されていません",
  "Please provide a prospect": "見込み客を入力してください",
  "Prospect does not have a valid email":
    "見込み客に有効なメールアドレスが設定されていません",
  "Subject line is too long": "件名が長すぎます",
  "Vacancy not found": "空き状況が見つかりません",
  "As a team member, you cannot promote funnels":
    "チームメンバーとして、ファネルをプロモートできません",
  "Please specify the funnel": "ファネルを指定してください",
  "Feature is disabled": "機能が無効です",
  "Funnel not found": "ファネルが見つかりません",
  "There is already an active promotion for this funnel":
    "このファネルにはすでにアクティブなプロモーションがあります",
  "Tier not found": "ティアが見つかりません",
  "Partner stripe is not configured": "パートナーのStripeが設定されていません",
  "Vacancy does not exist": "空き状況が存在しません",
  "Template not found": "テンプレートが見つかりません",
  "Please provide the candidateId": "candidateIdを入力してください",
  "Unable to find the lead": "リードを見つけることができません",
  "Invalid lead ID": "無効なリードID",
  "Out of service": "サービス停止中",
  "Password must contain minimum 8 characters":
    "パスワードは8文字以上である必要があります。",
  "Master user not found": "マスターユーザーが見つかりません",
  "Subscription is required (tier)": "サブスクリプションが必要です（ティア）",
  "Subscription is required (id)": "サブスクリプションが必要です（ID）",
  "Subscription not found": "サブスクリプションが見つかりません",
  "Subscription item is not found":
    "サブスクリプションアイテムが見つかりません",
  "Interval is not specified": "インターバルが指定されていません",
  "Pricing specification not found": "価格仕様が見つかりません",
  "Invalid email or password": "無効なメールアドレスまたはパスワード",
  Unable: "できません",
  "Your latest OTP is not expired yet":
    "最新のOTP（ワンタイムパスワード）はまだ有効です。",
  "Invalid OTP": "無効なOTP（ワンタイムパスワード）です。",
  "Wrong password": "パスワードが間違っています",
  "Repeated password does not match": "繰り返しのパスワードが一致しません",
  "No access": "アクセス権がありません",
  "Minimum Purchase Amount must be $10 or higher":
    "最低購入金額は$10以上である必要があります",
  "Resale factor for lead sourcing must be 1.5 or higher":
    "リードソーシングの再販係数は1.5以上である必要があります",
  "User not found": "ユーザーが見つかりません",
  "Wrong code": "コードが間違っています",
  "Your code has expired": "あなたのコードは期限切れです",
  "As a team member, you cannot perform this action":
    "チームメンバーとして、このアクションを実行できません",
  "No subscription to cancel": "キャンセルするサブスクリプションがありません",
  "Please provide a valid email": "有効なメールアドレスを入力してください",
  "Email is already in use": "メールアドレスはすでに使用されています",
  "Invalid password": "無効なパスワード",
  "This service is currently not available": "このサービスは現在利用できません",
  "Calendly is currently disabled": "Calendlyは現在無効になっています",
  "Super admin not found": "スーパー管理者が見つかりません",
  "Invalid user role": "無効なユーザーロール",
  "User has no access token": "ユーザーにアクセストークンがありません",
  "Could not upload the image": "画像をアップロードできませんでした",
  "Access blocked!": "アクセスがブロックされました！",
  "Invalid link": "無効なリンク",
  "CV not found": "履歴書が見つかりません",
  "No active customer account found":
    "アクティブな顧客アカウントが見つかりません",
  "Either locationIds or vacancyLocation must be provided":
    "locationIdsまたはvacancyLocationのいずれかを入力する必要があります",
  "descriptions must be provided": "説明を入力する必要があります",
  "headlines must be provided": "見出しを入力する必要があります",
  "keywords must be provided": "キーワードを入力する必要があります",
  "Maximum length for path is 15 characters": "パスワードは最長で15文字です。",
  "Please ensure all of the provided final URLs are valid":
    "入力されたすべての最終URLが有効であることを確認してください",
  "Google connection has expired": "Google接続の有効期限が切れました",
  "You must create a customer account in Google Ads":
    "Google Adsで顧客アカウントを作成する必要があります",
  "Lead not found": "リードが見つかりません",
  "Lead does not have phone number": "リードに電話番号がありません",
  "Lead does not have email": "リードにメールアドレスがありません",
  "Invalid candidate ID": "無効な候補者ID",
  "Already published": "すでに公開されています",
  "Domain already registered": "ドメインはすでに登録されています",
  "This link has expired": "このリンクの有効期限が切れています",
  "Calendar connection not available": "カレンダー接続が利用できません",
  "Partner not found": "パートナーが見つかりません",
  "You have already signed": "すでにサインインしています",
  Unauthorized: "認証されていません",
  "SMTP not specified": "SMTPが指定されていません",
  "Please provide an email": "メールアドレスを入力してください",
  "Firstname is required": "名が必要です",
  "Lastname is required": "姓が必要です",
  "This email is not available": "このメールは利用できません",
  "Ad creative is required": "広告クリエイティブが必要です",
  "Access blocked!!": "アクセスがブロックされました！！",
  "Calendly is not connected": "Calendlyが接続されていません",
  "Not connected": "接続されていません",
  "SMS has been sent successfully": "SMSが正常に送信されました",
  "Email has been sent successfully": "メールが正常に送信されました",
  "Successfully forwarded the resumes": "履歴書を正常に転送しました",
  "Lead has scheduled a meeting": "リードがミーティングを予定しました",
  "Sent calendar scheduling request to candidate":
    "候補者にカレンダーのスケジュールリクエストを送信しました",
  "Sent calendar scheduling request to lead":
    "リードにカレンダーのスケジュールリクエストを送信しました",
  "You are not at the required job location": "求められる勤務地にいません。",
  "Your skill set does not match our current needs":
    "あなたのスキルセットは当社の現在のニーズと一致しません",
  "Your application did not show the required level of expertise":
    "あなたの応募は必要な専門知識レベルを示していません",
  "We require more proficiency in certain software or tools":
    "特定のソフトウェアやツールにおいて、より高い熟練度が必要です",
  "We are seeking a lead with a different educational background.":
    "異なる教育背景を持つリードを求めています。",
  "We need a lead who is available to work different hours":
    "異なる時間帯に働けるリードが必要です",
  "Your salary expectations exceed the budget for this position":
    "あなたの給与期待額はこのポジションの予算を超えています",
  "We need someone with more experience in a similar role":
    "類似の役割での経験が豊富な方を求めています。",
  "We are looking for a lead who has a more extensive portfolio.":
    "より充実したポートフォリオを持つリードを探しています",
  "We require a lead with a longer commitment to the next role.":
    "次の役割に対してより長期的なコミットメントを持つリードが必要です",
  "Deleted successfully": "正常に削除されました",
  "Lead updated successfully": "リードが正常に更新されました",
  "You need to put your payment details on file":
    "支払い詳細をファイルに登録する必要があります",
  "Funnel has been successfully promoted":
    "ファネルのプロモーションが成功しました。",
  "CV has been submit manually": "履歴書が手動で提出されました",
  "Welcome aboard!": "ご加入いただきありがとうございます！",
  "Please check your email inbox": "メールの受信箱を確認してください",
  "Password reset successfully": "パスワードが正常にリセットされました",
  "Password changed successfully": "パスワードが正常に変更されました",
  "Upgrade successful": "アップグレードに成功しました",
  "Email has been updated": "メールが更新されました",
  "Ticket closed": "チケットが閉じられました",
  "Permission denied": "許可が拒否されました",
  "CV has been submit by candidate": "履歴書が候補者によって提出されました",
  "CV has been successfully forwarded to the hiring manager":
    "履歴書が採用担当者に正常に転送されました",
  "Error getting user statistics": "ユーザー統計の取得エラー",
  "We have forwarded the CV page to your email":
    "履歴書ページをメールに転送しました",
  "You have successfully signed the document":
    "ドキュメントへの署名が完了しました。",
  Success: "成功",
  "Invitation sent successfully": "招待が正常に送信されました",
  "Wrong model": "間違ったモデル",
  "What is the name of your business?": "あなたの会社の名前は何ですか?",
  "Please describe your product or service":
    "製品またはサービスについて説明してください",
  "What are your pricing plans?": "料金プランは何ですか?",
  "What are your benefits?": "メリットは何ですか?",
  "Who is your audience?": "オーディエンスは誰ですか?",
  "{{step1}} of {{step2}}": "ステップ {{step1}}/{{step2}}",
  "What is your brand's tone and voice?": "ブランドのトーンと声は何ですか?",
  "AI Suggestion:": "AI の提案:",
  Use: "使用",
  "Does it look right?": "これで合っていますか？",
  "Company Description": "会社説明",
  "Customer Difficulties": "顧客の課題",
  "Pricing Details": "価格の詳細",
  "Which layout do you like more?": "どのレイアウトが好きですか?",
  Default: "デフォルト",
  Secondary: "セカンダリ",
  "Your Purpose": "目的",
  "Who will you be building websites for?":
    "誰のために Web サイトを構築しますか?",
  "Clients as a freelancer": "フリーランサーの顧客",
  "Clients as part of an agency": "代理店の顧客",
  "My company or business": "自身の会社やビジネス",
  "The company I work for": "自身が勤める会社のため",
  Others: "その他",
  "How You Found": "どのように見つけましたか",
  "How did you hear about SalesAI?":
    "THESALESAIについてどのように知りましたか？",
  "Friend / Colleague": "友人/同僚",
  Twitter: "Twitter",
  Youtube: "Youtube",
  Instagram: "Instagram",
  Tiktok: "Tiktok",
  Facebook: "Facebook",
  "Facebook Ads": "Facebook 広告",
  Google: "Google",
  "Other Search Engines": "その他の検索エンジン",
  "Role Description": "役職の詳細",
  "What best describes your role?": "あなたの役職に近いものは何ですか?",
  "Small to medium size business owner": "中小企業のオーナー",
  Enterprener: "起業家",
  "Large business owner": "大企業のオーナー",
  "Marketing agency": "マーケティング代理店",
  Marketer: "マーケティング担当者",
  "Web designer": "Web デザイナー",
  "What are the customer pain points who would like to use your service?":
    "あなたのサービスを利用する顧客が抱える悩みは何ですか？",
  "We provide our customers an AI system to automate their lead generation and sales.":
    "当社は、リード生成と販売を自動化する AI システムを顧客に提供しています。",
  "The main features are: Creating customized sales automation funnels for clients to streamline their sales process and increase conversions.":
    "主な機能は次のとおりです。クライアント向けにカスタマイズされた販売自動化ファネルを作成し、販売プロセスを合理化してコンバージョンを向上させます。",
  "While we work on creating a customized sales strategy for your business, please take a few seconds to answer the survey to move on.":
    "お客様のビジネスに合わせたカスタマイズされた販売戦略を作成するために、アンケートにご回答ください。",
  "We are curious to know more about you to personalize your experience even more!":
    "顧客体験をより最適化するために、お客様についてもう少し教えてください。",
  "Myself (personal brand or portfolio)":
    "私自身（個人ブランドまたはポートフォリオ）",
  Features: "機能",
  "Customer Struggles": "顧客の悩み",
  CTA: "CTA",
  "Features Slider": "機能スライダー",
  Divider: "区切り線",
  "Lead Form": "リードフォーム",
  "Value Proposition": "価値提案",
  HeadlineComponent: "見出しコンポーネント",
  Hero: "ヒーローセクション",
  "+ Add Message": "+ メッセージを追加",

  "Failed to fetch assistants": "アシスタントを取得できませんでした",
  "Failed to fetch phone numbers": "電話番号を取得できませんでした",
  "Assistant deleted successfully": "アシスタントが正常に削除されました",
  "Failed to delete assistant": "アシスタントを削除できませんでした",
  "Assistant updated successfully": "アシスタントが正常に更新されました",
  "Assistant created successfully": "アシスタントが正常に作成されました",
  "Failed to submit assistant": "アシスタントを送信できませんでした",
  "Phone number added successfully": "電話番号が正常に追加されました",
  "Failed to add phone number": "電話番号を追加できませんでした",
  "Assistant activated successfully":
    "アシスタントが正常にアクティブ化されました",
  "Failed to activate assistant": "アシスタントをアクティブ化できませんでした",
  Name: "名前",
  "First Message": "最初のメッセージ",
  Language: "言語",
  Gender: "性別",
  Male: "男性",
  Speed: "速度",
  Actions: "アクション",
  Edit: "編集",
  Delete: "削除",
  Status: "ステータス",
  Active: "アクティブ",
  Activate: "アクティブ化",
  "Call Agents": "エージェントに電話",
  "Create New Assistant": "新しいアシスタントを作成",
  "Edit Assistant": "アシスタントを編集",
  "Please input the name": "名前を入力してください",
  "Please input the first message": "最初のメッセージを入力してください",
  "Please select the gender": "性別を選択してください",
  Female: "女性",
  "Please select the language": "言語を選択してください",
  "Please input the speed": "速度を入力してください",
  "Task Description": "タスクの説明",
  "Please input the task description": "タスクの説明を入力してください",
  Update: "更新",
  "Add Phone Number": "電話番号を追加",
  "Phone Number": "電話番号",
  "Please input the phone number": "電話番号を入力してください",
  "Twilio Account SID": "Twilio アカウント SID",
  "Please input the Twilio Account SID":
    "Twilio アカウント SID を入力してください",
  "Twilio Auth Token": "Twilio 認証トークン",
  "Please input the Twilio Auth Token": "Twilio 認証トークンを入力してください",
  "Name (Optional)": "名前 (オプション)",
  "Activate Assistant": "アシスタントをアクティブ化",
  "Select a phone number": "電話番号を選択",
  "Create Assistant": "アシスタントを作成",
  Create: "作成",

  "Forward Call Number": "転送番号",
  "Please input the number to forward to if the customer needs personal support":
    "顧客が個人的なサポートを必要とする場合に転送する番号を入力してください",
  "Enter coupon code": "クーポンコードを入力",

  "Connect your Google calendar": "Google カレンダーを接続する",
  "Google Calendar Connection": "Google カレンダー接続",

  "Google Calendar is connected.": "Google カレンダーが接続されています。",
  "Connect Google Calendar": "Google カレンダーを接続",
  "Outbound Calling": "発信通話",
  "Phone Configuration": "電話の設定",
  Webinars: "ウェビナー",

  "  Failed to fetch webinars": "ウェビナーの取得に失敗しました",
  "Failed to fetch participants": "参加者の取得に失敗しました",
  "Webinar created successfully": "ウェビナーの作成に成功しました",
  "Failed to create webinar": "ウェビナーの作成に失敗しました",
  Title: "タイトル",
  Date: "日付",
  Participants: "参加者",
  Status: "ステータス",
  Completed: "完了",
  Upcoming: "今後の予定",
  Actions: "アクション",
  "Webinar link copied to clipboard":
    "ウェビナー リンクがクリップボードにコピーされました",
  "Copy Link": "リンクをコピー",
  Webinars: "ウェビナー",
  "Create Webinar": "ウェビナーの作成",
  "Create Webinar": "ウェビナーの作成",
  "Webinar Title": "ウェビナー タイトル",
  "Please input webinar title": "ウェビナー タイトルを入力してください",
  Description: "説明",
  "Please input description": "説明を入力してください",
  "Scheduled Date": "予定日",
  "Please select date": "日付を選択してください",
  "Select Participants": "参加者の選択",
  "Please select participants": "参加者を選択してください",
  "Select participants": "参加者を選択してください",
  "Upload Webinar Video": "ウェビナー ビデオのアップロード",
  "Please upload video": "ビデオをアップロードしてください",
  "Video uploaded successfully": "ビデオのアップロードに成功しました",
  "Click or drag video to upload":
    "ビデオをクリックまたはドラッグしてアップロードしてください",
  "Create Webinar": "ウェビナーの作成",
  Webinar: "ウェビナー",

  "Video is required": "ビデオが必要です",
  "Days later": "数日後",
  Date: "日付",
  "Time Slots": "時間帯",
  Sunday: "日曜日",
  Monday: "月曜日",
  Tuesday: "火曜日",
  Wednesday: "水曜日",
  Thursday: "木曜日",
  Friday: "金曜日",
  Saturday: "土曜日",
  "Add Time Slot": "時間帯を追加",
  "Chat feature": "チャット機能",
  Use: "使用",
  "Don't Use": "使用しない",
  "All leads joining this funnel will become participants of this webinar automatically after this many days. You can also manually make them participants above.":
    "このファネルに参加するすべてのリードは、この日数が経過すると自動的にこのウェビナーの参加者になります。また、上記の手順で手動で参加者にすることもできます。",

  "Your browser does not support the video tag.":
    "お使いのブラウザはビデオタグをサポートしていません。",
  viewers: "視聴者",
  Comments: "コメント",
  "Post Comment": "コメントを投稿",
  You: "あなた",

  "Email Automations": "メール自動化",
  "Add Email Automation": "メール自動化の追加",
  "Email Subject": "メール件名",
  "Email Body": "メール本文",
  "Hours Before Webinar": "ウェビナーの何時間前",
  "Sending Behavior": "送信動作",

  "Webinar has ended": "ウェビナーは終了しました",
  "Thank you for your interest. The webinar is no longer available.":
    "ご関心をお寄せいただきありがとうございます。ウェビナーは利用できなくなりました。",
  "Next webinar starts on {{nextDay}} at {{nextTime}}":
    "次のウェビナーは {{nextDay}} の {{nextTime}} に開始されます",

  "Choose Stock Image": "ストック画像を選択",
  "Search for images...": "画像を検索...",
  "Phone Numbers": "電話番号",
  "Inbound Calling": "着信通話",
  "Your Phone Numbers": "あなたの電話番号",
  "Are you sure you want to remove this number?": "この番号を削除してもよろしいですか？",
  "Yes": "はい",
  "No": "いいえ",
  "Remove": "削除",
  "Agent assigned": "エージェントが割り当てられました",
  "Purchase Phone Number": "電話番号を購入",
  "Search Available Numbers": "利用可能な番号を検索",
  "Select a Number": "番号を選択",
  "Choose a phone number": "電話番号を選択",
  "Purchase Number": "番号を購入",
  "Phone Numbers": "電話番号",
  "AI Agents": "AIエージェント",
  "Agent Assignment": "エージェント割り当て",
  "Please select a number to purchase": "購入する番号を選択してください",
  "Successfully purchased number: {{phoneNumber}}": "番号の購入に成功しました: {{phoneNumber}}",
  "Successfully purchased number: {{phoneNumber}}": "番号の購入に成功しました: {{phoneNumber}}",
  "Agent created successfully": "エージェントが正常に作成されました",
  "Agent updated successfully": "エージェントが正常に更新されました",
  "Agent deleted successfully": "エージェントが正常に削除されました",
  "Language": "言語",
  "Please select a language": "言語を選択してください",
  "Select language": "言語を選択",
  "Voice Gender": "音声の性別",
  "Please select voice gender": "音声の性別を選択してください",
  "Select gender": "性別を選択",
  "Voice": "音声",
  "Please select a voice": "音声を選択してください",
  "Select a voice": "音声を選択",
  "Create AI Agent": "AIエージェントを作成",
  "Agent Name": "エージェント名",
  "Please enter agent name": "エージェント名を入力してください",
  "e.g., Sales Assistant": "例：営業アシスタント",
  "Description": "説明",
  "Brief description of what this agent does": "このエージェントの機能の簡単な説明",
  "Agent Prompt": "エージェントプロンプト",
  "Please enter agent prompt": "エージェントプロンプトを入力してください",
  "Instructions for how the agent should behave and respond": "エージェントの行動と応答方法の指示",
  "Active": "アクティブ",
  "Inactive": "非アクティブ",
  "Create Agent": "エージェントを作成",
  "Your AI Agents": "あなたのAIエージェント",
  "Edit": "編集",
  "Are you sure you want to delete this agent?": "このエージェントを削除してもよろしいですか？",
  "Delete": "削除",
  "AI Agent": "AIエージェント",
  "Status": "ステータス",
  "Prompt": "プロンプト",
  "Edit Agent": "エージェントを編集",
  "Update Agent": "エージェントを更新",
  "Assign Agent to Phone Number": "電話番号にエージェントを割り当て",
  "Phone Number": "電話番号",
  "Please select a phone number": "電話番号を選択してください",
  "Select a phone number": "電話番号を選択",
  "Please select an agent": "エージェントを選択してください",
  "Select an agent": "エージェントを選択",
  "Assign Agent": "エージェントを割り当て",
  "Current Assignments": "現在の割り当て",
  "No agent assignments yet": "エージェントの割り当てはまだありません",
  "Unknown Agent": "不明なエージェント",
  "First Message": "最初のメッセージ",
  "Please input the first message": "最初のメッセージを入力してください",
  "The first message the agent will say when a call starts": "通話開始時にエージェントが最初に話すメッセージ",
};
