"use client";

import { useState } from "react";
import {
  Card,
  Button,
  Form,
  Input,
  message,
  List,
  Popconfirm,
  Modal,
  Select,
  Switch,
  Collapse,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CallManagementService from "../../../../service/CallManagementService";
import axios from "axios";

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const LANGUAGE_VOICES = {
  en: {
    male: "s3://voice-cloning-zero-shot/a540a448-a9ca-446c-9538-d1bae6c506f1/original/manifest.json",
    female: "jennifer",
    provider: "playht"
  },
  ja: {
    male: "ja-JP-MasaruMultilingualNeural",
    female: "ja-JP-AoiNeural",
    provider: "azure"
  }
};

export default function AgentConfiguration({
  agents = [],
  onAgentCreated,
  onAgentUpdated,
  onAgentDeleted,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [selectedGender, setSelectedGender] = useState('female');

  const createAgent = async (values) => {
    setLoading(true);
    try {
      const selectedVoiceConfig = LANGUAGE_VOICES[values.language] || LANGUAGE_VOICES.en;
      const voice = selectedVoiceConfig[values.gender || 'male'];

      await CallManagementService.createAgent({
        ...values,
        language: values.language || 'en',
        voice: {
          provider: selectedVoiceConfig.provider,
          voiceId: voice
        }
      });
      message.success(t("Agent created successfully"));
      form.resetFields();
      if (onAgentCreated) onAgentCreated();
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const showEditModal = (agent) => {
    setCurrentAgent(agent);
    const voiceConfig = LANGUAGE_VOICES[agent.language || 'en'];
    
    // Get the voiceId string from the agent's voice object
    const voiceId = agent.voice?.voiceId || agent.voice;
    const gender = voiceConfig?.female?.includes(voiceId) ? 'female' : 'male';
    
    editForm.setFieldsValue({
      name: agent.name,
      description: agent.description,
      prompt: agent.prompt,
      voice: voiceId, // Set just the voiceId string
      isActive: agent.isActive,
      firstMessage: agent.firstMessage,
      language: agent.language || 'en',
      gender: gender,
      forwardNumber: agent.forwardNumber
    });
    setSelectedLanguage(agent.language || 'en');
    setSelectedGender(gender);
    setIsModalVisible(true);
  };

  const handleEditCancel = () => {
    setIsModalVisible(false);
    setCurrentAgent(null);
  };

  const updateAgent = async (values) => {
    setLoading(true);
    try {
      const selectedVoiceConfig = LANGUAGE_VOICES[values.language] || LANGUAGE_VOICES.en;
      const voice = selectedVoiceConfig[values.gender || 'male'];

      await CallManagementService.updateAgent(currentAgent._id, {
        ...values,
        voice: {
          provider: selectedVoiceConfig.provider,
          voiceId: voice
        }
      });
      message.success(t("Agent updated successfully"));
      setIsModalVisible(false);
      if (onAgentUpdated) onAgentUpdated();
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteAgent = async (agentId) => {
    try {
      await CallManagementService.deleteAgent(agentId);
      if (onAgentDeleted) onAgentDeleted();
    } catch (error) {
      console.error(error);
    }
  };

  const languageSelector = (
    <>
      <Form.Item
        name="language"
        label={t("Language")}
        rules={[{ required: true, message: t("Please select a language") }]}
      >
        <Select 
          placeholder={t("Select language")} 
          onChange={(value) => {
            setSelectedLanguage(value);
            // Reset voice when language changes
            form.setFieldValue('voice', null);
            editForm.setFieldValue('voice', null);
          }}
        >
          <Option value="en">{t("English")}</Option>
          <Option value="ja">{t("Japanese")}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="gender"
        label={t("Voice Gender")}
        rules={[{ required: true, message: t("Please select voice gender") }]}
      >
        <Select 
          placeholder={t("Select gender")}
          onChange={(value) => {
            setSelectedGender(value);
            // Reset voice when gender changes
            form.setFieldValue('voice', null);
            editForm.setFieldValue('voice', null);
          }}
        >
          <Option value="female">{t("Female")}</Option>
          <Option value="male">{t("Male")}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="voice"
        label={t("Voice")}
        rules={[{ required: true, message: t("Please select a voice") }]}
      >
        <Select 
          placeholder={t("Select a voice")}
          onChange={(voiceId) => {
            const formInstance = isModalVisible ? editForm : form;
            formInstance.setFieldsValue({
              voice: voiceId
            });
          }}
        >
          {selectedLanguage && selectedGender && (
            <Option 
              key={LANGUAGE_VOICES[selectedLanguage][selectedGender]} 
              value={LANGUAGE_VOICES[selectedLanguage][selectedGender]}
            >
              {selectedLanguage === 'ja' ? 
                (selectedGender === 'female' ? 'Aoi (Female)' : 'Masaru (Male)') :
                (selectedGender === 'female' ? 'Jennifer' : 'Will')}
            </Option>
          )}
        </Select>
      </Form.Item>
    </>
  );

  return (
    <>
      <Card title={t("Create AI Agent")} className="mb-6">
        <Form form={form} onFinish={createAgent} layout="vertical">
          <Form.Item
            name="name"
            label={t("Agent Name")}
            rules={[{ required: true, message: t("Please enter agent name") }]}
          >
            <Input placeholder={t("e.g., Sales Assistant")} />
          </Form.Item>

          <Form.Item name="description" label={t("Description")}>
            <Input placeholder={t("Brief description of what this agent does")} />
          </Form.Item>

          <Form.Item
            name="prompt"
            label={t("Agent Prompt")}
            rules={[{ required: true, message: t("Please enter agent prompt") }]}
          >
            <TextArea
              rows={4}
              placeholder={t("Instructions for how the agent should behave and respond")}
            />
          </Form.Item>

          <Form.Item
            name="firstMessage"
            label={t("First Message")}
          >
            <Input placeholder={t("The first message the agent will say when a call starts")} />
          </Form.Item>

          <Form.Item 
            name="forwardNumber" 
            label={t("Forward Call Number")}
          >
            <Input placeholder={t("Optional: Number to forward calls to")} />
          </Form.Item>

          {languageSelector}

          <Form.Item
            name="isActive"
            valuePropName="checked"
            initialValue={true}
          >
            <Switch checkedChildren={t("Active")} unCheckedChildren={t("Inactive")} />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            loading={loading}
            className="w-full bg-orange-500 hover:bg-orange-600"
          >
            {t("Create Agent")}
          </Button>
        </Form>
      </Card>

      <Card title={t("Your AI Agents")}>
        <List
          dataSource={agents}
          renderItem={(agent) => (
            <List.Item
              actions={[
                <Button
                  key="edit"
                  icon={<EditOutlined />}
                  onClick={() => showEditModal(agent)}
                >
                  {t("Edit")}
                </Button>,
                <Popconfirm
                  key="delete"
                  title={t("Are you sure you want to delete this agent?")}
                  onConfirm={() => deleteAgent(agent._id)}
                  okText={t("Yes")}
                  cancelText={t("No")}
                >
                  <Button icon={<DeleteOutlined />} danger>
                    {t("Delete")}
                  </Button>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                avatar={<RobotOutlined style={{ fontSize: "24px" }} />}
                title={agent.name}
                description={
                  <Collapse ghost>
                    <Panel header={agent.description || t("AI Agent")} key="1">
                      <p>
                        <strong>{t("Voice")}:</strong> {agent.voice?.voiceId || agent.voice}
                      </p>
                      <p>
                        <strong>{t("Language")}:</strong> {agent.language === 'ja' ? t("Japanese") : t("English")}
                      </p>
                      <p>
                        <strong>{t("Status")}:</strong>{" "}
                        {agent.isActive ? t("Active") : t("Inactive")}
                      </p>
                      <p>
                        <strong>{t("Prompt")}:</strong>
                      </p>
                      <p className="whitespace-pre-line">{agent.prompt}</p>
                      {agent.firstMessage && (
                        <>
                          <p>
                            <strong>{t("First Message")}:</strong>
                          </p>
                          <p>{agent.firstMessage}</p>
                        </>
                      )}
                    </Panel>
                  </Collapse>
                }
              />
            </List.Item>
          )}
        />
      </Card>

      <Modal
        title={t("Edit Agent")}
        visible={isModalVisible}
        onCancel={handleEditCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Form form={editForm} onFinish={updateAgent} layout="vertical">
          <Form.Item
            name="name"
            label={t("Agent Name")}
            rules={[{ required: true, message: t("Please enter agent name") }]}
          >
            <Input placeholder={t("e.g., Sales Assistant")} />
          </Form.Item>

          <Form.Item name="description" label={t("Description")}>
            <Input placeholder={t("Brief description of what this agent does")} />
          </Form.Item>

          <Form.Item
            name="prompt"
            label={t("Agent Prompt")}
            rules={[{ required: true, message: t("Please enter agent prompt") }]}
          >
            <TextArea
              rows={4}
              placeholder={t("Instructions for how the agent should behave and respond")}
            />
          </Form.Item>

          <Form.Item
            name="firstMessage"
            label={t("First Message")}
          >
            <Input placeholder={t("The first message the agent will say when a call starts")} />
          </Form.Item>

          <Form.Item 
            name="forwardNumber" 
            label={t("Forward Call Number")}
          >
            <Input placeholder={t("Optional: Number to forward calls to")} />
          </Form.Item>

          {languageSelector}

          <Form.Item name="isActive" valuePropName="checked">
            <Switch checkedChildren={t("Active")} unCheckedChildren={t("Inactive")} />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="w-full bg-orange-500 hover:bg-orange-600"
          >
            {t("Update Agent")}
          </Button>
        </Form>
      </Modal>
    </>
  );
}
