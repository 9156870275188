"use client";

import { useState } from "react";
import { Card, Button, Form, Select, message, List, Tag, Empty } from "antd";
import { PhoneOutlined, RobotOutlined, LinkOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CallManagementService from "../../../../service/CallManagementService";

const { Option } = Select;

export default function AgentAssignment({
  phoneNumbers = [],
  agents = [],
  onAssignmentUpdated,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const assignAgent = async (values) => {
    setLoading(true);
    try {
      await CallManagementService.assignAgentToNumber(values);
      form.resetFields();
      if (onAssignmentUpdated) onAssignmentUpdated();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getAgentNameById = (agentId) => {
    const agent = agents.find((a) => a._id === agentId);
    return agent ? agent.name : t("Unknown Agent");
  };

  return (
    <>
      <Card title={t("Assign Agent to Phone Number")} className="mb-6">
        <Form form={form} onFinish={assignAgent} layout="vertical">
          <Form.Item
            name="phoneNumber"
            label={t("Phone Number")}
            rules={[
              { required: true, message: t("Please select a phone number") },
            ]}
          >
            <Select placeholder={t("Select a phone number")}>
              {phoneNumbers.map((number) => (
                <Option key={number.phoneNumber} value={number.phoneNumber}>
                  {number.friendlyName} ({number.phoneNumber})
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="agentId"
            label={t("AI Agent")}
            rules={[{ required: true, message: t("Please select an agent") }]}
          >
            <Select placeholder={t("Select an agent")}>
              {agents
                .filter((agent) => agent.isActive)
                .map((agent) => (
                  <Option key={agent._id} value={agent._id}>
                    {agent.name} - {agent.voice}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            icon={<LinkOutlined />}
            loading={loading}
            className="w-full bg-orange-500 hover:bg-orange-600"
          >
            {t("Assign Agent")}
          </Button>
        </Form>
      </Card>

      <Card title={t("Current Assignments")}>
        {phoneNumbers.filter((number) => number.agentId).length > 0 ? (
          <List
            dataSource={phoneNumbers.filter((number) => number.agentId)}
            renderItem={(number) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<PhoneOutlined style={{ fontSize: "24px" }} />}
                  title={number.friendlyName}
                  description={
                    <>
                      <div>{number.phoneNumber}</div>
                      <Tag color="green">
                        <RobotOutlined /> {getAgentNameById(number.agentId)}
                      </Tag>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty description={t("No agent assignments yet")} />
        )}
      </Card>
    </>
  );
}
